import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { api } from 'src/api';
import { EMPTY_OBJECT, TaskGroup } from 'src/constants/ui';
import { getString } from 'src/i18n/labels';
import { mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended } from 'src/services/packageApi';
import { taskApi } from 'src/services/taskApi';
import { ValueOf } from 'src/types/common';
import { FormElementV2ResponseDtoExtended } from 'src/types/formelement';
import { Loan } from 'src/types/loan';
import { Task } from 'src/types/tasks';

import { ITask } from "../backend";
import type { AppThunk, RootState } from '../store';

export type UserTasks = Record<string, Record<string, Task[]>>;
// key is user id  and value is a record of task group and count
export type UserTasksCount = Record<string, Record<string, number>>;

const getTaskTitleByTaskGroup = (taskGroup: ValueOf<typeof TaskGroup>): string => {
    switch (taskGroup) {
        case TaskGroup.AssignSigneeTask:
            return getString('assignSignee');
        case TaskGroup.AssignQuestionTask:
            return getString('assignQuestion');
        case TaskGroup.FillTask:
            return getString('fillAndSign');
        case TaskGroup.ShoeBoxTask:
            return getString('shoeBox');
        case TaskGroup.ApproveAnswerTask:
            return getString('accept');
        case TaskGroup.FillFormTask:
            return getString('fillForm');
        case TaskGroup.AnswerQuestionTask:
            return getString('upload');
    }
    return taskGroup;
}
interface TaskState {
    postTasksPending: boolean;
    getTasksPending: Record<string, boolean>;
    loanTasks: Record<string, UserTasks>;
    loanTasksCount: Record<string, UserTasksCount>;
    expandedAccordionUserId: string;
}

const initialState: TaskState = {
    getTasksPending: {},
    postTasksPending: false,
    loanTasks: {},
    loanTasksCount: {},
    expandedAccordionUserId: null,
};

const slice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setLoanUserTasks(
            state: TaskState,
            action: PayloadAction<{ tasks: Record<string, Task[]>, loanId: string, userId: string }>
        ): void {
            if (!isEqual(state.loanTasks[action.payload.loanId]?.[action.payload.userId], action.payload.tasks)) {
                state.loanTasks[action.payload.loanId] = {
                    ...state.loanTasks[action.payload.loanId],
                    [action.payload.userId]: action.payload.tasks
                };
            }
        },
        setLoanUserTasksCount(
            state: TaskState,
            action: PayloadAction<{ taskGroupCount: Record<string, number>, loanId: string, userId: string }>
        ): void {
            if (!isEqual(state.loanTasksCount[action.payload.loanId]?.[action.payload.userId], action.payload.taskGroupCount)) {
                state.loanTasksCount[action.payload.loanId] = {
                    ...state.loanTasksCount[action.payload.loanId],
                    [action.payload.userId]: action.payload.taskGroupCount
                };
            }
        },
        setExpandedAccordionUserId(state: TaskState, action: PayloadAction<string>): void {
            state.expandedAccordionUserId = action.payload;
        },
        setPostTasksPending(state: TaskState, action: PayloadAction<boolean>): void {
            state.postTasksPending = action.payload;
        },
        setGetTasksPending(state: TaskState, action: PayloadAction<{ userId: string, pending: boolean }>): void {
            state.getTasksPending[action.payload.userId] = action.payload.pending;
        }
    }
});

export const { reducer, actions } = slice;

export const setExpandedAccordionUserId = (userId: string): AppThunk => async (dispatch) => {
    dispatch(actions.setExpandedAccordionUserId(userId));
}

export const transformLoanUserTasks = ({
    loanId,
    userId,
    tasks,
    elementsList,
}: {
    loanId: string;
    userId: string;
    tasks: ITask[];
    elementsList: FormElementV2ResponseDtoExtended[];
}): AppThunk => async (dispatch): Promise<void> => {
    try {

        const tasksWithPackageInfo = tasks?.reduce((all, nextTask) => {
            const packageInfo = elementsList.find(packageInfo => nextTask.contextId === packageInfo.id);
            return ({
                ...all,
                [nextTask.taskType]: [
                    ...all[nextTask.taskType] || [],
                    ({
                        ...nextTask,
                        formElement: packageInfo,
                    })
                ]
            });
        }, {});
        dispatch(slice.actions.setLoanUserTasks({ tasks: tasksWithPackageInfo, loanId, userId }));
    } catch (error) { }
}


export const getAllLoanUsersTasks = (loanId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: loanId }]))
        const elementsResult = await api.getV2FormElements({ loanId });
        const consolidatedTasks = await api.consolidatedTasks(loanId);

        const userIds = Object.keys(consolidatedTasks.perUser);
        for (const userId of userIds) {
            const userTaskDetails = consolidatedTasks.perUser[userId];
            if (typeof userTaskDetails !== 'undefined') {
                dispatch(transformLoanUserTasks({
                    tasks: userTaskDetails.forMe,
                    loanId,
                    userId: userId,
                    elementsList: mapFormElementsV2ResponseDtoToFormElementV2ResponseDtoExtended(elementsResult).list,
                }));
            }
        };

        for (const userId of userIds) {
            const userTaskDetails = consolidatedTasks.perUser[userId];
            if (typeof userTaskDetails !== 'undefined') {
                dispatch(slice.actions.setLoanUserTasksCount({ taskGroupCount: userTaskDetails.count, loanId, userId }));
            }
        }
    } catch (error) {
        console.error('error getting tasks', error);
    }
}

export const userTasksSelector = ({ userId, loanId }: { userId: string, loanId: string }) => createSelector((state: any) => state.task.loanTasks, loanTasks => {
    return loanTasks?.[loanId]?.[userId] ?? [];
})

export const loanTasksSelector = ({ loanId }: { loanId: string }) => createSelector<[(state: RootState) => any],
    UserTasks>([
        (state: RootState) => state.task.loanTasks
    ],
        (loanTasks: Record<string, Record<string, UserTasks[]>>) => {

            const tasks = loanTasks[loanId] ?? EMPTY_OBJECT;
            const transformedTasks = Object.keys(tasks).reduce((acc, userId) => {
                let updatedAssignTaskGroup = tasks[userId][TaskGroup.AssignQuestionTask];
                let updatedAcceptTaskGroup = tasks[userId][TaskGroup.ApproveAnswerTask];
                let updatedAnswerTaskGroup = tasks[userId][TaskGroup.AnswerQuestionTask];
                return {
                    ...acc,
                    [userId]: {
                        ...tasks[userId],
                        ...!!updatedAssignTaskGroup && { [TaskGroup.AssignQuestionTask]: updatedAssignTaskGroup },
                        ...!!updatedAcceptTaskGroup && { [TaskGroup.ApproveAnswerTask]: updatedAcceptTaskGroup },
                        ...!!updatedAnswerTaskGroup && { [TaskGroup.AnswerQuestionTask]: updatedAnswerTaskGroup },
                    }
                }
            }, {});
            return transformedTasks;
        }
    );

export const userTasksCountByType = (currentLoanId: string) => createSelector([(state: RootState) => state.task.loanTasksCount], (loanTasks) => {
    const tasksCountByType = {};
    Object.keys(loanTasks).filter(loanId => loanId === currentLoanId).forEach(loanId => {
        Object.keys(loanTasks[loanId]).forEach(userId => {
            tasksCountByType[userId] = {};
            Object.keys(loanTasks[loanId][userId]).forEach(taskGroup => {
                tasksCountByType[userId][taskGroup] = {
                    count: taskGroup === TaskGroup.AssignQuestionTask ? 1 : loanTasks[loanId][userId][taskGroup],
                    title: getTaskTitleByTaskGroup(taskGroup as any)
                };
            });
        });
    });
    return tasksCountByType;
}
)