import { ProviderType } from "src/backend";

import { getExtensionFromFilename } from "../get-extension-from-filename";

export const guessFileProviderType = (name: string): ProviderType => {
    const extension = getExtensionFromFilename(name);
    if (['xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx', 'xlsm', 'dotx'].includes(extension)) {
        return ProviderType.ONE_DRIVE;
    }

    return ProviderType.S3;
}