/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 3.2.1263 on 2024-08-21 20:29:05.

export interface RestApplication<O> {

    /**
     * HTTP GET /anonymous/**
     * Java method: com.byzpass.demo.loan.AnonymousController.showAnonymous
     */
    showAnonymous(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/action
     * Java method: com.byzpass.demo.linkaction.LinkActionController.doAction
     */
    doAction(queryParams: { id: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/action/pendingInvites
     * Java method: com.byzpass.demo.linkaction.LinkActionController.getPendingInvites
     */
    getPendingInvites(options?: O): RestResponse<LinkActionInviteToLoanResponseDto[]>;

    /**
     * HTTP DELETE /api/v1/admin/labels
     * Java method: com.byzpass.demo.labels.LabelAdminController.deleteLabels
     */
    deleteLabels(toDelete: LabelDeleteDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.getLabelsForCompany
     */
    getLabelsForCompany(companyId: string, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/admin/labels/forCompany/{companyId}
     * Java method: com.byzpass.demo.labels.LabelAdminController.putLabels
     */
    putLabels(companyId: string, create: LabelsListDto, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/auth/addUser
     * Java method: com.byzpass.demo.security.SecurityAuthController.addUser
     */
    addUser(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP POST /api/v1/auth/adminResetPassword
     * Java method: com.byzpass.demo.security.SecurityAuthController.adminResetPassword
     */
    adminResetPassword(iChangePasswordRequestDto: AppUserChangePasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/auth/anonSession
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionAuthController.createSessionForUser
     */
    createSessionForUser(iDto: AnonCreateSessionForUserDto, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP POST /api/v1/auth/anonSession/{id}/extend
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionAuthController.extendSessionForUser
     */
    extendSessionForUser(id: string, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP POST /api/v1/auth/changePassword
     * Java method: com.byzpass.demo.security.SecurityAuthController.changePassword
     */
    changePassword(iChangePasswordRequest: AppUserChangePasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/auth/editUser/{id}
     * Java method: com.byzpass.demo.security.SecurityAuthController.editUser
     */
    editUser$POST$api_v1_auth_editUser_id(id: string, iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP POST /api/v1/auth/globalLogout
     * Java method: com.byzpass.demo.security.SecurityAuthController.globalLogout
     */
    globalLogout(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/isAuthenticated
     * Java method: com.byzpass.demo.security.SecurityAuthController.isAuthenticated
     */
    isAuthenticated(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/logout
     * Java method: com.byzpass.demo.security.SecurityAuthController.logout
     */
    logout$GET$api_v1_auth_logout(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/auth/timezones
     * Java method: com.byzpass.demo.security.SecurityAuthController.getTimezones
     */
    getTimezones(options?: O): RestResponse<TimezoneDTO[]>;

    /**
     * HTTP POST /api/v1/auth/update
     * Java method: com.byzpass.demo.security.SecurityAuthController.updateAppUser
     */
    updateAppUser(iDto: CreateUpdateAppUserDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP PUT /api/v1/auth/updateCookieTimeout
     * Java method: com.byzpass.demo.security.SecurityAuthController.updateCookieTimeout
     */
    updateCookieTimeout(iDto: UpdateCookieTimeoutDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.getSettings
     */
    getSettings(options?: O): RestResponse<AppUserPreferencesDTO>;

    /**
     * HTTP PUT /api/v1/auth/userSettings
     * Java method: com.byzpass.demo.security.SecurityAuthController.putSettings
     */
    putSettings(iDto: AppUserPreferencesDTO, options?: O): RestResponse<string>;

    /**
     * HTTP PUT /api/v1/clear/reeschedule/individualquickflag/{loanId}/{individualId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleIndividualQuickFlagReport
     */
    reescheduleIndividualQuickFlagReport(loanId: string, individualId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v1/clear/reschedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.reescheduleBusinessQuickFlagReport
     */
    reescheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v1/clear/schedule/businessquickflag/{loanId}/{entityId}
     * Java method: com.byzpass.demo.clear.ClearController.scheduleBusinessQuickFlagReport
     */
    scheduleBusinessQuickFlagReport(loanId: string, entityId: string, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/documents
     * Java method: com.byzpass.demo.document.DocumentController.postUploadUrlFor
     */
    postUploadUrlFor$POST$api_v1_documents(iUpload: Upload, options?: O): RestResponse<Document>;

    /**
     * HTTP GET /api/v1/documents/uploadUrlFor
     * Java method: com.byzpass.demo.document.DocumentController.getUploadUrlFor
     */
    getUploadUrlFor$GET$api_v1_documents_uploadUrlFor(queryParams: { name: string; iProviderType?: ProviderType; formElementId?: string; loanId?: string; }, options?: O): RestResponse<Upload>;

    /**
     * HTTP PUT /api/v1/documents/{id}
     * Java method: com.byzpass.demo.document.DocumentController.updateDocument
     */
    updateDocument(id: string, iDto: DocumentUpdateDto, options?: O): RestResponse<Document>;

    /**
     * HTTP GET /api/v1/documents/{id}/download
     * Java method: com.byzpass.demo.document.DocumentController.downloadFile
     */
    downloadFile(id: string, queryParams: { loanId: string; }, options?: O): RestResponse<any>;

    /**
     * HTTP GET /api/v1/documents/{id}/withDownloadUrl
     * Java method: com.byzpass.demo.document.DocumentController.getDocumentWithDownloadUrl
     */
    getDocumentWithDownloadUrl(id: string, queryParams?: { formElementId?: string; password?: string; timestamp?: string; loanId?: string; }, options?: O): RestResponse<Document>;

    /**
     * HTTP GET /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.getPreference
     */
    getPreference(key: string, options?: O): RestResponse<FePreferenceEntityResponseDto>;

    /**
     * HTTP POST /api/v1/fepreference/{key}
     * Java method: com.byzpass.demo.fepreference.FePreferenceController.savePreference
     */
    savePreference(key: string, queryParams: { value: { [index: string]: string }; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/javascriptConstants
     * Java method: com.byzpass.demo.services.ConstantsForClientController.constants
     */
    constants(options?: O): RestResponse<PerUserConstants>;

    /**
     * HTTP GET /api/v1/javascriptConstants/hotjar.js
     * Java method: com.byzpass.demo.services.ConstantsForClientController.hotjar
     */
    hotjar(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/labels/loan/{loanId}/availableToMe
     * Java method: com.byzpass.demo.labels.LabelController.getLabels
     */
    getLabels(loanId: string, options?: O): RestResponse<LabelsListDto>;

    /**
     * HTTP POST /api/v1/labels/loan/{loanId}/setForUser
     * Java method: com.byzpass.demo.labels.LabelController.putLabelOnSomeone
     */
    putLabelOnSomeone(loanId: string, toset: SetLabelOnUserDto, options?: O): RestResponse<LoanDto>;

    /**
     * @deprecated
     * HTTP GET /api/v1/lenders/companies
     * Java method: com.byzpass.demo.company.LenderController.getCompanies
     */
    getCompanies(options?: O): RestResponse<CompanyListDto[]>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/company
     * Java method: com.byzpass.demo.company.LenderController.onboardCompany
     */
    onboardCompany(iOnboardCompany: OnboardCompany, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP PUT /api/v1/lenders/company/{id}
     * Java method: com.byzpass.demo.company.LenderController.updateCompany
     */
    updateCompany(id: string, iOnboardCompany: OnboardCompany, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/lenders/companyDetails/{subdomain}
     * Java method: com.byzpass.demo.company.LenderController.getCompanyDetails
     */
    getCompanyDetails(subdomain: string, options?: O): RestResponse<CompanyListDto>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyLogo
     * Java method: com.byzpass.demo.company.LenderController.onboardLogo
     */
    onboardLogo(iRequest: UploadLogo, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP POST /api/v1/lenders/companyUser
     * Java method: com.byzpass.demo.company.LenderController.onboardCompanyUser
     */
    onboardCompanyUser(toOnboard: OnboardUserDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP POST /api/v1/lenders/employee/info
     * Java method: com.byzpass.demo.company.LenderController.updateEmployeeInfo
     */
    updateEmployeeInfo(iDto: EmployeeInfoDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/lenders/employee/secureUploadPageUrl
     * Java method: com.byzpass.demo.company.LenderController.getEmployeeSecureUploadPageUrl
     */
    getEmployeeSecureUploadPageUrl(queryParams: { givenName: string; familyName: string; }, options?: O): RestResponse<UniqueIdDto>;

    /**
     * HTTP GET /api/v1/lenders/employeePublicDetails/{id}
     * Java method: com.byzpass.demo.company.LenderController.getEmployeePublicDetails
     */
    getEmployeePublicDetails(id: string, options?: O): RestResponse<EmployeePublicDetailsDto>;

    /**
     * HTTP GET /api/v1/lenders/forBorrower
     * Java method: com.byzpass.demo.company.LenderController.getLendersForBorrower
     */
    getLendersForBorrower(options?: O): RestResponse<CompanyListDto[]>;

    /**
     * HTTP GET /api/v1/lenders/isSubdomainUsed/{subdomain}
     * Java method: com.byzpass.demo.company.LenderController.checkSubdomainUsed
     */
    checkSubdomainUsed(subdomain: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/v1/lenders/isSubdomainUsed/{subdomain}/{companyId}
     * Java method: com.byzpass.demo.company.LenderController.checkSubdomain
     */
    checkSubdomain(subdomain: string, companyId: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/v1/lenders/logo/image/{companyId}
     * Java method: com.byzpass.demo.company.LenderController.getCompanyLogoImage
     */
    getCompanyLogoImage(companyId: string, options?: O): RestResponse<any>;

    /**
     * HTTP GET /api/v1/lenders/logo/{companyId}
     * Java method: com.byzpass.demo.company.LenderController.getLogoForACompany
     */
    getLogoForACompany(companyId: string, options?: O): RestResponse<Document>;

    /**
     * HTTP DELETE /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.deletePhases
     */
    deletePhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/lenders/phases
     * Java method: com.byzpass.demo.company.LenderController.setPhases
     */
    setPhases(iPhaseDtos: LoanPhaseDto[], options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/lenders/subdomainLogoStub
     * Java method: com.byzpass.demo.company.LenderController.subdomainLogoStub
     */
    subdomainLogoStub(queryParams?: { sub?: string; }, options?: O): RestResponse<any>;

    /**
     * HTTP PUT /api/v1/lenders/venturesTestCredentials
     * Java method: com.byzpass.demo.company.LenderController.testVenturesConnectionCredentials
     */
    testVenturesConnectionCredentials(iCredentials: VentureTestCredentialDTO, options?: O): RestResponse<VentureTestResultDTO>;

    /**
     * HTTP GET /api/v1/lenders/{companyId}/excelTemplateId
     * Java method: com.byzpass.demo.company.LenderController.getExcelTemplateId
     */
    getExcelTemplateId(companyId: string, options?: O): RestResponse<string>;

    /**
     * HTTP PUT /api/v1/lenders/{companyId}/excelTemplateId/{documentId}
     * Java method: com.byzpass.demo.company.LenderController.setExcelTemplateId
     */
    setExcelTemplateId(companyId: string, documentId: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/lenders/{companyId}/venturesTest
     * Java method: com.byzpass.demo.company.LenderController.testVenturesConnection
     */
    testVenturesConnection(companyId: string, options?: O): RestResponse<VentureTestResultDTO>;

    /**
     * HTTP GET /api/v1/lenders/{id}/employees
     * Java method: com.byzpass.demo.company.LenderController.employees
     */
    employees(id: string, options?: O): RestResponse<CompanyEmployeeListDto>;

    /**
     * HTTP GET /api/v1/lenders/{id}/phases
     * Java method: com.byzpass.demo.company.LenderController.getPhases
     */
    getPhases(id: string, options?: O): RestResponse<LoanPhaseDto[]>;

    /**
     * HTTP GET /api/v1/loans
     * Java method: com.byzpass.demo.loan.LoanController.loans
     */
    loans(queryParams?: { role?: ViewType; phaseCategory?: LoanPhaseCategoryType; phaseId?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<LoanDto>>;

    /**
     * HTTP GET /api/v1/loans/company/{companyId}/user/{userId}
     * Java method: com.byzpass.demo.loan.LoanController.getLoansForCompanyAndUser
     */
    getLoansForCompanyAndUser(companyId: string, userId: string, queryParams?: { showLastMessageDate?: boolean; }, options?: O): RestResponse<BasicLoanDto[]>;

    /**
     * HTTP POST /api/v1/loans/editBorrowUser/{loanId}/{userId}
     * Java method: com.byzpass.demo.loan.LoanController.editUser
     */
    editUser$POST$api_v1_loans_editBorrowUser_loanId_userId(loanId: string, userId: string, iBorrowerUser: BorrowUserSetDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP GET /api/v1/loans/view
     * Java method: com.byzpass.demo.loan.LoanController.loansView
     */
    loansView(queryParams?: { categories?: LoanPhaseCategoryType[]; }, options?: O): RestResponse<LoanDto[]>;

    /**
     * HTTP GET /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.getLoan
     */
    getLoan(id: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{id}
     * Java method: com.byzpass.demo.loan.LoanController.putLoan
     */
    putLoan(id: string, iUpdateLoanDTO: UpdateLoanDTO, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v1/loans/{id}/getPackageUrls
     * Java method: com.byzpass.demo.loan.LoanController.getPackageUrls
     */
    getPackageUrls(id: string, options?: O): RestResponse<PackageUrlResponse>;

    /**
     * HTTP POST /api/v1/loans/{id}/inviteborrower
     * Java method: com.byzpass.demo.loan.LoanController.inviteBorrowerUserToLoan
     */
    inviteBorrowerUserToLoan(id: string, iInviteToLoanDTO: InviteToLoanDTO, options?: O): RestResponse<string>;

    /**
     * HTTP DELETE /api/v1/loans/{id}/lenderUser/{roleId}
     * Java method: com.byzpass.demo.loan.LoanController.removeLender
     */
    removeLender(id: string, roleId: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{id}/phase
     * Java method: com.byzpass.demo.loan.LoanController.setLoanPhase
     */
    setLoanPhase(id: string, iDto: LoanPhaseDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{id}/pin
     * Java method: com.byzpass.demo.loan.LoanController.pinLoan
     */
    pinLoan(id: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP POST /api/v1/loans/{id}/refreshPackages
     * Java method: com.byzpass.demo.loan.LoanController.generatePackage
     */
    generatePackage(id: string, options?: O): RestResponse<void>;

    /**
     * @deprecated
     * HTTP POST /api/v1/loans/{id}/status
     * Java method: com.byzpass.demo.loan.LoanController.setLoanStatus
     */
    setLoanStatus(id: string, iLoanStatus: LoanStatusDTO, options?: O): RestResponse<LoanDto>;

    /**
     * @deprecated for removal
     * HTTP GET /api/v1/loans/{id}/teamMembers
     * Java method: com.byzpass.demo.loan.LoanController.loanTeamMembers
     */
    loanTeamMembers(id: string, options?: O): RestResponse<AppUser[]>;

    /**
     * HTTP PUT /api/v1/loans/{id}/unpin
     * Java method: com.byzpass.demo.loan.LoanController.unpinLoan
     */
    unpinLoan(id: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/v1/loans/{loanId}/answers/{packageInfoId}
     * Java method: com.byzpass.demo.loan.LoanController.getAnswersForFormElement
     */
    getAnswersForFormElement(loanId: string, packageInfoId: string, options?: O): RestResponse<AnswerV2Dto[]>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerRole
     * Java method: com.byzpass.demo.loan.LoanController.setUserRole
     */
    setUserRole(loanId: string, borrowerUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/borrowerUser
     * Java method: com.byzpass.demo.loan.LoanController.addBorrowerUser
     */
    addBorrowerUser(loanId: string, iBorrowerUser: BorrowUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.addLender
     */
    addLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{loanId}/lenderUser
     * Java method: com.byzpass.demo.loan.LoanController.editLender
     */
    editLender(loanId: string, lenderUser: LeadUserSetDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP PUT /api/v1/loans/{loanId}/setReviewStatus/{reviewStatus}
     * Java method: com.byzpass.demo.loan.LoanController.setLoanReadyToReviewStatus
     */
    setLoanReadyToReviewStatus(loanId: string, reviewStatus: LoanReviewStatus, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.loanMessages
     */
    loanMessages(queryParams: { loanId: string; iLabels?: MessageLabel[]; page?: number; size?: number; }, options?: O): RestResponse<MessageDto[]>;

    /**
     * HTTP POST /api/v1/messages
     * Java method: com.byzpass.demo.message.MessageController.sendMessage
     */
    sendMessage(iMessageSendDto: MessageSendDto, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP POST /api/v1/messages/draft
     * Java method: com.byzpass.demo.message.MessageController.saveNewDraftMessage
     */
    saveNewDraftMessage(iMessageSendDto: MessageSendDto, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP DELETE /api/v1/messages/draft/{id}
     * Java method: com.byzpass.demo.message.MessageController.deleteDraftMessage
     */
    deleteDraftMessage(id: string, options?: O): RestResponse<string>;

    /**
     * HTTP PUT /api/v1/messages/draft/{id}
     * Java method: com.byzpass.demo.message.MessageController.updateDraftMessage
     */
    updateDraftMessage(id: string, iMessageSendDto: MessageSendDto, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP GET /api/v1/messages/draft/{loanId}
     * Java method: com.byzpass.demo.message.MessageController.getDraftMessages
     */
    getDraftMessages(loanId: string, options?: O): RestResponse<MessageDto[]>;

    /**
     * HTTP GET /api/v1/messages/draft/{loanId}/count
     * Java method: com.byzpass.demo.message.MessageController.getDraftMessagesCount
     */
    getDraftMessagesCount(loanId: string, options?: O): RestResponse<number>;

    /**
     * HTTP POST /api/v1/messages/generateDynamicNeedsListBody
     * Java method: com.byzpass.demo.message.MessageController.generateDynamicNeedsListBody
     */
    generateDynamicNeedsListBody(iDto: DynamicNeedsListRequestDto, options?: O): RestResponse<htmlDto>;

    /**
     * HTTP GET /api/v1/messages/originator/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentUrl
     */
    getMessageFullContentUrl(messageId: string, options?: O): RestResponse<MessageFullContentUrl>;

    /**
     * HTTP GET /api/v1/messages/originatorimplementation/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.getMessageFullContentImplementation
     */
    getMessageFullContentImplementation(messageId: string, options?: O): RestResponse<MessageOriginationStuff>;

    /**
     * HTTP GET /api/v1/messages/search
     * Java method: com.byzpass.demo.message.MessageController.searchMessage
     */
    searchMessage(queryParams?: { textSearch?: string; loanId?: string; threadId?: string; iLabels?: MessageLabel[]; page?: number; size?: number; order?: string; }, options?: O): RestResponse<MessageSearchPageDto>;

    /**
     * HTTP GET /api/v1/messages/threads
     * Java method: com.byzpass.demo.message.MessageController.loanMessagesThreads
     */
    loanMessagesThreads(queryParams: { loanId: string; }, options?: O): RestResponse<MessageThreadDto[]>;

    /**
     * HTTP POST /api/v1/messages/threads/forwardMessage/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.forwardMessage
     */
    forwardMessage(messageId: string, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/messages/threads/mute/{threadId}
     * Java method: com.byzpass.demo.message.MessageController.silenceThreadNotificationEMail
     */
    silenceThreadNotificationEMail(threadId: string, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/messages/threads/unmute/{threadId}
     * Java method: com.byzpass.demo.message.MessageController.unsilenceThreadNotificationEMail
     */
    unsilenceThreadNotificationEMail(threadId: string, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/messages/threads/userTyping/{threadId}
     * Java method: com.byzpass.demo.message.MessageController.userTypingInThread
     */
    userTypingInThread(threadId: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v1/messages/threads/{threadId}
     * Java method: com.byzpass.demo.message.MessageController.loanMessageThread
     */
    loanMessageThread(threadId: string, queryParams: { loanId: string; }, options?: O): RestResponse<MessageThreadDto>;

    /**
     * HTTP GET /api/v1/messages/threads/{threadId}/countUnread
     * Java method: com.byzpass.demo.message.MessageController.countUreadMessagesByThread
     */
    countUreadMessagesByThread(threadId: string, options?: O): RestResponse<number>;

    /**
     * HTTP GET /api/v1/messages/threads/{threadId}/messages
     * Java method: com.byzpass.demo.message.MessageController.loanMessagesByThread
     */
    loanMessagesByThread(threadId: string, queryParams?: { iLabels?: MessageLabel[]; page?: number; size?: number; iMessageId?: string; iLoadFirstUnreadPage?: boolean; }, options?: O): RestResponse<MessagePageDto>;

    /**
     * HTTP GET /api/v1/messages/unread
     * Java method: com.byzpass.demo.message.MessageController.loanMessagesUnread
     */
    loanMessagesUnread(queryParams: { loanId: string; iLabels?: MessageLabel[]; page?: number; size?: number; }, options?: O): RestResponse<MessageDto[]>;

    /**
     * HTTP GET /api/v1/messages/unreadForCompany/{companyId}
     * Java method: com.byzpass.demo.message.MessageController.getUnreadCountByCompany
     */
    getUnreadCountByCompany(companyId: string, queryParams?: { categories?: LoanPhaseCategoryType[]; }, options?: O): RestResponse<MessageUnreadDto>;

    /**
     * HTTP DELETE /api/v1/messages/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.deleteMessage
     */
    deleteMessage(messageId: string, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP PUT /api/v1/messages/{messageId}
     * Java method: com.byzpass.demo.message.MessageController.updateMessage
     */
    updateMessage(messageId: string, iMessageUpdate: MessageUpdateDto, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP POST /api/v1/messages/{messageId}/important
     * Java method: com.byzpass.demo.message.MessageController.setImportant
     */
    setImportant(messageId: string, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP POST /api/v1/messages/{messageId}/notimportant
     * Java method: com.byzpass.demo.message.MessageController.setNotImportant
     */
    setNotImportant(messageId: string, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP POST /api/v1/messages/{messageId}/read
     * Java method: com.byzpass.demo.message.MessageController.markAsRead
     */
    markAsRead(messageId: string, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP POST /api/v1/messages/{messageId}/unread
     * Java method: com.byzpass.demo.message.MessageController.markAsUnRead
     */
    markAsUnRead(messageId: string, options?: O): RestResponse<MessageDto>;

    /**
     * HTTP GET /api/v1/naics
     * Java method: com.byzpass.demo.naics.NaicsController.getNaicsList
     */
    getNaicsList(options?: O): RestResponse<NaicsDto[]>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/lenderDoc/{lenderUniqueCode}/{documentId}
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonGetLenderDocumentWithDownloadUrl
     */
    anonGetLenderDocumentWithDownloadUrl(lenderUniqueCode: string, documentId: string, options?: O): RestResponse<Document>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/verifyCode
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonUploadVerifyCode
     */
    anonUploadVerifyCode(iDto: AnonConfirmCodeRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/verifyEmail
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonUploadVerifyEmail
     */
    anonUploadVerifyEmail(iDto: AnonVerifyEmailRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/{id}
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonSession
     */
    anonSession(id: string, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/{id}/createDocument
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.postUploadUrlFor
     */
    postUploadUrlFor$POST$api_v1_noauth_anonSession_id_createDocument(id: string, iUpload: Upload, options?: O): RestResponse<Document>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/{id}/editDocument
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonEditDocument
     */
    anonEditDocument(id: string, iDto: AnonRequestDto, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/{id}/getUploadUrl
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.getUploadUrlFor
     */
    getUploadUrlFor$GET$api_v1_noauth_anonSession_id_getUploadUrl(id: string, queryParams: { name: string; iProviderType?: ProviderType; }, options?: O): RestResponse<Upload>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/{id}/pdfKey
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonGetPdfKey
     */
    anonGetPdfKey(id: string, options?: O): RestResponse<PdfKeyDto>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/{id}/requestExtension
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonRequestExtension
     */
    anonRequestExtension(id: string, iDto: AnonVerifyEmailRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/{id}/shorten
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonShortenSession
     */
    anonShortenSession(id: string, queryParams: { time: string; }, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/{id}/submit
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonSubmitDocuments
     */
    anonSubmitDocuments(id: string, iDto: AnonSubmitDto, options?: O): RestResponse<AnonSessionDto>;

    /**
     * HTTP POST /api/v1/noauth/anonSession/{id}/verifyExtension
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonVerifyExtension
     */
    anonVerifyExtension(id: string, iDto: AnonConfirmCodeRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/anonSession/{sessionId}/documentWithDownloadUrl/{documentId}
     * Java method: com.byzpass.demo.security.anonsession.AnonSessionController.anonGetDocumentWithDownloadUrl
     */
    anonGetDocumentWithDownloadUrl(sessionId: string, documentId: string, options?: O): RestResponse<Document>;

    /**
     * HTTP POST /api/v1/noauth/confirmForgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmForgotPassword
     */
    confirmForgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/confirmSignup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.confirmSignup
     */
    confirmSignup(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/forgotPassword
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.forgotPassword
     */
    forgotPassword(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/installurl
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getInstallUrl
     */
    getInstallUrl(options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/noauth/login
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.login
     */
    login(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/loginWithConfirmCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.loginWithConfirmCode
     */
    loginWithConfirmCode(iDto: LoginWithConfirmCodeDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/logout
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.logout
     */
    logout$POST$api_v1_noauth_logout(options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/notifyCompany/{id}/shoebox
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.notifyCompanyOfShoeboxChange
     */
    notifyCompanyOfShoeboxChange(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/noauth/resendConfirmationCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.resendConfirmationCode
     */
    resendConfirmationCode(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP POST /api/v1/noauth/signup
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.signup
     */
    signup(iSignupRequestDto: AppUserSignupRequestDto, options?: O): RestResponse<SecurityResponseDto>;

    /**
     * HTTP GET /api/v1/noauth/testutils/backupH2
     * Java method: ai.mysherpas.app.testutils.TestUtilsController.backupH2
     */
    backupH2(queryParams?: { fileName?: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/noauth/testutils/emailTestId
     * Java method: ai.mysherpas.app.testutils.TestUtilsController.getEmailTestId
     */
    getEmailTestId(options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v1/noauth/testutils/emailTestId
     * Java method: ai.mysherpas.app.testutils.TestUtilsController.setEmailTestId
     */
    setEmailTestId(iDto: EmailTestIdRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/noauth/testutils/restoreH2
     * Java method: ai.mysherpas.app.testutils.TestUtilsController.restoreH2
     */
    restoreH2(queryParams?: { fileName?: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v1/noauth/verifyConfirmationCode
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.verifyConfirmationCode
     */
    verifyConfirmationCode(iDto: AppUserForgotPasswordRequestDto, options?: O): RestResponse<VerifyConfirmationCodeDto>;

    /**
     * HTTP GET /api/v1/noauth/version
     * Java method: com.byzpass.demo.security.SecurityNoAuthController.getVersion
     */
    getVersion(options?: O): RestResponse<VersionDto>;

    /**
     * HTTP GET /api/v1/s/{tinyId}
     * Java method: com.byzpass.demo.document.tiny.TinyDocumentController.redirect
     */
    redirect(tinyId: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/search
     * Java method: com.byzpass.demo.search.SearchController.search
     */
    search$GET$api_v1_search(queryParams: { q: string; p?: number; d?: string; c?: string; ot?: string; }, options?: O): RestResponse<SearchResultsDTO>;

    /**
     * HTTP GET /api/v1/tasks/html
     * Java method: com.byzpass.demo.task.TaskController.tasksForLoanAndUserAsHtml
     */
    tasksForLoanAndUserAsHtml(queryParams: { loanId: string; userId?: string; }, options?: O): RestResponse<htmlDto>;

    /**
     * HTTP PUT /api/v1/transunion/reeschedule/{loanId}/{individualId}
     * Java method: com.byzpass.demo.transunion.v2.TransunionController.reescheduleReport
     */
    reescheduleReport(loanId: string, individualId: string, queryParams?: { forceSchedule?: boolean; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v1/venture/checkdatachange/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.checkdatachange
     */
    checkdatachange(loanId: string, options?: O): RestResponse<VentureExportDataChangeDTO>;

    /**
     * HTTP GET /api/v1/venture/checkrequireddata/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.checkRequiredData
     */
    checkRequiredData(loanId: string, options?: O): RestResponse<VentureRequiredDataDTO>;

    /**
     * HTTP GET /api/v1/venture/contacts/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.getContacts
     */
    getContacts(loanId: string, options?: O): RestResponse<VentureContactDTO[]>;

    /**
     * HTTP POST /api/v1/venture/datachangeconfirm/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.dataChangeConfirmation
     */
    dataChangeConfirmation(loanId: string, iDataChange: VentureExportDataChangeDTO, options?: O): RestResponse<VentureExportDataChange>;

    /**
     * HTTP GET /api/v1/venture/documenttypes
     * Java method: com.byzpass.demo.venture.VentureController.getVentureDocTypeInSherpas
     */
    getVentureDocTypeInSherpas(options?: O): RestResponse<VenturesDocType[]>;

    /**
     * HTTP PUT /api/v1/venture/export/loan/{loanId}/{dataChangeId}
     * Java method: com.byzpass.demo.venture.VentureController.exportLoanToVenture
     */
    exportLoanToVenture(loanId: string, dataChangeId: string, options?: O): RestResponse<VentureEntityDTO[]>;

    /**
     * HTTP GET /api/v1/venture/loantypes/{loanId}
     * Java method: com.byzpass.demo.venture.VentureController.getVenturesLoanTypes
     */
    getVenturesLoanTypes(loanId: string, options?: O): RestResponse<VentureLoanTypeDTO[]>;

    /**
     * HTTP DELETE /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.clearMaintenance
     */
    clearMaintenance(options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.getMaintenance
     */
    getMaintenance(options?: O): RestResponse<MaintenanceWindowDto>;

    /**
     * HTTP POST /api/v2/admin/maintenance
     * Java method: com.byzpass.demo.admin.AdminMaintenanceController.setMaintenance
     */
    setMaintenance(dto: MaintenanceWindowDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/admin/util/onedrive-remove-shares
     * Java method: com.byzpass.demo.admin.AdminUtilController.removesSharesOneDrive
     */
    removesSharesOneDrive(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/admin/util/s3-summary
     * Java method: com.byzpass.demo.admin.AdminUtilController.getS3Summary
     */
    getS3Summary(options?: O): RestResponse<StorageSummaryDto>;

    /**
     * HTTP GET /api/v2/admin/util/s3-transfer/{ndocuments}
     * Java method: com.byzpass.demo.admin.AdminUtilController.transferS3Documents
     */
    transferS3Documents(ndocuments: number, options?: O): RestResponse<StorageSummaryDto>;

    /**
     * HTTP POST /api/v2/conversion/image
     * Java method: ai.mysherpas.app.conversion.ConversionController.convertImage
     */
    convertImage(requestDto: ConversionRequestDto, options?: O): RestResponse<DeferredResult<ResponseEntity<ConversionResponseDto>>>;

    /**
     * HTTP POST /api/v2/entities/bankAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBankAssets
     */
    createBankAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/businesses
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createBusinesses
     */
    createBusinesses(dto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/equipment
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createEquipmentAssets
     */
    createEquipmentAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/individualFromUser
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividualFromUser
     */
    createIndividualFromUser(iDto: IdDto, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP GET /api/v2/entities/individualFromUser/{id}
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.getIndividualFromUser
     */
    getIndividualFromUser(id: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/entities/individuals
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createIndividuals
     */
    createIndividuals(lDto: IndividualRequestDto, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/entities/randomAsset
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createOtherAssets
     */
    createOtherAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP POST /api/v2/entities/realEstate
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.createRealEstateAssets
     */
    createRealEstateAssets(iDto: EntityCreateDto, options?: O): RestResponse<EntityResponseDto>;

    /**
     * HTTP PUT /api/v2/entities/{entityId}/rename
     * Java method: ai.mysherpas.app.sherpaentity.SherpaEntityController.renameEntity
     */
    renameEntity(entityId: string, renameDto: RenameEntityDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/frontend/keys
     * Java method: com.byzpass.demo.util.frontend.FrontendKeys.getKeys
     */
    getKeys(options?: O): RestResponse<FeKeys>;

    /**
     * HTTP GET /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBankInformation
     */
    getMostRecentBankInformation(entityId: string, options?: O): RestResponse<BankAssetResponseDto>;

    /**
     * HTTP POST /api/v2/information/bankAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBankInformation
     */
    postUpSomeBankInformation(entityId: string, information: BankAssetRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentBusinessEntityInformation
     */
    getMostRecentBusinessEntityInformation(entityId: string, options?: O): RestResponse<BusinessResponseDto>;

    /**
     * HTTP POST /api/v2/information/business/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeBusinessEntityInformation
     */
    postUpSomeBusinessEntityInformation(entityId: string, information: BusinessRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentEquipmentInformation
     */
    getMostRecentEquipmentInformation(entityId: string, options?: O): RestResponse<EquipmentResponseDto>;

    /**
     * HTTP POST /api/v2/information/equipment/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeEquipmentInformation
     */
    postUpSomeEquipmentInformation(entityId: string, information: EquipmentRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getADriversLicense
     */
    getADriversLicense(entityId: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/information/individual/usdl/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpADriversLicense
     */
    postUpADriversLicense(entityId: string, dto: DlRequest, options?: O): RestResponse<ResponseEntity<any>>;

    /**
     * HTTP GET /api/v2/information/individual/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentIndividualAssetInformation
     */
    getMostRecentIndividualAssetInformation(entityId: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP POST /api/v2/information/individual/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postIndividualInformation
     */
    postIndividualInformation(entityId: string, dto: IndividualRequestDto, options?: O): RestResponse<ResponseEntity<any>>;

    /**
     * HTTP GET /api/v2/information/individual/{entityId}/riskreportstatus/{loanId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getIndividualInfo
     */
    getIndividualInfo(entityId: string, loanId: string, options?: O): RestResponse<IndividualResponseDto>;

    /**
     * HTTP GET /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRandomAssetInformation
     */
    getMostRecentRandomAssetInformation(entityId: string, options?: O): RestResponse<RandomAssetResponseDto>;

    /**
     * HTTP POST /api/v2/information/randomAsset/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRandomAssetInformation
     */
    postUpSomeRandomAssetInformation(entityId: string, information: RandomAssetRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.getMostRecentRealEstateInformation
     */
    getMostRecentRealEstateInformation(entityId: string, options?: O): RestResponse<RealEstateResponseDto>;

    /**
     * HTTP POST /api/v2/information/realEstate/{entityId}
     * Java method: ai.mysherpas.app.sherpaentity.InformationController.postUpSomeRealEstateInformation
     */
    postUpSomeRealEstateInformation(entityId: string, information: RealEstateRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/loanmanager/applyComponentTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyComponentTemplate
     */
    applyComponentTemplate(queryParams: { loanId: string; templateId: string; }, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingEntity
     */
    applyNewTemplateToExistingEntity(iDto: NewEntityTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/applyNewTemplateToExistingLoan
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyNewTemplateToExistingLoan
     */
    applyNewTemplateToExistingLoan(iDto: NewLoanTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/applyTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.applyTemplate
     */
    applyTemplate(iDto: ApplyTemplateDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/createDeepLinkActionForLoan
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createDeepLinkActionForObject
     */
    createDeepLinkActionForObject(iDto: DeepLinkActionRequestDto, options?: O): RestResponse<FormElementUrlResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/createEntity
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createEntity
     */
    createEntity(iDto: CreateEntityCompleteDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/createFromTemplate
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createFromTemplate2
     */
    createFromTemplate2(iDto: NewLoanDTO, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createElements
     */
    createElements$POST$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElements
     */
    deleteElements$DELETE$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/loanmanager/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.updateElements
     */
    updateElements$PUT$api_v2_loanmanager_elements(iDto: FormElementsRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/action
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.performElementAction
     */
    performElementAction(iDto: FormElementsActionRequestDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_loanmanager_elements_answer(iDto: AnswerFormElementDto, options?: O): RestResponse<FormElementV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/elements/answer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteAnswerToElement
     */
    deleteAnswerToElement(iDto: AnswerFormElementDto, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/copy
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.copyElements
     */
    copyElements(iDto: CopyFormElementsDto, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/handleExpiring
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.handleExpiringAndExpiredElements
     */
    handleExpiringAndExpiredElements(options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/elements/{id}/resetAnswer
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.resetTemplateAnswerOnElement
     */
    resetTemplateAnswerOnElement(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/loanmanager/entityLabel
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.addEntityLabel
     */
    addEntityLabel(iDto: EntityLabelDto, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP GET /api/v2/loanmanager/linkForLoan/{packageId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLinkForElementV2
     */
    getLinkForElementV2(packageId: string, options?: O): RestResponse<FormElementUrlResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/linkForPortal
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLinkForPortal
     */
    getLinkForPortal(queryParams: { infoId?: string; taskView?: TaskViewType; companyId: string; }, options?: O): RestResponse<FormElementUrlResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.createSharedInfoElement
     */
    createSharedInfoElement(iDto: PackageInfoSharesRequestDto, options?: O): RestResponse<PackageInfoSharesResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteSharedInfoElement
     */
    deleteSharedInfoElement(iDto: PackageInfoSharesRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/loanmanager/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.updateSharedInfoElement
     */
    updateSharedInfoElement(iDto: PackageInfoSharesRequestDto, options?: O): RestResponse<PackageInfoSharesResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/template/{id}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getAllWithTemplate
     */
    getAllWithTemplate(id: string, options?: O): RestResponse<LoanDto[]>;

    /**
     * HTTP POST /api/v2/loanmanager/updateTemplateForLoans
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.updateTemplateForLoans
     */
    updateTemplateForLoans(iDto: UpdateTemplateRequestDto, options?: O): RestResponse<UpdateTemplateResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanElements
     */
    getLoanElements$GET$api_v2_loanmanager_id_elements(id: string, queryParams?: { view?: LoanViewType; }, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.deleteElementsZip
     */
    deleteElementsZip(id: string, queryParams: { jobid: string; }, options?: O): RestResponse<ZipFileJobResponseDto>;

    /**
     * HTTP POST /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.generateElementsZip
     */
    generateElementsZip(id: string, iDto: ZipFileJobRequestDto, options?: O): RestResponse<ZipFileJobResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/elementsDownload
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getElementsZip
     */
    getElementsZip(id: string, queryParams?: { jobid?: string; }, options?: O): RestResponse<ZipFileJobsResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/folders
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanFolders
     */
    getLoanFolders(id: string, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/shared/elements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getSharedInfoElements
     */
    getSharedInfoElements(id: string, options?: O): RestResponse<PackageInfoSharesResponseDto>;

    /**
     * HTTP GET /api/v2/loanmanager/{id}/statusChangeElements
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.getLoanElements
     */
    getLoanElements$GET$api_v2_loanmanager_id_statusChangeElements(id: string, queryParams: { loanReviewStatus: LoanReviewStatus; }, options?: O): RestResponse<FormElementsV2ResponseDto>;

    /**
     * HTTP DELETE /api/v2/loanmanager/{loanId}/entity/{entityId}
     * Java method: ai.mysherpas.app.loanmanager.LoanMgrController.removeEntityFromLoan
     */
    removeEntityFromLoan(loanId: string, entityId: string, options?: O): RestResponse<LoanDto>;

    /**
     * HTTP POST /api/v2/notes
     * Java method: ai.mysherpas.app.note.NoteController.createNote
     */
    createNote(iDto: NoteRequestDto, options?: O): RestResponse<NoteResponseDto>;

    /**
     * HTTP GET /api/v2/notes/loan/{id}
     * Java method: ai.mysherpas.app.note.NoteController.getNotesForLoan
     */
    getNotesForLoan(id: string, queryParams?: { objectId?: string; objectType?: NoteObjectType; }, options?: O): RestResponse<NoteResponseDto[]>;

    /**
     * HTTP DELETE /api/v2/notes/{id}
     * Java method: ai.mysherpas.app.note.NoteController.deleteNote
     */
    deleteNote(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/notes/{id}
     * Java method: ai.mysherpas.app.note.NoteController.updateNote
     */
    updateNote(id: string, iDto: NoteRequestDto, options?: O): RestResponse<NoteResponseDto>;

    /**
     * HTTP POST /api/v2/sharepoint/export/{loanId}
     * Java method: com.byzpass.demo.sharepoint.SharepointController.exportLoan
     */
    exportLoan(loanId: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/shoebox/company/{id}
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.getCompanyShoeboxes
     */
    getCompanyShoeboxes(id: string, options?: O): RestResponse<PersonalShoeboxesDto>;

    /**
     * HTTP POST /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.createItem
     */
    createItem(iDto: ShoeboxItemRequestDto, options?: O): RestResponse<ShoeboxItemResponseDto>;

    /**
     * HTTP DELETE /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.deleteItem
     */
    deleteItem(iDto: IdDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/shoebox/item
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.updateItem
     */
    updateItem(iDto: ShoeboxItemRequestDto, options?: O): RestResponse<ShoeboxItemResponseDto>;

    /**
     * HTTP GET /api/v2/shoebox/item/{id}
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.findById
     */
    findById(id: string, options?: O): RestResponse<ShoeboxItemResponseDto>;

    /**
     * HTTP GET /api/v2/shoebox/lender
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.getItemsByLender
     */
    getItemsByLender(options?: O): RestResponse<ShoeboxItemResponseDto[]>;

    /**
     * HTTP GET /api/v2/shoebox/loan
     * Java method: ai.mysherpas.app.shoebox.ShoeboxController.getItemsByLoan
     */
    getItemsByLoan(queryParams: { loan: string; }, options?: O): RestResponse<ShoeboxItemResponseDto[]>;

    /**
     * HTTP GET /api/v2/support/categories
     * Java method: ai.mysherpas.app.support.SupportController.getCategories
     */
    getCategories(options?: O): RestResponse<CategoryDto[]>;

    /**
     * HTTP POST /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.createCategory
     */
    createCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto>;

    /**
     * HTTP PUT /api/v2/support/category
     * Java method: ai.mysherpas.app.support.SupportController.updateCategory
     */
    updateCategory(iDto: CategoryDto, options?: O): RestResponse<CategoryDto>;

    /**
     * HTTP DELETE /api/v2/support/category/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteCategory
     */
    deleteCategory(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/support/entries
     * Java method: ai.mysherpas.app.support.SupportController.getEntries
     */
    getEntries(options?: O): RestResponse<SupportResponseDto[]>;

    /**
     * HTTP POST /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.createEntry
     */
    createEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP PUT /api/v2/support/entry
     * Java method: ai.mysherpas.app.support.SupportController.updateEntry
     */
    updateEntry(iDto: SupportRequestDto, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP DELETE /api/v2/support/entry/{id}
     * Java method: ai.mysherpas.app.support.SupportController.deleteEntry
     */
    deleteEntry(id: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/support/views/increment/{supportId}
     * Java method: ai.mysherpas.app.support.SupportController.incrementViews
     */
    incrementViews(supportId: string, options?: O): RestResponse<SupportResponseDto>;

    /**
     * HTTP GET /api/v2/tasks/consolidated/{loanId}
     * Java method: com.byzpass.demo.task.Task2Controller.consolidatedTasks
     */
    consolidatedTasks(loanId: string, options?: O): RestResponse<ConsolidatedTasksDto>;

    /**
     * HTTP POST /api/v2/tasks/element/{id}
     * Java method: com.byzpass.demo.task.Task2Controller.tasksForV2Element
     */
    tasksForV2Element(id: string, iNewTaskDtos: NewTaskDto[], options?: O): RestResponse<NewTaskResponseDto2[]>;

    /**
     * HTTP POST /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.create
     */
    create(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP DELETE /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.delete
     */
    delete(iDto: TemplatesRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.findAllByUser
     */
    findAllByUser(options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP PUT /api/v2/templates
     * Java method: ai.mysherpas.app.templates.TemplatesController.update
     */
    update(iDto: TemplatesRequestDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP GET /api/v2/templates/borrower
     * Java method: ai.mysherpas.app.templates.TemplatesController.findByEntityTypeAndUser
     */
    findByEntityTypeAndUser(queryParams: { entityType: SherpaEntityType; loanId: string; }, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP POST /api/v2/templates/copy
     * Java method: ai.mysherpas.app.templates.TemplatesController.copyTemplate
     */
    copyTemplate(iDto: CopyTemplateDto, options?: O): RestResponse<TemplatesResponseDto>;

    /**
     * HTTP GET /api/v2/templates/download/company/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForCompany
     */
    downloadCsvForCompany(id: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/download/{id}
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForSelected
     */
    downloadCsvForSelected(id: string, options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadBlank
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadBlank
     */
    downloadBlank(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadCsv
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadCsvForUser
     */
    downloadCsvForUser(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api/v2/templates/downloadMultiple
     * Java method: ai.mysherpas.app.templates.TemplatesController.downloadRequestedTemplates
     */
    downloadRequestedTemplates(queryParams: { id: string[]; }, options?: O): RestResponse<string>;

    /**
     * HTTP POST /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.createElements
     */
    createElements$POST$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP DELETE /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.deleteElements
     */
    deleteElements$DELETE$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/v2/templates/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.updateElements
     */
    updateElements$PUT$api_v2_templates_elements(iDto: TemplateElementsRequestDto, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP POST /api/v2/templates/elements/answer
     * Java method: ai.mysherpas.app.templates.TemplatesController.addAnswerToElement
     */
    addAnswerToElement$POST$api_v2_templates_elements_answer(iDto: AnswerTemplateElementDto, options?: O): RestResponse<TemplateElementResponseDto>;

    /**
     * HTTP GET /api/v2/templates/types
     * Java method: ai.mysherpas.app.templates.TemplatesController.getLocationTypes
     */
    getLocationTypes(options?: O): RestResponse<TemplateTypeMapsDto>;

    /**
     * HTTP POST /api/v2/templates/upload
     * Java method: ai.mysherpas.app.templates.TemplatesController.upload
     */
    upload(queryParams: { file: Blob; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/v2/templates/{id}/elements
     * Java method: ai.mysherpas.app.templates.TemplatesController.getElements
     */
    getElements(id: string, options?: O): RestResponse<TemplateElementsResponseDto>;

    /**
     * HTTP GET /api/v2/templates/{id}/usage
     * Java method: ai.mysherpas.app.templates.TemplatesController.getTemplateUsage
     */
    getTemplateUsage(id: string, options?: O): RestResponse<TemplateUsageResponseDto>;

    /**
     * HTTP DELETE /api/v2/users/avatar
     * Java method: com.byzpass.demo.security.AppUserController.deleteUserAvatar
     */
    deleteUserAvatar(iDto: AppUserAvatarDto, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/v2/users/avatar
     * Java method: com.byzpass.demo.security.AppUserController.setUserAvatar
     */
    setUserAvatar(iDto: AppUserAvatarDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP PUT /api/v2/users/avatar
     * Java method: com.byzpass.demo.security.AppUserController.updateUserAvatar
     */
    updateUserAvatar(iDto: AppUserAvatarDto, options?: O): RestResponse<AppUserDTO2>;

    /**
     * HTTP GET /api/v2/users/loggedUser
     * Java method: com.byzpass.demo.security.AppUserController.getCheckLoggedUser
     */
    getCheckLoggedUser(options?: O): RestResponse<OnboardDTO>;

    /**
     * HTTP GET /api/v2/users/search
     * Java method: com.byzpass.demo.security.AppUserController.search
     */
    search$GET$api_v2_users_search(queryParams: { slice: string; }, options?: O): RestResponse<AppUserDTO2[]>;

    /**
     * HTTP POST /api/v2/users/{userId}/creditConsent
     * Java method: com.byzpass.demo.security.AppUserController.addSoftCreditPull
     */
    addSoftCreditPull(userId: string, iNewCreditConsentDTO: NewCreditConsentDTO, options?: O): RestResponse<CreditConsent>;

    /**
     * HTTP GET /api/v2/users/{userId}/images/getUrlsToDownloadDriversLicenseImages
     * Java method: com.byzpass.demo.security.AppUserController.usDriversLicenseDownloadImages
     */
    usDriversLicenseDownloadImages(userId: string, options?: O): RestResponse<DriversLicenseImages>;

    /**
     * HTTP GET /api/v2/users/{userId}/images/getUrlsToUploadDriversLicenseImages
     * Java method: com.byzpass.demo.security.AppUserController.usDriversLicenseUploadImages
     */
    usDriversLicenseUploadImages(userId: string, options?: O): RestResponse<DriversLicenseImages>;

    /**
     * HTTP POST /api/v2/users/{userId}/usDriversLicense
     * Java method: com.byzpass.demo.security.AppUserController.addDriversLicense
     */
    addDriversLicense(userId: string, iUSDriversLicense: USDriversLicense, options?: O): RestResponse<USDriversLicense>;

    /**
     * HTTP POST /beep/toxiproxy/disable
     * Java method: com.byzpass.demo.util.ToxiproxyController.disableToxics
     */
    disableToxics(options?: O): RestResponse<string>;

    /**
     * HTTP POST /beep/toxiproxy/enable
     * Java method: com.byzpass.demo.util.ToxiproxyController.enableToxics
     */
    enableToxics(options?: O): RestResponse<string>;

    /**
     * HTTP POST /test/500
     * Java method: com.byzpass.demo.common.TestController.a500
     */
    a500(options?: O): RestResponse<string>;
}

export interface AddEntityDto {
    createFromTemplateId: string;
    entityType: EntityType;
    knowledgeBase: KnowledgeBase;
    loanId: string;
    name: string;
}

export interface AddPersonToLoanDto {
    createFromTemplateId: string;
    loanId: string;
    personId: string;
}

export interface Address {
    citySuburb: string;
    country: string;
    currentAddress: boolean;
    moveInDate: string;
    moveOutDate: string;
    postOfficeBox: string;
    postalCode: string;
    stateProvinceRegion: string;
    streetAddressLine1: string;
    streetAddressLine2: string;
}

export interface AddressEntity extends BaseObject {
    address: Address;
    personalInformation: PersonalInformation;
}

export interface AddressEntityDto {
    address: Address;
}

export interface AnonConfirmCodeRequestDto {
    code: string;
    emailAddress: string;
    lenderUniqueId: string;
}

export interface AnonCreateSessionForUserDto {
    lenderUniqueId: string;
    userId: string;
}

export interface AnonRequestDto {
    documentId: string;
    documentName: string;
    emailAddress: string;
    trash: boolean;
}

export interface AnonSessionDto {
    documents: Document[];
    emailAddress: string;
    expires: DateAsString;
    serverTime: DateAsString;
    sessionId: string;
}

export interface AnonSubmitDto {
    emailAddress: string;
    familyName: string;
    givenName: string;
    lenderUniqueId: string;
    message: string;
    phone: PhoneNumber;
    shortCode: string;
}

export interface AnonVerifyEmailRequestDto {
    emailAddress: string;
}

export interface AnswerFormElementDto {
    answerId: string;
    documentId: string;
    elementId: string;
    isMerged: boolean;
    submit: boolean;
}

export interface AnswerTemplateElementDto {
    answerId: string;
    documentId: string;
    elementId: string;
}

export interface AnswerV2Dto {
    active: boolean;
    document: DocumentAnswerV2Dto;
    id: string;
}

export interface AppUser extends BaseObject, IUser {
    admin: boolean;
    appleEmailConfirmed: boolean;
    appleSub: string;
    authorities: GrantedAuthority[];
    azureToken: TokenEntity;
    azureadEmailConfirmed: boolean;
    azureadSub: string;
    emailAddressConfirmed: boolean;
    googleEmailConfirmed: boolean;
    googleSub: string;
    initials: string;
    left: boolean;
    locked: boolean;
    lockedUntil: DateAsString;
    loginLevel: LoginLevelType;
    managerLender: boolean;
    mobilePhone: PhoneNumber;
    password: string;
    positionOrTitle: string;
    provider: AuthProviderType;
    providerId: string;
    secondsLocked: number;
    stub: boolean;
    subRoleDefault: SubRole;
    systemAccount: boolean;
    userKey: string;
    yahooEmailConfirmed: boolean;
    yahooSub: string;
}

export interface AppUserAvatarDto {
    documentId: string;
    userId: string;
}

export interface AppUserChangePasswordRequestDto {
    city: string;
    country: string;
    newPassword: string;
    oldPassword: string;
    state: string;
    token: string;
    userId: string;
}

export interface AppUserDTO2 {
    avatarDocId: string;
    citizenshipCountry: string;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    driverLicenceInfo: UsDriverLicenceInfoDto;
    emailAddress: string;
    employments: EmploymentEntity[];
    familyName: string;
    features: Features[];
    givenName: string;
    id: string;
    isPasswordExpiring: boolean;
    loginLevel: LoginLevelType;
    maritalStatus: string;
    mobilePhone: PhoneNumber;
    passportNumber: string;
    passwordExpiration: DateAsString;
    permanentResidenceCardNumber: string;
    permanentResidentAlien: boolean;
    placeOfBirth: string;
    positionOrTitle: string;
    residentialAddresses: AddressEntity[];
    roleDefault: Role;
    stub: boolean;
    subRoleDefault: SubRole;
    systemAccount: boolean;
    usCitizen: boolean;
    username: string;
}

/**
 * @deprecated for removal
 */
export interface AppUserDto extends BaseObjectDto<AppUser> {
    emailAddress?: string;
    familyName?: string;
    givenName?: string;
    mobilePhone?: PhoneNumber;
    roleDefault?: Role;
    subRoleDefault?: SubRole;
    username?: string;
}

export interface AppUserForgotPasswordRequestDto {
    confirmationCode: string;
    confirmationCode2: string;
    lenderUniqueId: string;
    password: string;
    provider: string;
    url: string;
    username: string;
}

export interface AppUserPreferencesDTO {
    agreedToTOS: boolean;
    camScanMode: string;
    dadZipFileHandling: string;
    fileElementUploader: string;
    formElementNavigator: string;
    formElementTreeMapDepth: number;
    formElementTreeMapHideFiles: boolean;
    formElementsSortingEnabled: boolean;
    hiddenLoanStatuses: string;
    previewMode: boolean;
    sendEmailAddRemoveUser: boolean;
    sendEmailAppMessage: boolean;
    sendEmailLoanStatus: boolean;
    sendSmsNotificationOnMessageReceived: boolean;
    sendTextAddRemoveUser: boolean;
    sendTextAppMessage: boolean;
    sendTextLoanStatus: boolean;
    simpleUiEnabled: boolean;
    timezoneId: string;
    useAlternativeFilePreviewer: boolean;
}

export interface AppUserResponseDto extends Id {
    emailAddress: string;
    familyName: string;
    givenName: string;
    middleName: string;
    pendingInvite: boolean;
    roleDefault: Role;
    roleInvited: Role;
    subRoleDefault: SubRole;
}

export interface AppUserSignupRequestDto {
    confirmationCode: string;
    email: string;
    familyName: string;
    givenName: string;
    invitationId: string;
    mobilePhone: PhoneNumber;
    password: string;
    positionOrTitle: string;
    roleDefault: Role;
    subRoleDefault: SubRole;
    url: string;
    username: string;
}

export interface ApplyTemplateDto {
    entityId: string;
    loanId: string;
    templateId: string;
}

export interface AssetEntityCustomizationsDto extends Serializable {
    bookValueDiscount?: number;
    deposit?: number;
    internalReviewValueForMla?: number;
    lenderPolicyLtv?: number;
    ltc: number;
    ltv: number;
}

export interface AssetResponseInformation extends InformationResponseDto {
    deposit?: number;
    mostImportantCost?: number;
    mostImportantValue?: number;
}

export interface AutoCloseable {
}

export interface BankAssetRequestDto extends OwnerInformationRequestDto {
    accountNumber: string;
    bankOrBrokerageAddress: Address;
    bankOrBrokerageName: string;
    bankOrBrokeragePhone: string;
    bankRoutingNumber: string;
    bankerOrBrokerEmail: string;
    bankerOrBrokerName: string;
    bankerOrBrokerPhone: string;
    bondDateIssued: DateAsString;
    bondDateRedeemed: DateAsString;
    bondSerialNumber: string;
    bondSeries: string;
    bondValueSource: string;
    cusip: string;
    stockTickerSymbol: string;
    value: number;
}

export interface BankAssetResponseDto extends BankAssetRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface BaseController {
}

export interface BaseObject extends Id {
    createdBy: string;
    createdDate: DateAsString;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
}

/**
 * @deprecated
 */
export interface BaseObjectDto<T> {
    id?: string;
}

export interface BaseResultDTO {
    EndIndex: number;
    GroupCount: number;
    StartIndex: number;
    Status: ClearReportApiStatus;
    Uri: string;
    groupId: string;
    resultCount: number;
    templateNameError: string;
    templateNameSucess: string;
}

export interface BaseStoredTaskDto extends BaseTask {
    formElement: string;
    formElement2: string;
    targetId: string;
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface BaseTask extends ITask {
}

export interface BasicLoanDto {
    entities: LoanEntityDto[];
    id: string;
    lastMessageDate: DateAsString;
    lender: CompanyDto;
    loanRoles: BasicLoanRoleDto[];
    projectName: string;
    shortCode: string;
}

export interface BasicLoanRoleDto {
    id: string;
    role: Role;
    user: AppUserDTO2;
    visibleToBorrower: boolean;
}

export interface BorrowUserSetDto {
    appUser: string;
    borrowerType: BorrowerType;
    citizenshipCountry: string;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    dob: DateAsString;
    driverLicenceInfo: UsDriverLicenceInfoDto;
    emailAddress: string;
    employments: EmploymentDto[];
    familyName: string;
    givenName: string;
    maritalStatus: string;
    middleName: string;
    mobilePhone: PhoneNumber;
    passportNumber: string;
    permanentResidenceCardNumber: string;
    permanentResidentAlien: boolean;
    placeOfBirth: string;
    positionOrTitle: string;
    residentialAddress: Address;
    residentialAddresses: AddressEntityDto[];
    role: Role;
    sendInvite: boolean;
    ssn: string;
    templateId: string;
    usCitizen: boolean;
}

export interface BusinessDatasourcesDTO {
    NPIRecord: boolean;
    PublicRecordBusiness: boolean;
    PublicRecordUCCFilings: boolean;
    WorldCheckRiskIntelligence: boolean;
}

export interface BusinessQuickAnalysisFlagCriteria {
    CompanyRiskFlags: any;
    EntityId: string;
    GroupId: string;
    xmlns: string;
}

export interface BusinessQuickAnalysisFlagRequestDTO {
    Criteria: CriteriaDTO;
    PermissiblePurpose: PermissiblePurpose;
    xmlns: string;
}

export interface BusinessQuickAnalysisFlagResponseDetail {
    CompanyRiskFlagsWithDocguids: any;
}

export interface BusinessQuickAnalysisFlagResultDTO extends BaseResultDTO {
    ResultGroup: BusinessQuickAnalysisFlagResultGroup;
}

export interface BusinessQuickAnalysisFlagResultGroup {
    GroupId: string;
    RecordCount: number;
    RecordDetails: BusinessQuickSearchFlagRecordDetail[];
    mainRecordDetails: BusinessQuickSearchFlagRecordDetail;
}

export interface BusinessQuickSearchFlagRecordDetail {
    CompanyQuickAnalysisFlagResponseDetail: BusinessQuickAnalysisFlagResponseDetail;
    bankruptcy: string;
    businessUsedAsResidentialAddress: string;
    globalSanctions: string;
    goingConcern: string;
    healthcareSanction: string;
    keyNatureOfSuit: string;
    listingLinkedToBusinessAddress: string;
    listingLinkedToBusinessPhone: string;
    listingLinkedToSameFEIN: string;
    marijuanaRelatedBusiness: string;
    msblisting: string;
    ofac: string;
    pendingClassAction: string;
    poBoxAddress: string;
    prisonAddress: string;
    worldCheck: string;
}

export interface BusinessRecordDetail {
    BusinessResponseDetail: any;
}

export interface BusinessRequestDto extends OwnerInformationRequestDto {
    annualSales: number;
    companyAddress: Address;
    dbaName: string;
    duns: string;
    ein: string;
    emailAddress: string;
    entityName: string;
    establishedDate: DateAsString;
    mailingAddress: Address;
    mailingSameAsCompanyAddress: boolean;
    naics: string;
    numberOfEmployees: number;
    phoneNumber: PhoneNumber;
    website: string;
}

export interface BusinessResponseDto extends BusinessRequestDto, InformationResponseDto {
    clearReport: ClearReportDTO;
}

export interface BusinessResultGroup {
    DominantValues: any[];
    GroupId: string;
    RecordCount: number;
    RecordDetails: BusinessRecordDetail[];
    Relevance: number;
}

export interface BusinessSearchRequestDTO {
    Criteria: CriteriaDTO;
    Datasources: BusinessDatasourcesDTO;
    PermissiblePurpose: PermissiblePurpose;
    Reference: string;
    xmlns: string;
}

export interface BusinessSearchResultsDTO extends BaseResultDTO {
    ResultGroup: BusinessResultGroup;
}

export interface CategoryDto {
    icon: string;
    id: string;
    name: string;
    totalEntries: number;
}

export interface ChangeEventDTO {
    loanId: string;
}

export interface ClearReportApiStatus {
    Message: string;
    Reference: string;
    StatusCode: number;
    SubStatusCode: number;
}

export interface ClearReportDTO {
    error: string;
    status: ClearReportStatus;
    type: ClearReportType;
}

export interface Company extends BaseObject {
    clientAddress: string;
    email: string;
    enabled: boolean;
    hasBorrowerOnboard: boolean;
    mailingAddress: Address;
    name: string;
    phoneNumber: PhoneNumber;
    preferences: CompanyPreferences;
    shortPackageNames: boolean;
    subdomain: string;
    supportEmailAddresses: string;
    supportPhoneNumbers: string;
    venturesConnectionLastChecked: DateAsString;
    venturesConnectionLastStatus: boolean;
    venturesEnabled: boolean;
    venturesEnvironment: VentureEnvironment;
    website: string;
}

export interface CompanyDto extends Serializable {
    createdBy: string;
    createdDate: DateAsString;
    email: string;
    hasBorrowerOnboard: boolean;
    id: string;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
    name: string;
    phoneNumber: PhoneNumber;
    preferences: CompanyPolicyDto;
    subdomain: string;
    supportEmailAddresses: string;
    venturesEnabled: boolean;
    website: string;
}

export interface CompanyEmployeeListDto {
    employees: AppUserDTO2[];
}

export interface CompanyListDto {
    city: string;
    clientAddress: string;
    country: string;
    email: string;
    enabled: boolean;
    hasBorrowerOnboard: boolean;
    id: string;
    name: string;
    phoneNumber: PhoneNumber;
    postalCode: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
    subdomain: string;
    supportEmailAddresses: string;
    supportPhoneNumbers: string;
    venturesConnectionLastChecked: string;
    venturesConnectionLastStatus: boolean;
    venturesEnabled: boolean;
    venturesEnvironment: VentureEnvironment;
    venturesPassword: string;
    venturesSubdomain: string;
    venturesUsername: string;
    website: string;
}

export interface CompanyPolicyDto extends Serializable {
    lenderPolicyLtv: string;
}

export interface CompanyPreferences extends BaseObject {
    lenderPolicyLtv: number;
}

export interface Consent extends BaseObject {
    acceptedDate: DateAsString;
    active: boolean;
    consentStatus: ConsentStatus;
    expireDate: DateAsString;
    expired: boolean;
    signature: string;
}

export interface ConsolidatedTasksDto {
    perTask: { [index: string]: number };
    perUser: { [index: string]: UserTaskDetailsDto };
}

export interface ConversionRequestDto {
    documentId: string;
}

export interface ConversionResponseDto {
    newDocumentId: string;
}

export interface CopyFormElementsDto {
    loanId: string;
    newParentId: string;
    sourceIds: string[];
}

export interface CopyTemplateDto {
    newCompanyId: string;
    newName: string;
    templateId: string;
}

export interface CreateEntityCompleteDto {
    bankAssetInfo: BankAssetRequestDto;
    entityCreateDto: EntityCreateDto;
    entityLabelDto: EntityLabelDto;
    equipmentInfo: EquipmentRequestDto;
    randomAssetInfo: RandomAssetRequestDto;
    realEstateInfo: RealEstateRequestDto;
    renameDto: RenameEntityDto;
    templateDto: ApplyTemplateDto;
}

export interface CreateUpdateAppUserDto {
    emailAddress: string;
    familyName: string;
    givenName: string;
    id: string;
    mobilePhone: PhoneNumber;
    roleDefault: Role;
    subRoleDefault: SubRole;
}

export interface CreditConsent extends Consent {
    creditScore: number;
}

export interface CriteriaDTO {
    "b1:BusinessCriteria": any;
    "cq1:CompanyQuickAnalysisFlagCriteria": BusinessQuickAnalysisFlagCriteria;
    "p1:PersonCriteria": any;
    "pq1:PersonQuickAnalysisFlagCriteria": PersonQuickAnalysisFlagCriteria;
}

export interface DatasourcesDTO {
    NPIRecord: boolean;
    PublicRecordPeople: boolean;
    WorldCheckRiskIntelligence: boolean;
}

export interface DeepLinkActionRequestDto {
    actionType: DeepLinkActionType;
    companyID: string;
    forUserId: string;
    infoId: string;
    loanId: string;
    messageId: string;
    taskViewType: TaskViewType;
}

export interface DeepLinkNoDatabaseDto {
    actionType: DeepLinkActionType;
    companyId: string;
    loanId: string;
    objectId: string;
    objectType: ActionObjectType;
    taskViewType: TaskViewType;
    userId: string;
}

export interface DeferredResult<T> {
    errorResult: any;
    result: any;
    resultHandler: DeferredResultHandler;
    resultInternal: any;
    setOrExpired: boolean;
}

export interface DeferredResultHandler {
}

export interface DestinationDto {
    locationType: LocationType;
    matchersEx: string;
    replaceFileName: NamingStrategyType;
    replaceFolderName: NamingStrategyType;
}

export interface DlRequest {
    dl: any;
    dlBackDocumentId: string;
    dlFrontDocumentId: string;
    dto: IndividualRequestDto;
}

export interface Document extends BaseObject {
    documentType: DocumentType;
    /**
     * @deprecated
     */
    downloadUrl: URL;
    downloadUrlSaved: string;
    downloadUrlValidUntil: DateAsString;
    lender?: Company;
    message: DocumentMessage;
    name: string;
    providerType: ProviderType;
    virusScanState: VirusScanState;
}

export interface DocumentAnswerV2Dto {
    createdByUser: AppUserDTO2;
    createdDate: string;
    documentType: DocumentType;
    downloadUrlSaved: string;
    id: string;
    lastModifiedDate: string;
    message: DocumentMessage;
    name: string;
    providerType: ProviderType;
    virusScanState: VirusScanState;
}

export interface DocumentMessage extends BaseObject {
    message: string;
}

export interface DocumentUpdateDto {
    id: string;
    name: string;
}

export interface DriversLicenseImages {
    back: URL;
    front: URL;
}

export interface DynamicNeedsListRequestDto {
    infoIds: string[];
    loanId: string;
    userIds: string[];
}

export interface EmailTestIdRequestDto {
    id: string;
}

export interface EmployeeInfoDto {
    address: Address;
    addressSameAsCompany: boolean;
    deleteUploadPageDoc: boolean;
    employeeId: string;
    facebookUrl: string;
    instagramUrl: string;
    linkedinUrl: string;
    secureUploadPageEnabled: boolean;
    secureUploadPageUrl: string;
    simpleViewOnly: boolean;
    tiktokUrl: string;
    twitterUrl: string;
    uniqueId: string;
    uploadPageDocId: string;
    uploadPageMsg: string;
    youtubeUrl: string;
}

export interface EmployeePublicDetailsDto {
    avatarId: string;
    companyAddress: string;
    companyId: string;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    emailAddress: string;
    employeeInfo: EmployeeInfoDto;
    familyName: string;
    givenName: string;
    mobilePhone: PhoneNumber;
    positionOrTitle: string;
}

export interface EmploymentDto {
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    currentEmployer: boolean;
    endDate: string;
    monthlyIncome: number;
    positionOrTitle: string;
    startDate: string;
    supervisorName: string;
    type: EmploymentType;
}

export interface EmploymentEntity extends BaseObject {
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    currentEmployer: boolean;
    endDate: string;
    monthlyIncome: number;
    personalInformation: PersonalInformation;
    positionOrTitle: string;
    startDate: string;
    supervisorName: string;
    type: EmploymentType;
}

export interface EntityCreateDto {
    name: string;
    type?: SherpaEntityType;
}

export interface EntityLabelDto {
    customizations?: AssetEntityCustomizationsDto;
    label: LoanEntityLabel[];
    loanId: string;
    sherpaEntityId: string;
    userLabels: string[];
}

export interface EntityResponseDto {
    id: string;
    name: string;
    type: SherpaEntityType;
    userIndividualId: string;
}

export interface EquipmentRequestDto extends OwnerInformationRequestDto {
    appraisedValue: number;
    bookValue: number;
    bookValueDiscount: number;
    bookValueSource: string;
    cashDownPayment: number;
    condition: string;
    hours: number;
    make: string;
    makeYear: string;
    mileage: number;
    model: string;
    purchasePrice: number;
    repairCost: number;
    serialNumber: string;
    totalCost: number;
    tradeInValue: number;
    ucc1FilingNumber: string;
    vehicleIdentificationNumber: string;
}

export interface EquipmentResponseDto extends EquipmentRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface EventLoginHistoryDto {
    lastFailLogin: DateAsString;
    lastSuccessLogin: DateAsString;
    minutesToLock: number;
    numberFailedLogins: number;
}

export interface FeKeys {
    googleMapsKey: string;
    pdftronKey: string;
    syncfusionKey: string;
}

export interface FePreferenceEntityResponseDto {
    key: string;
    user: AppUserDTO2;
    value: string;
}

export interface FileAnswerDTO {
    documentId: string;
    id: string;
}

export interface FileUrlDTO {
    url: string;
}

export interface FormElementActionRequestDto {
    action: ElementActionType;
    elementId: string;
    includeNote: boolean;
    infoId: string;
    loanId: string;
    noteContent: string;
    noteVisibility: NoteVisibilityType;
    userId: string;
}

export interface FormElementExpirationDto {
    canHaveExpiration: boolean;
    expireDate: DateAsString;
    reAskedRefId: string;
}

export interface FormElementPackageInfoResponseDto {
    approved: boolean;
    approvedByUser: AppUserDTO2;
    childrenIds: string[];
    description: string;
    displayOrder: number;
    elementId: string;
    hidden: boolean;
    id: string;
    includeDescription: boolean;
    locations: LocationDto[];
    parentInfoId: string;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUser: AppUserDTO2;
    title: string;
    visibleAtStatus: LoanReviewStatus;
}

export interface FormElementUrlResponseDto {
    url: string;
}

export interface FormElementV2RequestDto {
    answerId: string;
    approved: boolean;
    approvedByUserId: string;
    assigned: boolean;
    assignedToUserId: string;
    canHaveExpiration: boolean;
    description: string;
    displayOrder: number;
    documentId: string;
    expireDate: DateAsString;
    hasExpiration: boolean;
    hidden: boolean;
    id: string;
    inProgress: boolean;
    includeDescription: boolean;
    knowledgeBase: KnowledgeBase;
    loanId: string;
    modifiers: ElementModifierType[];
    needsLegalReview: boolean;
    parentId: string;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUserId: string;
    removeSherpaEntity: boolean;
    reviewing: boolean;
    sherpaEntityId: string;
    sherpaEntityType: SherpaEntityType;
    status: ElementStatusType;
    storageType: StorageType;
    submit: boolean;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface FormElementV2ResponseDto {
    answer: AnswerV2Dto;
    answerPending: boolean;
    approved: boolean;
    approvedByUser: AppUserDTO2;
    assigned: boolean;
    assignedToUser: AppUserDTO2;
    canHaveExpiration: boolean;
    childrenIds: string[];
    createdBy: string;
    createdDate: DateAsString;
    displayOrder: number;
    expiration: FormElementExpirationDto;
    expireDate: DateAsString;
    expired: boolean;
    hasExpiration: boolean;
    hasTemplateAnswer: boolean;
    hidden: boolean;
    id: string;
    isExpiring: boolean;
    knowledgeBase: KnowledgeBase;
    lastModifiedBy: string;
    lastModifiedByUser: AppUserDTO2;
    lastModifiedDate: DateAsString;
    loanId: string;
    modifiers: ElementModifierType[];
    originalTitle: string;
    parentId: string;
    percentageAnswered: number;
    percentageApproved: number;
    priorityType: PriorityType;
    rejected: boolean;
    rejectedByUser: AppUserDTO2;
    sherpaEntityId: string;
    sherpaEntityType: SherpaEntityType;
    state: FormElementState;
    status: ElementStatusType;
    storageType: StorageType;
    templateElementId: string;
    title: string;
    venturesDocType: VenturesDocType;
}

export interface FormElementsActionRequestDto {
    actions: FormElementActionRequestDto[];
}

export interface FormElementsRequestDto {
    elements: FormElementV2RequestDto[];
    multiSelect: boolean;
}

export interface FormElementsV2ResponseDto {
    elements: { [index: string]: FormElementV2ResponseDto };
    linkInfo: { [index: string]: FormElementPackageInfoResponseDto };
    loanId: string;
    packageInfo: { [index: string]: FormElementPackageInfoResponseDto };
    sharedInfo: { [index: string]: PackageInfoSharingResponseDto[] };
}

export interface GeneratedPackageDto extends Serializable {
    document: Document;
    errorMessage: string;
    finished: DateAsString;
    started: DateAsString;
    state: GenerationStatus;
}

export interface GrantedAuthority extends Serializable {
    authority: string;
}

export interface HttpEntity<T> {
    body: T;
    headers: { [index: string]: string[] };
}

export interface ITask {
    action: string;
    contextId: string;
    deepLink?: string;
    description: string;
    fullTitle: string;
    hasEntity: boolean;
    hidden: boolean;
    id: string;
    includeDescription: boolean;
    loanId: string;
    needsAttention: boolean;
    notes: NoteResponseDto[];
    originalTitle: string;
    status: ElementStatusType;
    subjectName: string;
    taskType: string;
    title: string;
}

export interface IUser extends Id {
    emailAddress: string;
    employer: Company;
    familyName: string;
    features: Features[];
    givenName: string;
    name: string;
    roleDefault: Role;
    username: string;
}

export interface Id {
    id: string;
}

export interface IdDto {
    id: string;
}

export interface Individual extends SherpaEntity {
    userIndividual: AppUser;
}

export interface IndividualRequestDto {
    citizenshipCountry: string;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    complianceType: string;
    countryIdentification: string;
    dd: string;
    dob: DateAsString;
    emailAddress: string;
    employments: EmploymentDto[];
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    fullName: string;
    givenName: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    legalFamilyName: string;
    legalGivenName: string;
    legalMiddleName: string;
    mailingAddress: Address;
    maritalStatus: string;
    mobilePhone: PhoneNumber;
    number: string;
    organDonorIndicator: string;
    passportNumber: string;
    permanentResidenceCardNumber: string;
    permanentResidentAlien: boolean;
    placeOfBirth: string;
    residentialAddress: Address;
    residentialAddresses: AddressEntityDto[];
    restrictions: string;
    sex: string;
    ssn: string;
    state: UnitedStates;
    usCitizen: boolean;
    userIndividual: AppUserDTO2;
    vehicleClass: string;
    weight: string;
}

export interface IndividualResponseDto extends IndividualRequestDto, InformationResponseDto {
    clearReport: ClearReportDTO;
}

export interface Information<ENTITY> extends BaseObject {
    about: ENTITY;
    dlBack: Document;
    dlFront: Document;
    govDocumentScanned: Document;
    simpleOwners: string;
    source: Source;
    transunionReportWeGotThisFrom: any;
    userWhoSubmittedInformation: AppUser;
}

export interface InformationResponseDto {
    dlBack?: Document;
    dlFront?: Document;
    id: string;
    simpleOwners?: string;
    userWhoSubmittedInformation: AppUserDTO2;
}

export interface InviteToLoanDTO {
    loanId: string;
    userId: string;
}

export interface Iterable<T> {
}

export interface LabelDeleteDto {
    toDelete: string[];
}

export interface LabelsListDto {
    labels: SimpleLabelDto[];
}

export interface LeadUserSetDto {
    appUser: string;
    borrowerType: BorrowerType;
    canAcceptFiles: boolean;
    leadBorrower: boolean;
    leadLender: boolean;
    newToLoan: boolean;
    role: Role;
    visibleToBorrower: boolean;
}

export interface LinkActionInviteToLoanResponseDto {
    accepted: boolean;
    actionType: LinkActionType;
    authRequest: boolean;
    createdDate: DateAsString;
    email: string;
    expires: DateAsString;
    id: string;
    invitedBy: AppUserDTO2;
    loan: BasicLoanDto;
    role: Role;
    signupCandidate: boolean;
    subRole: SubRole;
    userInvited: AppUserDTO2;
}

export interface LoanDto extends Serializable {
    actualLoanAmortization?: number;
    actualLoanAmount?: number;
    actualLoanTerm?: number;
    amortizationInMonths?: number;
    /**
     * @deprecated
     */
    applicationLead: AppUserDTO2;
    apr: number;
    /**
     * @deprecated
     */
    borrowerTeamUsers: AppUserDTO2[];
    borrowers: AppUserDTO2[];
    closeDate: DateAsString;
    contacts: AppUserDTO2[];
    createdBy: string;
    createdDate: DateAsString;
    downPayment: number;
    downPaymentPercentage?: number;
    downPaymentSource: string;
    entityName: string;
    entityType: EntityType;
    executiveSummary: string;
    id: string;
    index?: number;
    lastModifiedBy: string;
    lastModifiedDate: DateAsString;
    lender: CompanyDto;
    /**
     * @deprecated
     */
    lenderTeamUsers: AppUserDTO2[];
    loanAmount: number;
    loanEntities: LoanEntityDto[];
    loanOriginationFees?: number;
    loanOriginationPoints?: number;
    loanOtherFees?: number;
    loanPhase: LoanPhaseDto;
    loanPurpose: string;
    loanRoles: LoanRoleDto[];
    loanStatus: LoanStatus;
    loanTermInMonths: number;
    locked: boolean;
    margin?: number;
    needOnboard: boolean;
    pdf: GeneratedPackageDto;
    pendingInviteUsers: AppUserResponseDto[];
    percentageAnswered: number;
    percentageApproved: number;
    percentageOpen: number;
    pinned: boolean;
    /**
     * @deprecated
     */
    primaryLenderUser?: AppUserDTO2;
    projectName: string;
    reviewStatus: LoanReviewStatus;
    shortCode: string;
    template: TemplateResponseDto;
    templateId: string;
    templateProjectName: string;
    unsecured: boolean;
    userProgress: { [index: string]: UserProgress };
    ventureExportStatus?: VentureExportStatus;
    ventureExportedBy: AppUserDTO2;
    ventureLastExportTime?: string;
    ventureLoanLogNumber: string;
    ventureLoanUrl: string;
    zip: GeneratedPackageDto;
}

export interface LoanEntityDto {
    assetEntityCustomizations?: AssetEntityCustomizationsDto;
    id: string;
    label: LoanEntityLabel[];
    loan: string;
    mostRecentDriversLicenseInformation?: InformationResponseDto;
    mostRecentUserInformation?: InformationResponseDto;
    sherpaEntity: EntityResponseDto;
    templateId: string;
    templateName: string;
    userLabels: SimpleLabelDto[];
}

export interface LoanListResponseDto {
    loans: LoanDto[];
    roles: LoanRoleDto[];
}

export interface LoanPhaseDto {
    category: LoanPhaseCategoryType;
    displayOrder: number;
    id: string;
    lenderId: string;
    name: string;
}

export interface LoanRoleDto {
    borrowerType: BorrowerType;
    canAcceptFiles: boolean;
    creditConsentStatus: ConsentStatus;
    creditReportError: string;
    creditScore: number;
    id: string;
    labels: SimpleLabelDto[];
    loan: string;
    role: Role;
    softCreditStatus: string;
    stub: boolean;
    user: AppUserDTO2;
    visibleToBorrower: boolean;
}

export interface LoanStatusDTO {
    loanStatus: LoanStatus;
}

export interface LocationDto {
    locationType: LocationType;
}

export interface LoginWithConfirmCodeDto {
    confirmationCode: string;
    emailAddress: string;
}

export interface MaintenanceWindowDto {
    end: DateAsString;
    notes?: string;
    start: DateAsString;
}

export interface MessageDto {
    additionalTo: AppUser[];
    attachmentCount: number;
    body: string;
    canDelete: boolean;
    canUpdate: boolean;
    createdDate: DateAsString;
    deleted: boolean;
    deletedDate: DateAsString;
    documents: string[];
    from: AppUserDTO2;
    generationType: MessageGenerationType;
    id: string;
    important: boolean;
    inReplyToMessage: MessageRepliedDto;
    isReadByMe: DateAsString;
    labels: MessageLabel[];
    left: boolean;
    limitTimeForDelete: DateAsString;
    limitTimeForUpdate: DateAsString;
    locked: boolean;
    messageThread: MessageThreadDto;
    messageType: MessageType;
    originType: MessageOriginType;
    originalMessageContentUrl: string;
    preview: string;
    processedTime: DateAsString;
    readControl: MessageReadControlDto[];
    subject: string;
    toRecipients: MessageRecipientDto[];
    updated: boolean;
    updatedDate: DateAsString;
    visibleOnlyToMyTeam: boolean;
}

export interface MessageEventDto {
    message: MessageDto;
}

export interface MessageForwardRequestDto {
    messagesIds: string[];
}

export interface MessageFullContentUrl {
    url: string;
}

export interface MessageOriginationStuff {
    htmlContent: string;
    originalSubject: string;
    plainContent: string;
}

export interface MessagePageDto {
    content: MessageDto[];
    hasNext: boolean;
    hasPrevious: boolean;
    isFirst: boolean;
    isLast: boolean;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
}

export interface MessageReadControlDto {
    read: boolean;
    readAt: DateAsString;
    user: AppUserDTO2;
}

export interface MessageRecipientDto {
    to: AppUserDTO2;
}

export interface MessageRepliedDto {
    body: string;
    from: AppUserDTO2;
    id: string;
    preview: string;
    subject: string;
}

export interface MessageSearchPageDto {
    content: MessageDto[];
    page: number;
    pageSize: number;
}

export interface MessageSendDto {
    attachments: string[];
    body: string;
    contextId: string;
    hasLinks: boolean;
    inReplyToMessage: string;
    labels: MessageLabel[];
    locked: boolean;
    messageThreadId: string;
    messageType: MessageType;
    shareOldHistory: boolean;
    subject: string;
    toUserIds: string[];
    toUserNames: string[];
}

export interface MessageThreadDto {
    archived: boolean;
    external: boolean;
    general: boolean;
    id: string;
    inactive: boolean;
    lastMessage: MessageDto;
    lastMessageDate: DateAsString;
    lastUnreadMessageId: string;
    lendingTeam: boolean;
    locked: boolean;
    memberType: MessageThreadMemberType;
    messageThreadCategory: MessageThreadCategory;
    muted: boolean;
    title: string;
    totalMessages: number;
    totalUnreadMessages: number;
    users: MessageThreadUserDto[];
}

export interface MessageThreadUserDto {
    memberSince: DateAsString;
    user: AppUserDTO2;
}

export interface MessageUnreadDto {
    companyId: string;
    unreadLastMassageMap: { [index: string]: DateAsString };
    unreadMap: { [index: string]: number };
}

export interface MessageUpdateDto {
    attachments: string[];
    body: string;
}

export interface NaicsDto {
    code: string;
    description: string;
}

export interface NewCreditConsentDTO {
    consent: string;
    lenderId: string;
    signature: string;
}

export interface NewEntityTemplateDto {
    entityId: string;
    loanId: string;
    templateId: string;
}

export interface NewLoanDTO extends UpdateLoanDTO {
    createBlank: boolean;
    lenderId: string;
    templateId: string;
}

export interface NewLoanTemplateDto {
    entities: NewEntityTemplateDto[];
    loanId: string;
    templateId: string;
}

export interface NewTaskDto {
    fieldId: string;
    targetId: string;
    type: TaskType;
}

export interface NewTaskResponseDto2 {
    fieldId: string;
    taskId: string;
}

export interface NoteRequestDto {
    authorId: string;
    content: string;
    id: string;
    includeWithMessages: boolean;
    loanId: string;
    noteType: NoteType;
    objectId: string;
    objectType: NoteObjectType;
    visibility: NoteVisibilityType;
}

export interface NoteResponseDto {
    authorId: string;
    content: string;
    createdDate: DateAsString;
    deleted: boolean;
    elementId: string;
    entityId: string;
    id: string;
    includeWithMessages: boolean;
    lastModifiedDate: DateAsString;
    loanId: string;
    noteType: NoteType;
    objectType: NoteObjectType;
    visibility: NoteVisibilityType;
}

export interface OnboardCompany {
    city: string;
    clientAddress: string;
    country: string;
    email: string;
    enabled: boolean;
    hasBorrowerOnboard: boolean;
    name: string;
    phoneNumber: PhoneNumber;
    postalCode: string;
    state: string;
    streetAddress1: string;
    streetAddress2: string;
    subdomain: string;
    supportEmailAddresses: string;
    supportPhoneNumbers: string;
    venturesConnectionLastChecked: string;
    venturesConnectionLastStatus: boolean;
    venturesEnabled: boolean;
    venturesEnvironment: VentureEnvironment;
    venturesPassword: string;
    venturesSubdomain: string;
    venturesUsername: string;
    website: string;
}

export interface OnboardDTO {
    employer: CompanyDto;
    info: EmployeeInfoDto;
    lenders: CompanyDto[];
    needOnboard: boolean;
    user: AppUserDTO2;
    viewType: ViewType;
}

export interface OnboardUserDto {
    company: string;
    emailAddress: string;
    familyName: string;
    givenName: string;
    mobilePhone: PhoneNumber;
    password: string;
    positionOrTitle: string;
    roleDefault: Role;
    subRoleDefault: SubRole;
}

export interface Order extends Serializable {
    ascending: boolean;
    descending: boolean;
    direction: Direction;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    property: string;
}

export interface OwnerInformationRequestDto {
    simpleOwners?: string;
}

export interface PackageInfoSharesRequestDto {
    shares: PackageInfoSharingRequestDto[];
}

export interface PackageInfoSharesResponseDto {
    sharedByUser: PackageInfoSharingResponseDto[];
    sharedOnLoan: PackageInfoSharingResponseDto[];
}

export interface PackageInfoSharingRequestDto {
    id: string;
    infoId: string;
    loanId: string;
    permissions: SharePermissionType[];
    sharedByUserId: string;
    sharedWithUserId: string;
}

export interface PackageInfoSharingResponseDto {
    id: string;
    info: FormElementPackageInfoResponseDto;
    loanId: string;
    permissions: SharePermissionType[];
    sharedByUser: AppUserDTO2;
    sharedWithUser: AppUserDTO2;
}

export interface PackageUrlResponse {
    pdfPackage: URL;
    zipPackage: URL;
}

export interface Page<T> extends Slice<T> {
    totalElements: number;
    totalPages: number;
}

export interface Pageable {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: Sort;
    unpaged: boolean;
}

export interface PdfKeyDto {
    pdfKey: string;
}

export interface PdfOutlinesDTO {
    children: PdfOutlinesDTO[];
    page: number;
    title: string;
}

export interface PerUserConstants {
    countries: { [index: string]: any };
    entityTypeMap: { [index: string]: any };
    knowledgeBaseMap: { [index: string]: any };
    loanStatusMap: { [index: string]: any };
    loanTypeMap: { [index: string]: any };
    phaseCategoryMap: { [index: string]: any };
    priorityMap: { [index: string]: any };
    statesMap: { [index: string]: any };
    usersExcludedFromCounts: string[];
}

export interface PermissiblePurpose {
    DPPA: string;
    GLB: string;
    VOTER: string;
}

export interface PersonQuickAnalysisFlagCriteria {
    EntityId: string;
    GroupId: string;
    PersonRiskFlags: any;
    Reference: string;
    xmlns: string;
}

export interface PersonQuickAnalysisFlagRequestDTO {
    Criteria: CriteriaDTO;
    PermissiblePurpose: PermissiblePurpose;
    xmlns: string;
}

export interface PersonQuickAnalysisFlagResponseDetail {
    PersonRiskFlags: PersonQuickAnalysisRiskFlags;
    PersonRiskFlagsWithDocguids: any;
}

export interface PersonQuickAnalysisFlagResultDTO extends BaseResultDTO {
    ResultGroup: PersonQuickAnalysisFlagResultGroup;
}

export interface PersonQuickAnalysisFlagResultGroup {
    GroupId: string;
    RecordCount: number;
    RecordDetails: PersonQuickSearchFlagRecordDetail[];
    mainRecordDetails: PersonQuickSearchFlagRecordDetail;
}

export interface PersonQuickAnalysisRiskFlags {
    AddressReportedLessNinetyDays: string;
    AgeYoungerThanSSN: string;
    Arrest: string;
    AssocRelativeWithPOBoxAddress: string;
    AssocRelativeWithPrisonAddress: string;
    AssocRelativeWithResidentialUsedAsBusiness: string;
    AssocWithOFACGlobalPEP: string;
    AssociatedWithMarijuanaBusiness: string;
    Bankruptcy: string;
    Criminal: string;
    CriminalLowLevelTrafficOffense: string;
    CriminalUncategorized: string;
    GlobalSanctions: string;
    HealthcareSanction: string;
    MultipleSSN: string;
    OFAC: string;
    POBoxAddress: string;
    PhoneNumberInconsistentAddress: string;
    PrisonAddress: string;
    RealTimeIncarcerationAndArrest: string;
    RecordedAsDeceased: string;
    ResidentialUsedAsBusiness: string;
    SSNFormatInvalid: string;
    SSNIsITIN: string;
    SSNMultipleIndividuals: string;
    SexOffender: string;
    WorldCheck: string;
}

export interface PersonQuickSearchFlagRecordDetail {
    PersonQuickAnalysisFlagResponseDetail: PersonQuickAnalysisFlagResponseDetail;
    addressReportedLessNinetyDays: string;
    ageYoungerThanSSN: string;
    arrest: string;
    assocRelativeWithPOBoxAddress: string;
    assocRelativeWithPrisonAddress: string;
    assocRelativeWithResidentialUsedAsBusiness: string;
    assocWithOFACGlobalPEP: string;
    associatedWithMarijuanaBusiness: string;
    bankruptcy: string;
    criminal: string;
    criminalLowLevelTrafficOffense: string;
    criminalUncategorized: string;
    globalSanctions: string;
    healthcareSanction: string;
    multipleSSN: string;
    ofac: string;
    phoneNumberInconsistentAddress: string;
    poBoxAddress: string;
    prisonAddress: string;
    realTimeIncarcerationAndArrest: string;
    recordedAsDeceased: string;
    residentialUsedAsBusiness: string;
    sexOffender: string;
    ssnFormatInvalid: string;
    ssnIsITIN: string;
    ssnMultipleIndividuals: string;
    worldCheck: string;
}

export interface PersonRecordDetail {
    PersonResponseDetail: any;
}

export interface PersonResultGroup {
    DominantValues: any[];
    GroupId: string;
    RecordCount: number;
    RecordDetails: PersonRecordDetail[];
    Relevance: number;
}

export interface PersonSearchRequestDTO {
    Criteria: CriteriaDTO;
    Datasources: DatasourcesDTO;
    PermissiblePurpose: PermissiblePurpose;
    Reference: string;
    xmlns: string;
}

export interface PersonSearchResultsDTO extends BaseResultDTO {
    ResultGroup: PersonResultGroup;
}

export interface PersonalInformation extends Information<Individual> {
    about: Individual;
    citizenshipCountry: string;
    companyAddress: Address;
    companyName: string;
    companyPhone: PhoneNumber;
    companyWebsite: string;
    complianceType: string;
    countryIdentification: string;
    dd: string;
    dob: DateAsString;
    emailAddress: string;
    employments: EmploymentEntity[];
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    givenName: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    legalFamilyName: string;
    legalGivenName: string;
    legalMiddleName: string;
    mailingAddress: Address;
    maritalStatus: string;
    mobilePhone: PhoneNumber;
    number: string;
    organDonorIndicator: string;
    passportNumber: string;
    permanentResidenceCardNumber: string;
    permanentResidentAlien: boolean;
    placeOfBirth: string;
    residentialAddress: Address;
    residentialAddresses: AddressEntity[];
    restrictions: string;
    sex: string;
    ssn: string;
    state: UnitedStates;
    usCitizen: boolean;
    vehicleClass: string;
    weight: string;
}

export interface PersonalShoeboxesDto {
    shoeboxMap: { [index: string]: ShoeboxItemResponseDto[] };
}

export interface PhoneNumber {
    locale: string;
    value: string;
}

export interface RandomAssetRequestDto extends OwnerInformationRequestDto {
    appraisedValue: number;
    bookValue: number;
    bookValueDiscount: number;
    bookValueSource: string;
    cashDeposit: number;
    estimatedValue: number;
    purchasePrice: number;
    simpleAssetDescriptor: string;
    ucc1: string;
    value: number;
}

export interface RandomAssetResponseDto extends RandomAssetRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface RealEstateRequestDto extends OwnerInformationRequestDto {
    afterLeaseValue: number;
    afterRepairValue: number;
    appraisedValue: number;
    asCompletedValue: number;
    buildingSquareFootage: number;
    constructionCost: number;
    costPerSquareFoot: number;
    earnestMoneyDeposit: number;
    landSquareFootage: number;
    leasedSquareFootage: number;
    lotPurchasePrice: number;
    mlsId: string;
    ownerOccupiedSquareFootage: number;
    purchasePrice: number;
    repairCost: number;
    repairCostPerSquareFoot: number;
    streetAddress: Address;
    taxOrParcelId: string;
    totalCompletionCost: number;
}

export interface RealEstateResponseDto extends RealEstateRequestDto, InformationResponseDto, AssetResponseInformation {
}

export interface RenameEntityDto {
    title: string;
}

export interface ResponseEntity<T> extends HttpEntity<T> {
    statusCode: HttpStatus;
    statusCodeValue: number;
}

export interface ResultIdDTO {
    id: number;
}

export interface RollupDto {
    additionalCashNeeded?: number;
    cumulativeDeposits: number;
    cumulativeMla: number;
    ltv?: number;
}

export interface SearchResultDTO {
    header: string;
    hitText: string;
    link: URL;
    type: SearchTypeEnum;
}

export interface SearchResultsDTO {
    items: SearchResultDTO[];
    similar: string[];
    total: number;
}

export interface SecurityResponseDto {
    challenge: string;
    channel: string;
    destination: string;
    linkActionId: string;
    message: string;
    session: string;
    success: boolean;
    username: string;
}

export interface Serializable {
}

export interface SetLabelOnUserDto {
    labels: string[];
    loanRoleToSetFor: string;
}

export interface SherpaEntity extends BaseObject {
    entityType: SherpaEntityType;
    madeBy?: AppUser;
    title: string;
    type: SherpaEntityType;
}

export interface SherpaEntityDto {
    id: string;
    title: string;
    type: SherpaEntityType;
}

export interface ShoeboxItemRequestDto {
    documentId: string;
    id: string;
    loanId: string;
    shoeboxOwnerId: string;
    shoeboxType: ShoeboxType;
    title: string;
    uploadedById: string;
    userId: string;
}

export interface ShoeboxItemResponseDto {
    document: DocumentAnswerV2Dto;
    entity: SherpaEntityDto;
    id: string;
    knowledgeBase: KnowledgeBase;
    loanRole: LoanRoleDto;
    shoeboxOwner: AppUserDTO2;
    shoeboxType: ShoeboxType;
    title: string;
    uploadedBy: AppUserDTO2;
}

export interface SimpleLabelDto {
    canBeOnBorrower: boolean;
    canBeOnLender: boolean;
    id: string;
    title: string;
}

export interface SimpleLoanDto {
    id: string;
    projectName: string;
    templateId: string;
    templateName: string;
}

export interface Slice<T> extends Streamable<T> {
    content: T[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: Pageable;
    size: number;
    sort: Sort;
}

export interface Sort extends Streamable<Order>, Serializable {
    sorted: boolean;
    unsorted: boolean;
}

export interface StorageSummaryDto {
    completedOneDriveDocuments: number;
    completedS3Documents: number;
    remainingOneDriveDocuments: number;
    remainingS3Documents: number;
    totalDocuments: number;
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface Supplier<T> {
}

export interface SupportRequestDto {
    authorId: string;
    categoryId: string;
    duration: string;
    id: string;
    loomLink: string;
    roles: Role[];
    tag: string;
    tangoHtml: string;
    title: string;
    videoId: string;
}

export interface SupportResponseDto {
    author: AppUserDTO2;
    category: CategoryDto;
    duration: string;
    id: string;
    loomLink: string;
    modifiedAt: DateAsString;
    roles: Role[];
    tag: string;
    tangoHtml: string;
    title: string;
    video: Document;
    views: number;
}

export interface TemplateElementRequestDto {
    answerId: string;
    auditable: boolean;
    description: string;
    destinations: DestinationDto[];
    displayOrder: number;
    excludeTasks: boolean;
    hasAnswer: boolean;
    hasExpiration: boolean;
    includeDescription: boolean;
    knowledgeBase: KnowledgeBase;
    locations: LocationDto[];
    modifiers: ElementModifierType[];
    parentId: string;
    priorityType: PriorityType;
    storageType: StorageType;
    templateElementId: string;
    templateId: string;
    title: string;
    venturesDocType: VenturesDocType;
    visibleAtStatus: LoanReviewStatus;
}

export interface TemplateElementResponseDto {
    answer: AnswerV2Dto;
    auditable: boolean;
    childrenIds: string[];
    description: string;
    destinations: DestinationDto[];
    displayOrder: number;
    evaluatedTitle: string;
    excludeTasks: boolean;
    hasAnswer: boolean;
    hasExpiration: boolean;
    includeDescription: boolean;
    knowledgeBase: KnowledgeBase;
    locations: LocationDto[];
    modifiers: ElementModifierType[];
    parentId: string;
    priorityType: PriorityType;
    storageType: StorageType;
    templateElementId: string;
    templateId: string;
    title: string;
    venturesDocType: VenturesDocType;
    visibleAtStatus: LoanReviewStatus;
}

export interface TemplateElementsRequestDto {
    elements: TemplateElementRequestDto[];
}

export interface TemplateElementsResponseDto {
    elements: { [index: string]: TemplateElementResponseDto };
}

export interface TemplateRequestDto {
    blockType: BlockType;
    entityModifiers: EntityModifierType[];
    id: string;
    lenderId: string;
    lenderPolicyLtv?: number;
    name: string;
    sherpaEntityType: SherpaEntityType;
    uniqueId: string;
    viewType: LoanViewType;
}

export interface TemplateResponseDto {
    blockType: BlockType;
    company: CompanyDto;
    entityModifiers: EntityModifierType[];
    id: string;
    lenderPolicyLtv: number;
    loanViewType: LoanViewType;
    name: string;
    sherpaEntityType: SherpaEntityType;
}

export interface TemplateTypeMapsDto {
    blockTypeMap: { [index: string]: any };
    elementModifierTypeMap: { [index: string]: any };
    entityModifierTypeMap: { [index: string]: any };
    loanViewTypeMap: { [index: string]: any };
    locationTypeMap: { [index: string]: any };
    namingStrategyTypeMap: { [index: string]: any };
    priorityTypeMap: { [index: string]: any };
    sherpaEntityTypeMap: { [index: string]: any };
    storageTypeMap: { [index: string]: any };
}

export interface TemplateUsageResponseDto {
    loans: SimpleLoanDto[];
}

export interface TemplatesRequestDto {
    templates: TemplateRequestDto[];
}

export interface TemplatesResponseDto {
    templates: TemplateResponseDto[];
}

export interface TimezoneDTO {
    description: string;
    hours: number;
    id: string;
    minutes: number;
}

export interface TokenEntity {
    accessToken: string;
    issueAt: DateAsString;
    refreshToken: string;
    validUntil: DateAsString;
}

export interface URL extends Serializable {
}

export interface USDriversLicense extends BaseObject {
    address: Address;
    backUrl: string;
    birthDate: DateAsString;
    complianceType: string;
    countryIdentification: string;
    dd: string;
    documentDiscriminator: string;
    endorsementCodes: string;
    expiration: DateAsString;
    eyesColor: string;
    familyName: string;
    familyNameTruncation: string;
    frontUrl: string;
    givenName: string;
    givenNameTruncation: string;
    hairColor: string;
    height: string;
    inventoryControlNumber: string;
    issueDate: DateAsString;
    middleName: string;
    middleNameTruncation: string;
    number: string;
    organDonorIndicator: string;
    restrictions: string;
    revisionDate: DateAsString;
    sex: string;
    state: UnitedStates;
    vehicleClass: string;
    verified: DateAsString;
    weight: string;
}

export interface UniqueIdDto {
    code: string;
    id: string;
    name: string;
    url: string;
}

export interface UpdateCookieTimeoutDto {
    remember: boolean;
    timestamp: string;
}

export interface UpdateLoanDTO {
    actualLoanAmortization?: number;
    actualLoanAmount?: number;
    actualLoanTerm?: number;
    amortizationInMonths?: number;
    apr: number;
    closeDate: DateAsString;
    downPayment: number;
    downPaymentPercentage?: number;
    downPaymentSource: string;
    /**
     * @deprecated
     */
    entityName: string;
    /**
     * @deprecated
     */
    entityType: EntityType;
    executiveSummary: string;
    index?: number;
    loanAmount: number;
    loanOriginationFees?: number;
    loanOriginationPoints?: number;
    loanOtherFees?: number;
    loanPhaseId: string;
    loanPurpose: string;
    loanTermInMonths: number;
    margin?: number;
    projectName: string;
    reviewStatus: LoanReviewStatus;
    unsecured: boolean;
}

export interface UpdateTemplateRequestDto {
    loans: SimpleLoanDto[];
}

export interface UpdateTemplateResponseDto {
    loans: SimpleLoanDto[];
}

export interface Upload {
    documentType: DocumentType;
    emailAddress: string;
    name: string;
    providerType: ProviderType;
    resourceKey: string;
    /**
     * @deprecated
     */
    s3Key: string;
    url: string;
}

export interface UploadLogo {
    filename: string;
    imageData: any;
    subdomain: string;
}

export interface UsDriverLicenceInfoDto {
    expiration: DateAsString;
    number: string;
    stateProvinceRegion: UnitedStates;
}

export interface UserProgress {
    acceptedPct: number;
    openPct: number;
    submittedPct: number;
}

export interface UserTaskDetailsDto {
    count: { [index: string]: number };
    forMe: ITask[];
}

export interface UserTypingEventDTO {
    user: AppUserDTO2;
}

export interface UsernamePasswordAuthenticationDto {
    password: string;
    remember: boolean;
    timestamp: string;
    username: string;
}

export interface VentureAddressDTO {
    city: string;
    countryCode: string;
    id: number;
    postalCode: string;
    stateCode: string;
    street1: string;
    street2: string;
}

export interface VentureCompanyDTO {
    address: VentureAddressDTO;
    addressId: number;
    businessPhone: string;
    dunsNumber: string;
    email: string;
    entityType: string;
    establishedDate: string;
    id: number;
    naicsCode: string;
    name: string;
    taxId: string;
    taxIdType: string;
}

export interface VentureCompanyListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureCompanyDTO[];
}

export interface VentureCompanyResultDTO extends VentureResponseBaseDTO {
    result: VentureCompanyDTO;
}

export interface VentureCompanyUpdateDTO {
    address: VentureAddressDTO;
    addressId: number;
    businessPhone: string;
    dunsNumber: string;
    email: string;
    entityType: string;
    establishedDate: string;
    naicsCode: string;
    name: string;
    taxId: string;
    taxIdType: string;
}

export interface VentureContactDTO {
    businessAddress: VentureAddressDTO;
    businessAddressId: number;
    businessPhone: string;
    dateOfBirth: string;
    email: string;
    firstName: string;
    homeAddress: VentureAddressDTO;
    homeAddressId: number;
    homePhone: string;
    id: number;
    lastName: string;
    mobilePhone: string;
}

export interface VentureContactListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureContactDTO[];
}

export interface VentureContactResultDTO extends VentureResponseBaseDTO {
    result: VentureContactDTO;
}

export interface VentureDataChangeItemField extends BaseObject {
    export: boolean;
    exportValue: string;
    fieldName: string;
    ventureValue: string;
}

export interface VentureDataChangeItemFieldDTO {
    export: boolean;
    exportValue: string;
    fieldName: string;
    id: string;
    ventureValue: string;
}

export interface VentureDocumentCreateDTO {
    Base64EncodedFile: string;
    Comment: string;
    Description: string;
    DocumentTypeID: number;
    FileName: string;
    LoanID: number;
}

export interface VentureDocumentFileTypeDTO {
    id: number;
    name: string;
}

export interface VentureDocumentFileTypeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureDocumentFileTypeDTO[];
}

export interface VentureEntityDTO {
    id: number;
    name: string;
}

export interface VentureExportDataChange extends BaseObject {
    items: VentureExportDataChangeItem[];
}

export interface VentureExportDataChangeDTO {
    id: string;
    items: VentureExportDataChangeItemDTO[];
}

export interface VentureExportDataChangeItem extends BaseObject {
    fields: VentureDataChangeItemField[];
    title: string;
    type: VentureExportDataType;
    ventureItemId: number;
}

export interface VentureExportDataChangeItemDTO {
    changeLevel: VentureChangeLevel;
    fields: VentureDataChangeItemFieldDTO[];
    id: string;
    title: string;
    type: VentureExportDataType;
    ventureItemId: number;
}

export interface VentureListResponseBaseDTO {
    page: number;
    recordsPerPage: number;
    totalCount: number;
}

export interface VentureLoanCreateResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanDTO {
    approvalDate: string;
    closingDate: string;
    entities: VentureLoanEntityDTO[];
    id: number;
    impactLmi: boolean;
    impactMinorityOwnedBusiness: boolean;
    impactVeteranOwnedBusiness: boolean;
    impactWomanOwnedBusiness: boolean;
    interestRatePercent: number;
    loanAmount: number;
    loanPurpose: string;
    loanStatus: string;
    loanTermMonths: number;
    loanType: string;
    loanTypeId: number;
    logNumber: string;
    organizationId: number;
    projectAddress: VentureAddressDTO;
    projectAddressId: number;
    referenceNumber: string;
}

export interface VentureLoanEntityCreateResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanEntityDTO {
    annualRevenue: number;
    association: string;
    borrower: boolean;
    company: VentureCompanyDTO;
    companyId: number;
    dbaName: string;
    guaranteeType: string;
    id: number;
    loanId: number;
    name: string;
    primary: boolean;
}

export interface VentureLoanEntityMemberDTO {
    guaranteeType: string;
    id: number;
    loanEntityId: number;
    memberContactId: number;
    memberLoanEntityId: number;
    name: string;
    ownershipPercentage: number;
    taxId: string;
    taxIdType: string;
}

export interface VentureLoanEntityMemberListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureLoanEntityMemberDTO[];
}

export interface VentureLoanEntityMemberResultDTO extends VentureResponseBaseDTO {
    result: VentureLoanEntityMemberDTO;
}

export interface VentureLoanEntityResultDTO extends VentureResponseBaseDTO {
    result: VentureLoanEntityDTO;
}

export interface VentureLoanEntityResultListDTO extends VentureListResponseBaseDTO {
    result: VentureLoanEntityDTO[];
}

export interface VentureLoanEntityUpdateDTO {
    annualRevenue: number;
    association: string;
    borrower: boolean;
    company: VentureCompanyDTO;
    dbaName: string;
    guaranteeType: string;
    name: string;
    primary: boolean;
}

export interface VentureLoanNoteDTO {
    addedOn: DateAsString;
    id: number;
    loanId: number;
    noteText: string;
    noteTypeId: number;
}

export interface VentureLoanNoteResultDTO extends VentureResponseBaseDTO {
    result: ResultIdDTO;
}

export interface VentureLoanNoteUpdateDTO {
    addedOn: DateAsString;
    noteText: string;
    noteTypeId: number;
}

export interface VentureLoanResultDTO extends VentureResponseBaseDTO {
    result: VentureLoanDTO;
}

export interface VentureLoanTypeDTO {
    category: string;
    id: number;
    name: string;
}

export interface VentureLoanTypeListDTO {
    loanType: VentureEntityDTO[];
}

export interface VentureLoanTypeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureLoanTypeDTO[];
}

export interface VentureNaicsCodeDTO {
    code: string;
    description: string;
    id: number;
}

export interface VentureNaicsCodeListResponseDTO extends VentureListResponseBaseDTO {
    result: VentureNaicsCodeDTO[];
}

export interface VentureNaicsResultDTO extends VentureResponseBaseDTO {
    result: VentureNaicsCodeDTO;
}

export interface VentureRequiredDataDTO {
    changedPOC: boolean;
    collaterals: LoanEntityDto[];
    entities: LoanEntityDto[];
    firstCollateralExport: boolean;
    firstPOCExport: boolean;
    missingPOC: boolean;
    missingPOCAddress: boolean;
    missingPrimaryCollateral: boolean;
    primaryCollateralMissingAddress: LoanEntityDto;
    primaryCollateralName: string;
    primaryPOCAddressCollateral: LoanEntityDto;
}

export interface VentureResponseBaseDTO {
    messages: string[];
    succeeded: boolean;
}

export interface VentureTestCredentialDTO {
    password: string;
    subdomain: string;
    username: string;
}

export interface VentureTestResultDTO {
    message: string;
    success: boolean;
}

export interface VentureTokenResultDTO {
    token: string;
}

export interface VentureloanUpdateDTO {
    interestRatePercent: number;
    loanType: string;
    logNumber: string;
    organizationId: number;
    projectAddress: VentureAddressDTO;
    referenceNumber: string;
}

export interface VerifyConfirmationCodeDto {
    confirmationCode2: string;
    confirmed: boolean;
}

export interface VersionDto {
    gitHash: string;
    version: string;
}

export interface ZipFileJobRequestDto {
    id: string;
    infoIdList: string[];
    itemsIgnored: number;
    itemsZipped: number;
    loanId: string;
    userId: string;
    view: LoanViewType;
    virtualFolderNames: string[];
}

export interface ZipFileJobResponseDto {
    id: string;
    itemsIgnored: number;
    itemsZipped: number;
    loanId: string;
    user: AppUserDTO2;
    zip: GeneratedPackageDto;
}

export interface ZipFileJobsResponseDto {
    jobs: ZipFileJobResponseDto[];
}

export interface htmlDto {
    header: string;
    html: string;
}

export type DateAsString = string;

export type RestResponse<R> = Promise<R>;

export enum ActionObjectType {
    ENTITY = "ENTITY",
    INFO = "INFO",
    LOAN = "LOAN",
    MESSAGE = "MESSAGE",
    NOTE = "NOTE",
    TASK = "TASK",
}

export enum AuthProviderType {
    local = "local",
    facebook = "facebook",
    google = "google",
    github = "github",
    yahoo = "yahoo",
    azuread = "azuread",
    apple = "apple",
}

export enum BlockType {
    NO_BLOCK = "NO_BLOCK",
    LOAN = "LOAN",
    ENTITY = "ENTITY",
    COMPONENT = "COMPONENT",
}

export enum BorrowerType {
    INDIVIDUAL = "INDIVIDUAL",
    MEMBER = "MEMBER",
}

export enum ClearReportStatus {
    WAITING = "WAITING",
    SCHEDULED = "SCHEDULED",
    PROCESSING = "PROCESSING",
    PROCESSED = "PROCESSED",
    ERROR = "ERROR",
    CANCELED = "CANCELED",
}

export enum ClearReportType {
    PERSON_SEARCH = "PERSON_SEARCH",
    PERSON_REPORT = "PERSON_REPORT",
    PERSON_QUICK_ANALYSIS_FLAG_REPORT = "PERSON_QUICK_ANALYSIS_FLAG_REPORT",
    BUSINESS_QUICK_ANALYSIS_FLAG_REPORT = "BUSINESS_QUICK_ANALYSIS_FLAG_REPORT",
    BUSINESS_SEARCH = "BUSINESS_SEARCH",
}

export enum ConsentStatus {
    NOT_ACCEPTED = "NOT_ACCEPTED",
    ACCEPTED = "ACCEPTED",
}

export enum DeepLinkActionType {
    PORTAL_HOME_PAGE = "PORTAL_HOME_PAGE",
    ELEMENT = "ELEMENT",
    ELEMENT_TASK = "ELEMENT_TASK",
    TASK = "TASK",
    MESSAGE = "MESSAGE",
}

export enum Direction {
    ASC = "ASC",
    DESC = "DESC",
}

export enum DocumentType {
    UNKNOWN = "UNKNOWN",
    PHOTOS_AND_IMAGES = "PHOTOS_AND_IMAGES",
    AUDIO = "AUDIO",
    PDF = "PDF",
    EXCEL = "EXCEL",
    VIDEO = "VIDEO",
    ZIP = "ZIP",
    WORD_DOCX = "WORD_DOCX",
    FORMS = "FORMS",
    SLIDES = "SLIDES",
    DOCUSIGN_TEMPLATE = "DOCUSIGN_TEMPLATE",
}

export enum ElementActionType {
    SAVE = "SAVE",
    SUBMIT = "SUBMIT",
    REVIEW = "REVIEW",
    REJECT = "REJECT",
    ACCEPT = "ACCEPT",
    NEEDS_LEGAL_REVIEW = "NEEDS_LEGAL_REVIEW",
    MOVE = "MOVE",
    DELETE = "DELETE",
}

export enum ElementModifierType {
    ALL = "ALL",
    NONE = "NONE",
    LENDER_ONLY = "LENDER_ONLY",
    SIGNATURE_REQUIRED = "SIGNATURE_REQUIRED",
    EDITABLE_XLS = "EDITABLE_XLS",
    BILLING_DOCUMENT = "BILLING_DOCUMENT",
    INCLUDE_ANSWERED_UNACCEPTED_IN_TASK = "INCLUDE_ANSWERED_UNACCEPTED_IN_TASK",
    FILLABLE_FORM = "FILLABLE_FORM",
    HIDE_ON_CREATE = "HIDE_ON_CREATE",
    AUDITABLE = "AUDITABLE",
    NEEDS_LEGAL_REVIEW = "NEEDS_LEGAL_REVIEW",
    SYSTEM_FORM = "SYSTEM_FORM",
    NEEDS_SIGNATURE = "NEEDS_SIGNATURE",
    NEEDS_NOTARY = "NEEDS_NOTARY",
    NEEDS_WET_SIG = "NEEDS_WET_SIG",
    NEEDS_SHIP_LABEL = "NEEDS_SHIP_LABEL",
    INTERNAL_ONLY = "INTERNAL_ONLY",
    EXTERNAL_DATA = "EXTERNAL_DATA",
    SELF_SIGN = "SELF_SIGN",
}

export enum ElementStatusType {
    OPEN = "OPEN",
    IN_PROGRESS = "IN_PROGRESS",
    SUBMITTED = "SUBMITTED",
    REVIEWING = "REVIEWING",
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
    NEEDS_LEGAL_REVIEW = "NEEDS_LEGAL_REVIEW",
    NEEDS_ATTENTION = "NEEDS_ATTENTION",
}

export enum EmploymentType {
    SELF_EMPLOYED = "SELF_EMPLOYED",
    KEY_EMPLOYEE = "KEY_EMPLOYEE",
}

export enum EntityModifierType {
    ALL = "ALL",
    NONE = "NONE",
    APPLICANT = "APPLICANT",
    NON_APPLICANT = "NON_APPLICANT",
    GUARANTOR = "GUARANTOR",
    SPOUSE = "SPOUSE",
    NEW = "NEW",
    FRANCHISEE = "FRANCHISEE",
    AFFILIATE = "AFFILIATE",
    RELATED_NON_AFFILIATE = "RELATED_NON_AFFILIATE",
    RELATED_ENTITY = "RELATED_ENTITY",
}

export enum EntityType {
    PERSON = "PERSON",
    DBA = "DBA",
    PARTNERSHIP = "PARTNERSHIP",
    CCORP = "CCORP",
    SCORP = "SCORP",
    LLC = "LLC",
    LLP = "LLP",
    TRUST = "TRUST",
    COMPANY = "COMPANY",
    ASSET = "ASSET",
    REAL_ESTATE_ASSET = "REAL_ESTATE_ASSET",
}

export enum Features {
    CALENDAR = "CALENDAR",
    ANALYTICS = "ANALYTICS",
    COMMUNICATIONS = "COMMUNICATIONS",
    SEARCH = "SEARCH",
    TEMPLATES = "TEMPLATES",
}

export enum FormElementState {
    UNANSWERED = "UNANSWERED",
    ANSWERED = "ANSWERED",
    ANSWERED_UNEXPIRED = "ANSWERED_UNEXPIRED",
    ANSWERED_EXPIRING = "ANSWERED_EXPIRING",
    ANSWERED_EXPIRING_RE_ASKED = "ANSWERED_EXPIRING_RE_ASKED",
    ANSWERED_EXPIRED = "ANSWERED_EXPIRED",
    ANSWERED_EXPIRED_RE_ASKED = "ANSWERED_EXPIRED_RE_ASKED",
}

export enum GenerationStatus {
    NEVER = "NEVER",
    REQUESTED = "REQUESTED",
    IN_PROGRESS = "IN_PROGRESS",
    FINISHED = "FINISHED",
    FAILED = "FAILED",
}

/**
 * Values:
 * - `CONTINUE`
 * - `SWITCHING_PROTOCOLS`
 * - `PROCESSING`
 * - `CHECKPOINT`
 * - `OK`
 * - `CREATED`
 * - `ACCEPTED`
 * - `NON_AUTHORITATIVE_INFORMATION`
 * - `NO_CONTENT`
 * - `RESET_CONTENT`
 * - `PARTIAL_CONTENT`
 * - `MULTI_STATUS`
 * - `ALREADY_REPORTED`
 * - `IM_USED`
 * - `MULTIPLE_CHOICES`
 * - `MOVED_PERMANENTLY`
 * - `FOUND`
 * - `MOVED_TEMPORARILY` - @deprecated
 * - `SEE_OTHER`
 * - `NOT_MODIFIED`
 * - `USE_PROXY` - @deprecated
 * - `TEMPORARY_REDIRECT`
 * - `PERMANENT_REDIRECT`
 * - `BAD_REQUEST`
 * - `UNAUTHORIZED`
 * - `PAYMENT_REQUIRED`
 * - `FORBIDDEN`
 * - `NOT_FOUND`
 * - `METHOD_NOT_ALLOWED`
 * - `NOT_ACCEPTABLE`
 * - `PROXY_AUTHENTICATION_REQUIRED`
 * - `REQUEST_TIMEOUT`
 * - `CONFLICT`
 * - `GONE`
 * - `LENGTH_REQUIRED`
 * - `PRECONDITION_FAILED`
 * - `PAYLOAD_TOO_LARGE`
 * - `REQUEST_ENTITY_TOO_LARGE` - @deprecated
 * - `URI_TOO_LONG`
 * - `REQUEST_URI_TOO_LONG` - @deprecated
 * - `UNSUPPORTED_MEDIA_TYPE`
 * - `REQUESTED_RANGE_NOT_SATISFIABLE`
 * - `EXPECTATION_FAILED`
 * - `I_AM_A_TEAPOT`
 * - `INSUFFICIENT_SPACE_ON_RESOURCE` - @deprecated
 * - `METHOD_FAILURE` - @deprecated
 * - `DESTINATION_LOCKED` - @deprecated
 * - `UNPROCESSABLE_ENTITY`
 * - `LOCKED`
 * - `FAILED_DEPENDENCY`
 * - `TOO_EARLY`
 * - `UPGRADE_REQUIRED`
 * - `PRECONDITION_REQUIRED`
 * - `TOO_MANY_REQUESTS`
 * - `REQUEST_HEADER_FIELDS_TOO_LARGE`
 * - `UNAVAILABLE_FOR_LEGAL_REASONS`
 * - `INTERNAL_SERVER_ERROR`
 * - `NOT_IMPLEMENTED`
 * - `BAD_GATEWAY`
 * - `SERVICE_UNAVAILABLE`
 * - `GATEWAY_TIMEOUT`
 * - `HTTP_VERSION_NOT_SUPPORTED`
 * - `VARIANT_ALSO_NEGOTIATES`
 * - `INSUFFICIENT_STORAGE`
 * - `LOOP_DETECTED`
 * - `BANDWIDTH_LIMIT_EXCEEDED`
 * - `NOT_EXTENDED`
 * - `NETWORK_AUTHENTICATION_REQUIRED`
 */
export enum HttpStatus {
    CONTINUE = "CONTINUE",
    SWITCHING_PROTOCOLS = "SWITCHING_PROTOCOLS",
    PROCESSING = "PROCESSING",
    CHECKPOINT = "CHECKPOINT",
    OK = "OK",
    CREATED = "CREATED",
    ACCEPTED = "ACCEPTED",
    NON_AUTHORITATIVE_INFORMATION = "NON_AUTHORITATIVE_INFORMATION",
    NO_CONTENT = "NO_CONTENT",
    RESET_CONTENT = "RESET_CONTENT",
    PARTIAL_CONTENT = "PARTIAL_CONTENT",
    MULTI_STATUS = "MULTI_STATUS",
    ALREADY_REPORTED = "ALREADY_REPORTED",
    IM_USED = "IM_USED",
    MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
    MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
    FOUND = "FOUND",
    /**
     * @deprecated
     */
    MOVED_TEMPORARILY = "MOVED_TEMPORARILY",
    SEE_OTHER = "SEE_OTHER",
    NOT_MODIFIED = "NOT_MODIFIED",
    /**
     * @deprecated
     */
    USE_PROXY = "USE_PROXY",
    TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
    PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
    BAD_REQUEST = "BAD_REQUEST",
    UNAUTHORIZED = "UNAUTHORIZED",
    PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
    FORBIDDEN = "FORBIDDEN",
    NOT_FOUND = "NOT_FOUND",
    METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
    NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
    PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
    REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
    CONFLICT = "CONFLICT",
    GONE = "GONE",
    LENGTH_REQUIRED = "LENGTH_REQUIRED",
    PRECONDITION_FAILED = "PRECONDITION_FAILED",
    PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
    /**
     * @deprecated
     */
    REQUEST_ENTITY_TOO_LARGE = "REQUEST_ENTITY_TOO_LARGE",
    URI_TOO_LONG = "URI_TOO_LONG",
    /**
     * @deprecated
     */
    REQUEST_URI_TOO_LONG = "REQUEST_URI_TOO_LONG",
    UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
    REQUESTED_RANGE_NOT_SATISFIABLE = "REQUESTED_RANGE_NOT_SATISFIABLE",
    EXPECTATION_FAILED = "EXPECTATION_FAILED",
    I_AM_A_TEAPOT = "I_AM_A_TEAPOT",
    /**
     * @deprecated
     */
    INSUFFICIENT_SPACE_ON_RESOURCE = "INSUFFICIENT_SPACE_ON_RESOURCE",
    /**
     * @deprecated
     */
    METHOD_FAILURE = "METHOD_FAILURE",
    /**
     * @deprecated
     */
    DESTINATION_LOCKED = "DESTINATION_LOCKED",
    UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
    LOCKED = "LOCKED",
    FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
    TOO_EARLY = "TOO_EARLY",
    UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
    PRECONDITION_REQUIRED = "PRECONDITION_REQUIRED",
    TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
    REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
    UNAVAILABLE_FOR_LEGAL_REASONS = "UNAVAILABLE_FOR_LEGAL_REASONS",
    INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
    NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
    BAD_GATEWAY = "BAD_GATEWAY",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
    HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
    VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
    INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
    LOOP_DETECTED = "LOOP_DETECTED",
    BANDWIDTH_LIMIT_EXCEEDED = "BANDWIDTH_LIMIT_EXCEEDED",
    NOT_EXTENDED = "NOT_EXTENDED",
    NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}

export enum KnowledgeBase {
    NO_KNOWLEDGE_BASE = "NO_KNOWLEDGE_BASE",
    ENTITY_FOLDER = "ENTITY_FOLDER",
    APPLICANT = "APPLICANT",
    ARTICLES_OF_ORGANIZATION = "ARTICLES_OF_ORGANIZATION",
    BORROWING_RESOLUTION = "BORROWING_RESOLUTION",
    CERTIFICATE_OF_FORMATION = "CERTIFICATE_OF_FORMATION",
    COMPANY = "COMPANY",
    COVER_PAGE = "COVER_PAGE",
    CREDIT_CONSENT = "CREDIT_CONSENT",
    CREDIT_REPORT = "CREDIT_REPORT",
    CREDIT_REPORT_RAW_DATA = "CREDIT_REPORT_RAW_DATA",
    CREDIT_REPORTS = "CREDIT_REPORTS",
    OFAC_REPORT = "OFAC_REPORT",
    DRIVERS_LICENSE_FRONT = "DRIVERS_LICENSE_FRONT",
    DRIVERS_LICENSE_BACK = "DRIVERS_LICENSE_BACK",
    LOAN_DOCUMENTS = "LOAN_DOCUMENTS",
    LOAN_DOCUMENT_DRAFTED = "LOAN_DOCUMENT_DRAFTED",
    LOAN_DOCUMENT_SIGNED = "LOAN_DOCUMENT_SIGNED",
    LOAN_DOCUMENT_RECORDED = "LOAN_DOCUMENT_RECORDED",
    OPERATING_AGREEMENT = "OPERATING_AGREEMENT",
    PHOTOS = "PHOTOS",
    PURCHASE_CONTRACTS = "PURCHASE_CONTRACTS",
    PURCHASE_CONTRACT = "PURCHASE_CONTRACT",
    PURCHASE_CONTRACT_ADENDUMS = "PURCHASE_CONTRACT_ADENDUMS",
    PRINCIPALS = "PRINCIPALS",
    RELATED_ENTITY = "RELATED_ENTITY",
    SHOE_BOX = "SHOE_BOX",
    EMAIL_LEAD = "EMAIL_LEAD",
    CREDIT_MEMO = "CREDIT_MEMO",
    LOAN_ASSISTANCE_PROGRAM = "LOAN_ASSISTANCE_PROGRAM",
    LOAN_PREPAYMENT_PROGRAM = "LOAN_PREPAYMENT_PROGRAM",
    TPL_COMMITMENT_LETTER = "TPL_COMMITMENT_LETTER",
    PSA = "PSA",
    RSRA = "RSRA",
    RBEG = "RBEG",
    ALIEN_LETTER_G845 = "ALIEN_LETTER_G845",
    ATR = "ATR",
    POA = "POA",
    BORROWER_SOW = "BORROWER_SOW",
    PRE_UNDERWRITING_QUESTION = "PRE_UNDERWRITING_QUESTION",
    PRE_UNDERWRITING_RESPONSE = "PRE_UNDERWRITING_RESPONSE",
    PRE_QUAL_SPREAD = "PRE_QUAL_SPREAD",
    PRE_TRANSFER_CHECKLIST = "PRE_TRANSFER_CHECKLIST",
    PRE_CLOSING_CHECKLIST = "PRE_CLOSING_CHECKLIST",
    APPROVAL_327 = "APPROVAL_327",
    APPROVAL_337_DECLINED = "APPROVAL_337_DECLINED",
    APPROVAL_BOARD = "APPROVAL_BOARD",
    APPROVAL_BOARD_SUMMARY = "APPROVAL_BOARD_SUMMARY",
    APPROVAL_LOAN_RATING = "APPROVAL_LOAN_RATING",
    APPROVAL_SBA_APPRAISAL = "APPROVAL_SBA_APPRAISAL",
    APPROVAL_SBA_TC = "APPROVAL_SBA_TC",
    APPROVAL_CAFS_CHANGE = "APPROVAL_CAFS_CHANGE",
    APPROVAL_MGMT_AGREEMENT = "APPROVAL_MGMT_AGREEMENT",
    PDF_PACKAGE = "PDF_PACKAGE",
    ZIP_PACKAGE = "ZIP_PACKAGE",
    TRANSACTION = "TRANSACTION",
    SHOE_BOXES = "SHOE_BOXES",
    COLLATERAL_ASSET = "COLLATERAL_ASSET",
    COLLATERAL_ASSET_REAL_ESTATE = "COLLATERAL_ASSET_REAL_ESTATE",
    SUBJECT_COLLATERAL = "SUBJECT_COLLATERAL",
    SUBJECT_COLLATERAL_REAL_ESTATE = "SUBJECT_COLLATERAL_REAL_ESTATE",
    TRUST = "TRUST",
    LOAN_APPLICATION = "LOAN_APPLICATION",
    CREDIT_APPLICATION = "CREDIT_APPLICATION",
    RISK_REPORTS = "RISK_REPORTS",
    RISK_REPORT = "RISK_REPORT",
    PERSON_QUICK_SEARCH_FLAG_REPORT = "PERSON_QUICK_SEARCH_FLAG_REPORT",
    BUSINESS_QUICK_SEARCH_FLAG_REPORT = "BUSINESS_QUICK_SEARCH_FLAG_REPORT",
    CAIVRS_REPORT = "CAIVRS_REPORT",
    GENERIC_SIG = "GENERIC_SIG",
    DEBT_SCHEDULE_SIG = "DEBT_SCHEDULE_SIG",
    IRS_7004_SIG = "IRS_7004_SIG",
    IRS_8821_SIG = "IRS_8821_SIG",
    IRS_4868_SIG = "IRS_4868_SIG",
    IRS_2553 = "IRS_2553",
    USCIS_G845_SIG = "USCIS_G845_SIG",
    SBA_1244 = "SBA_1244",
    SBA_1233_PART2_SIG = "SBA_1233_PART2_SIG",
    SBA_1233_PART3_SIG = "SBA_1233_PART3_SIG",
    SBA_2462_SIG = "SBA_2462_SIG",
    SBA_327_SIG = "SBA_327_SIG",
    SBA_159_SIG = "SBA_159_SIG",
    SBA_2287_SIG = "SBA_2287_SIG",
    SBA_2303_SIG = "SBA_2303_SIG",
    PDF_APPROVAL_SLPC = "PDF_APPROVAL_SLPC",
    LOAN_APPLICATION_SIG = "LOAN_APPLICATION_SIG",
    IRS_SS4_SIG = "IRS_SS4_SIG",
    IRS_4506C_SIG = "IRS_4506C_SIG",
    IRS_TAX_INFO_AUTH = "IRS_TAX_INFO_AUTH",
    GENERIC_LENDER_ONLY = "GENERIC_LENDER_ONLY",
    PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
    PERSONAL_HISTORY = "PERSONAL_HISTORY",
    PERSONAL_REFERENCES = "PERSONAL_REFERENCES",
    GOVERNMENT_ID = "GOVERNMENT_ID",
    GOVERNMENT_ID_FRONT = "GOVERNMENT_ID_FRONT",
    GOVERNMENT_ID_BACK = "GOVERNMENT_ID_BACK",
    GREEN_CARD_FRONT = "GREEN_CARD_FRONT",
    GREEN_CARD_BACK = "GREEN_CARD_BACK",
    AKA_STATMENT = "AKA_STATMENT",
    USA_RESIDENCE_CARD = "USA_RESIDENCE_CARD",
    PAY_STUBS = "PAY_STUBS",
    W2_MOST_RECENT = "W2_MOST_RECENT",
    PROFESSIONAL_LICENCES = "PROFESSIONAL_LICENCES",
    SPECIALIZED_LICENCES = "SPECIALIZED_LICENCES",
    PRE_CLOSE_CHECKLIST = "PRE_CLOSE_CHECKLIST",
    APPRAISAL_CHECKLIST = "APPRAISAL_CHECKLIST",
    PROOF_OF_INCOME = "PROOF_OF_INCOME",
    PROOF_OF_INJECTION = "PROOF_OF_INJECTION",
    AKA_STATEMENT = "AKA_STATEMENT",
    UNCONDITIONAL_GUARANTEE = "UNCONDITIONAL_GUARANTEE",
    BANKRUPTCY_COURT = "BANKRUPTCY_COURT",
    BANKRUPTCY_EXPLANATION = "BANKRUPTCY_EXPLANATION",
    PENDING_LAWSUIT_OPINION = "PENDING_LAWSUIT_OPINION",
    PENDING_LAWSUIT_COMPLAINT = "PENDING_LAWSUIT_COMPLAINT",
    INS_SUBMISSION = "INS_SUBMISSION",
    INSURANCE_CALC = "INSURANCE_CALC",
    TRANSCRIPTS = "TRANSCRIPTS",
    APPRAISAL = "APPRAISAL",
    APPRAISAL_RECERT = "APPRAISAL_RECERT",
    COMPARABLE_SALES = "COMPARABLE_SALES",
    DEED = "DEED",
    COPY_OF_TITLE = "COPY_OF_TITLE",
    INSURANCE_BUILDING = "INSURANCE_BUILDING",
    INSURANCE_PROOF = "INSURANCE_PROOF",
    INSURANCE_TITLE = "INSURANCE_TITLE",
    INSURANCE_GENERIC = "INSURANCE_GENERIC",
    INSURANCE_BUILDERS_RISK = "INSURANCE_BUILDERS_RISK",
    INSURANCE_FLOOD = "INSURANCE_FLOOD",
    INSURANCE_HAZARD = "INSURANCE_HAZARD",
    INSURANCE_HAZARD_MOL = "INSURANCE_HAZARD_MOL",
    INSURANCE_LIABILITY = "INSURANCE_LIABILITY",
    INSURANCE_LIFE = "INSURANCE_LIFE",
    INSURANCE_LIQUOR = "INSURANCE_LIQUOR",
    INSURANCE_REQUEST = "INSURANCE_REQUEST",
    GAP_POLICY = "GAP_POLICY",
    RENTAL_ANALYSIS = "RENTAL_ANALYSIS",
    MLS_PAGE = "MLS_PAGE",
    CONSTRUCTION_CONTRACT = "CONSTRUCTION_CONTRACT",
    CONSTRUCTION_BUDGET = "CONSTRUCTION_BUDGET",
    CONSTRUCTION_BIDS = "CONSTRUCTION_BIDS",
    CONSTRUCTION_DISBURSEMENT_LEDGER = "CONSTRUCTION_DISBURSEMENT_LEDGER",
    CONSTRUCTION_CHANGE_ORDERS = "CONSTRUCTION_CHANGE_ORDERS",
    CONSTRUCTION_COST_BREAKDOWN = "CONSTRUCTION_COST_BREAKDOWN",
    BUILDING_PERMITS = "BUILDING_PERMITS",
    USE_PERMIT = "USE_PERMIT",
    ARCHITECT_PLANS = "ARCHITECT_PLANS",
    RENT_COLLECTION_HISTORY = "RENT_COLLECTION_HISTORY",
    PAYMENT_HISTORY = "PAYMENT_HISTORY",
    PAYMENT_INTEREST = "PAYMENT_INTEREST",
    SURVEY = "SURVEY",
    SOIL_REPORT = "SOIL_REPORT",
    PHASE_1_ENVIRONMENTAL = "PHASE_1_ENVIRONMENTAL",
    PHASE_2_ENVIRONMENTAL = "PHASE_2_ENVIRONMENTAL",
    ENVIRONMENTAL_APPROVAL = "ENVIRONMENTAL_APPROVAL",
    SBA_ENVIRONMENTAL_APPROVAL = "SBA_ENVIRONMENTAL_APPROVAL",
    ENVIRONMENTAL_QUESTIONNAIRE = "ENVIRONMENTAL_QUESTIONNAIRE",
    ENVIRONMENTAL_CHECKLIST = "ENVIRONMENTAL_CHECKLIST",
    ENVIRONMENTAL_RELIANCE = "ENVIRONMENTAL_RELIANCE",
    ENVIRONMENTAL_INSURANCE = "ENVIRONMENTAL_INSURANCE",
    ENVIRONMENTAL_RSRA = "ENVIRONMENTAL_RSRA",
    ENVIRONMENTAL_TSA = "ENVIRONMENTAL_TSA",
    ENVIRONMENTAL_SEISMIC = "ENVIRONMENTAL_SEISMIC",
    PROMISSORY_NOTE = "PROMISSORY_NOTE",
    PROMISSORY_NOTE_SHORT = "PROMISSORY_NOTE_SHORT",
    SETTLEMENT_STATEMENT = "SETTLEMENT_STATEMENT",
    SETTLEMENT_STATEMENT_SBA = "SETTLEMENT_STATEMENT_SBA",
    SETTLEMENT_STATEMENT_TPL = "SETTLEMENT_STATEMENT_TPL",
    SETTLEMENT_STATEMENT_FINAL = "SETTLEMENT_STATEMENT_FINAL",
    SITE_VISIT_REPORT = "SITE_VISIT_REPORT",
    LEASE_AGREEMENTS = "LEASE_AGREEMENTS",
    LEASE_AGREEMENT_AMENDMENTS = "LEASE_AGREEMENT_AMENDMENTS",
    LEASE_UP_SCHEDULE = "LEASE_UP_SCHEDULE",
    LEASE_FOR_EPC_OC_TEMPLATE = "LEASE_FOR_EPC_OC_TEMPLATE",
    LEASE_FOR_EPC_OC_FINAL = "LEASE_FOR_EPC_OC_FINAL",
    RENT_ROLL = "RENT_ROLL",
    FLOOD_ZONE_DETERMINATION = "FLOOD_ZONE_DETERMINATION",
    PROPERTY_MGMT_DESCRIPTION = "PROPERTY_MGMT_DESCRIPTION",
    COUNTY_TAX_PAGE = "COUNTY_TAX_PAGE",
    GENERAL_CONTRACTOR_LICENSE = "GENERAL_CONTRACTOR_LICENSE",
    SCHEDULE_OF_SUBCONTRACTORS = "SCHEDULE_OF_SUBCONTRACTORS",
    STATEMENT_OF_VALUES = "STATEMENT_OF_VALUES",
    FINAL_DEVELOPMENT_PLAT = "FINAL_DEVELOPMENT_PLAT",
    QUOTE = "QUOTE",
    INSTALLATION_SCHEDULE = "INSTALLATION_SCHEDULE",
    UCC1_FILING = "UCC1_FILING",
    UCC1_CONSENT = "UCC1_CONSENT",
    UCC1_SEARCH_1 = "UCC1_SEARCH_1",
    UCC1_SEARCH_2 = "UCC1_SEARCH_2",
    UCC1_SEARCH_3 = "UCC1_SEARCH_3",
    PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
    PURCHASE_INVOICE = "PURCHASE_INVOICE",
    TITLE_COMMITMENT = "TITLE_COMMITMENT",
    TITLE_POLICY = "TITLE_POLICY",
    TITLE_LIEN = "TITLE_LIEN",
    DEVELOPMENT_PRO_FORMA = "DEVELOPMENT_PRO_FORMA",
    DEVELOPMENT_APPROVAL = "DEVELOPMENT_APPROVAL",
    DEVELOPMENT_TEAM_BIOS = "DEVELOPMENT_TEAM_BIOS",
    PROP_MGMT_DESCRIPTION = "PROP_MGMT_DESCRIPTION",
    RELEASE_OF_LIEN = "RELEASE_OF_LIEN",
    ASSIGNMENT_BY_SELLER = "ASSIGNMENT_BY_SELLER",
    BUYERS_ORDER = "BUYERS_ORDER",
    WAIVER_OF_PRODUCTS = "WAIVER_OF_PRODUCTS",
    OTHER_AGREEMENTS = "OTHER_AGREEMENTS",
    COLLATERAL_CHECKLIST = "COLLATERAL_CHECKLIST",
    PRELIM_LEGAL_TITLE = "PRELIM_LEGAL_TITLE",
    PRELIM_TITLE = "PRELIM_TITLE",
    PRELIM_TITLE_EXCEPTIONS = "PRELIM_TITLE_EXCEPTIONS",
    PRELIM_TITLE_SBA_EXCEPTIONS = "PRELIM_TITLE_SBA_EXCEPTIONS",
    CDC_MEMO_TO_FILE_NOC = "CDC_MEMO_TO_FILE_NOC",
    CDC_MEMO_TO_FILE_COO = "CDC_MEMO_TO_FILE_COO",
    EQUIPMENT_BID = "EQUIPMENT_BID",
    AUTO_BUYERS_GUIDE_SOLD = "AUTO_BUYERS_GUIDE_SOLD",
    AUTO_BUYERS_GUIDE_TRADE_IN = "AUTO_BUYERS_GUIDE_TRADE_IN",
    AUTO_MILEAGE_STATEMENT = "AUTO_MILEAGE_STATEMENT",
    AUTO_MILEAGE_STATEMENT_SOLD = "AUTO_MILEAGE_STATEMENT_SOLD",
    AUTO_TRADE_IN_ANALYSIS = "AUTO_TRADE_IN_ANALYSIS",
    AUTO_ATR_SOLD = "AUTO_ATR_SOLD",
    AUTO_ATR_TRADE_IN = "AUTO_ATR_TRADE_IN",
    AUTO_PRIVACY_NOTICE = "AUTO_PRIVACY_NOTICE",
    AUTO_PRIVACY_NOTICE_2 = "AUTO_PRIVACY_NOTICE_2",
    TRUST_DEED = "TRUST_DEED",
    CERT_OF_TRUST = "CERT_OF_TRUST",
    TRUST_AGREEMENT = "TRUST_AGREEMENT",
    GENERIC_CORP = "GENERIC_CORP",
    ARTICLES_OF_ORGANIZATION_CORP = "ARTICLES_OF_ORGANIZATION_CORP",
    ARTICLES_OF_INCORPORATION_CORP = "ARTICLES_OF_INCORPORATION_CORP",
    PARTNERSHIP_AGREEMENT_CORP = "PARTNERSHIP_AGREEMENT_CORP",
    SHAREHOLDER_AGREEMENT_CORP = "SHAREHOLDER_AGREEMENT_CORP",
    BYLAWS_CORP = "BYLAWS_CORP",
    CERT_OF_AMENDMENTS_CORP = "CERT_OF_AMENDMENTS_CORP",
    CERT_OF_GENERAL_PARTNERS_CORP = "CERT_OF_GENERAL_PARTNERS_CORP",
    CERT_OF_GOOD_STANDING_CORP = "CERT_OF_GOOD_STANDING_CORP",
    CERT_OF_GOOD_STANDING_DBA = "CERT_OF_GOOD_STANDING_DBA",
    CERT_OF_GOOD_FORMATION_CORP = "CERT_OF_GOOD_FORMATION_CORP",
    CERT_TRADE_NAME_CORP = "CERT_TRADE_NAME_CORP",
    CERT_OF_OCCUPANCY = "CERT_OF_OCCUPANCY",
    CERT_OF_COMPLETED_PROJECT = "CERT_OF_COMPLETED_PROJECT",
    CERT_OF_LIABILITY = "CERT_OF_LIABILITY",
    CERT_OF_ENTITY_OWNERSHIP = "CERT_OF_ENTITY_OWNERSHIP",
    DBA_GOOD_STANDING_CORP = "DBA_GOOD_STANDING_CORP",
    OPERATING_AGREEMENT_CORP = "OPERATING_AGREEMENT_CORP",
    SCHEDULE_OF_DEBT_CORP = "SCHEDULE_OF_DEBT_CORP",
    SCHEDULE_OF_REAL_ESTATE_CORP = "SCHEDULE_OF_REAL_ESTATE_CORP",
    SCHEDULE_OF_RELATED_ENTITIES_CORP = "SCHEDULE_OF_RELATED_ENTITIES_CORP",
    SCHEDULE_OF_OWNERSHIP_CORP = "SCHEDULE_OF_OWNERSHIP_CORP",
    SCHEDULE_OF_BENEFICIARIES = "SCHEDULE_OF_BENEFICIARIES",
    SCHEDULE_OF_PRIOR_FINANCING = "SCHEDULE_OF_PRIOR_FINANCING",
    SCHEDULE_OF_LOT_TAKEDOWN = "SCHEDULE_OF_LOT_TAKEDOWN",
    SCHEDULE_OF_STOCK_OWNERSHIP = "SCHEDULE_OF_STOCK_OWNERSHIP",
    CASH_FLOW_ANALYSIS_CORP = "CASH_FLOW_ANALYSIS_CORP",
    FINANCIAL_PROJECTIONS = "FINANCIAL_PROJECTIONS",
    FINANCIAL_PROJECTIONS_ASSUMPTIONS = "FINANCIAL_PROJECTIONS_ASSUMPTIONS",
    ACCOUNTS_RECEIVABLE_AGING = "ACCOUNTS_RECEIVABLE_AGING",
    ACCOUNTS_PAYABLE_AGING = "ACCOUNTS_PAYABLE_AGING",
    EIN_CORP = "EIN_CORP",
    FRANCHISE_AGREEMENT = "FRANCHISE_AGREEMENT",
    FRANCHISE_APPROVAL = "FRANCHISE_APPROVAL",
    SBA_FRANCHISE_DIRECTORY = "SBA_FRANCHISE_DIRECTORY",
    BUSINESS_LICENSE = "BUSINESS_LICENSE",
    OPERATING_LICENSE = "OPERATING_LICENSE",
    MARKETING_PLAN = "MARKETING_PLAN",
    BUSINESS_PLAN = "BUSINESS_PLAN",
    BUSINESS_PLAN_DOWNLOAD = "BUSINESS_PLAN_DOWNLOAD",
    ENTITY_SEARCH = "ENTITY_SEARCH",
    FILING_STATEMENT = "FILING_STATEMENT",
    BUSINESS_HISTORY = "BUSINESS_HISTORY",
    SBA_EIDL_NOTE = "SBA_EIDL_NOTE",
    FOREIGN_ARTICLES = "FOREIGN_ARTICLES",
    TRANSFER_OF_OWNERSHIP = "TRANSFER_OF_OWNERSHIP",
    PERMIT_NOT_REQUIRED = "PERMIT_NOT_REQUIRED",
    STATEMENT_OF_OCCUPANCY = "STATEMENT_OF_OCCUPANCY",
    ESCROW_AGREEMENT = "ESCROW_AGREEMENT",
    SUBORDINATION_AGREEMENT = "SUBORDINATION_AGREEMENT",
    SUBORDINATION_CORRESPONDENCE = "SUBORDINATION_CORRESPONDENCE",
    MASTER_INVESTOR_WORKBOOK = "MASTER_INVESTOR_WORKBOOK",
    MASTER_LOAN_SERVICING_WORKBOOK = "MASTER_LOAN_SERVICING_WORKBOOK",
    DEAL_SHEET = "DEAL_SHEET",
    LOAN_PAYOFF_LETTER = "LOAN_PAYOFF_LETTER",
    COLLATERAL_RELEASE = "COLLATERAL_RELEASE",
    LOAN_PROPOSAL = "LOAN_PROPOSAL",
    LETTER_OF_INTENT = "LETTER_OF_INTENT",
    LOAN_EXECUTIVE_SUMMARY = "LOAN_EXECUTIVE_SUMMARY",
    CONTRACT_AMENDMENTS = "CONTRACT_AMENDMENTS",
    WELCOME_LETTER = "WELCOME_LETTER",
    REGISTERED_PRINCIPALS = "REGISTERED_PRINCIPALS",
    VIRTUAL_FOLDER = "VIRTUAL_FOLDER",
    SOURCES_USES = "SOURCES_USES",
    POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
    SALES_CONTRACT = "SALES_CONTRACT",
    SERVICE_CONTRACT = "SERVICE_CONTRACT",
    STATEMENTS_BANKS = "STATEMENTS_BANKS",
    STATEMENTS_BROKERAGES = "STATEMENTS_BROKERAGES",
    STATEMENTS_LOANS = "STATEMENTS_LOANS",
    STATEMENTS_MORTGAGES = "STATEMENTS_MORTGAGES",
    STATEMENTS_INTERIM = "STATEMENTS_INTERIM",
    INVOICES = "INVOICES",
    CHECKS = "CHECKS",
    CANCELLED_CHECKS = "CANCELLED_CHECKS",
    LOAN_TRANSCRIPT = "LOAN_TRANSCRIPT",
    STATEMENT_INTERIM_INTEREST = "STATEMENT_INTERIM_INTEREST",
    WIRE_TRANSFER_INSTRUCTIONS = "WIRE_TRANSFER_INSTRUCTIONS",
    BANKRUPTCY_VERIFICATION = "BANKRUPTCY_VERIFICATION",
    TAX_CERTIFICATION = "TAX_CERTIFICATION",
    PAYMENT_FORM = "PAYMENT_FORM",
    FEDERAL_TAX_RETURN_YEAR_DATE = "FEDERAL_TAX_RETURN_YEAR_DATE",
    FEDERAL_TAX_RETURN_2015 = "FEDERAL_TAX_RETURN_2015",
    FEDERAL_TAX_RETURN_2016 = "FEDERAL_TAX_RETURN_2016",
    FEDERAL_TAX_RETURN_2017 = "FEDERAL_TAX_RETURN_2017",
    FEDERAL_TAX_RETURN_2018 = "FEDERAL_TAX_RETURN_2018",
    FEDERAL_TAX_RETURN_2019 = "FEDERAL_TAX_RETURN_2019",
    FEDERAL_TAX_RETURN_2020 = "FEDERAL_TAX_RETURN_2020",
    FEDERAL_TAX_RETURN_2021 = "FEDERAL_TAX_RETURN_2021",
    FEDERAL_TAX_RETURN_2022 = "FEDERAL_TAX_RETURN_2022",
    FEDERAL_TAX_RETURN_2023 = "FEDERAL_TAX_RETURN_2023",
    FEDERAL_TAX_RETURN_2024 = "FEDERAL_TAX_RETURN_2024",
    FEDERAL_TAX_RETURN_2025 = "FEDERAL_TAX_RETURN_2025",
    FEDERAL_TAX_RETURN_2026 = "FEDERAL_TAX_RETURN_2026",
    FEDERAL_TAX_RETURN_2027 = "FEDERAL_TAX_RETURN_2027",
    FEDERAL_TAX_RETURN_2028 = "FEDERAL_TAX_RETURN_2028",
    FEDERAL_TAX_RETURN_2029 = "FEDERAL_TAX_RETURN_2029",
    FEDERAL_TAX_RETURN_2030 = "FEDERAL_TAX_RETURN_2030",
    POST_SBA_TAX_RETURN_YEAR_DATE = "POST_SBA_TAX_RETURN_YEAR_DATE",
    POST_SBA_TAX_RETURN_2015 = "POST_SBA_TAX_RETURN_2015",
    POST_SBA_TAX_RETURN_2016 = "POST_SBA_TAX_RETURN_2016",
    POST_SBA_TAX_RETURN_2017 = "POST_SBA_TAX_RETURN_2017",
    POST_SBA_TAX_RETURN_2018 = "POST_SBA_TAX_RETURN_2018",
    POST_SBA_TAX_RETURN_2019 = "POST_SBA_TAX_RETURN_2019",
    POST_SBA_TAX_RETURN_2020 = "POST_SBA_TAX_RETURN_2020",
    POST_SBA_TAX_RETURN_2021 = "POST_SBA_TAX_RETURN_2021",
    POST_SBA_TAX_RETURN_2022 = "POST_SBA_TAX_RETURN_2022",
    POST_SBA_TAX_RETURN_2023 = "POST_SBA_TAX_RETURN_2023",
    POST_SBA_TAX_RETURN_2024 = "POST_SBA_TAX_RETURN_2024",
    POST_SBA_TAX_RETURN_2025 = "POST_SBA_TAX_RETURN_2025",
    POST_SBA_TAX_RETURN_2026 = "POST_SBA_TAX_RETURN_2026",
    POST_SBA_TAX_RETURN_2027 = "POST_SBA_TAX_RETURN_2027",
    POST_SBA_TAX_RETURN_2028 = "POST_SBA_TAX_RETURN_2028",
    POST_SBA_TAX_RETURN_2029 = "POST_SBA_TAX_RETURN_2029",
    POST_SBA_TAX_RETURN_2030 = "POST_SBA_TAX_RETURN_2030",
    K1S_YEAR_DATE = "K1S_YEAR_DATE",
    K1S_2015 = "K1S_2015",
    K1S_2016 = "K1S_2016",
    K1S_2017 = "K1S_2017",
    K1S_2018 = "K1S_2018",
    K1S_2019 = "K1S_2019",
    K1S_2020 = "K1S_2020",
    K1S_2021 = "K1S_2021",
    K1S_2022 = "K1S_2022",
    K1S_2023 = "K1S_2023",
    K1S_2024 = "K1S_2024",
    K1S_2025 = "K1S_2025",
    K1S_2026 = "K1S_2026",
    K1S_2027 = "K1S_2027",
    K1S_2028 = "K1S_2028",
    K1S_2029 = "K1S_2029",
    K1S_2030 = "K1S_2030",
    TAX_EXT_YEAR_DATE = "TAX_EXT_YEAR_DATE",
    TAX_EXT_2015 = "TAX_EXT_2015",
    TAX_EXT_2016 = "TAX_EXT_2016",
    TAX_EXT_2017 = "TAX_EXT_2017",
    TAX_EXT_2018 = "TAX_EXT_2018",
    TAX_EXT_2019 = "TAX_EXT_2019",
    TAX_EXT_2020 = "TAX_EXT_2020",
    TAX_EXT_2021 = "TAX_EXT_2021",
    TAX_EXT_2022 = "TAX_EXT_2022",
    TAX_EXT_2023 = "TAX_EXT_2023",
    TAX_EXT_2024 = "TAX_EXT_2024",
    TAX_EXT_2025 = "TAX_EXT_2025",
    TAX_EXT_2026 = "TAX_EXT_2026",
    TAX_EXT_2027 = "TAX_EXT_2027",
    TAX_EXT_2028 = "TAX_EXT_2028",
    TAX_EXT_2029 = "TAX_EXT_2029",
    TAX_EXT_2030 = "TAX_EXT_2030",
    FINANCIAL_STATEMENTS_YEAR_DATE = "FINANCIAL_STATEMENTS_YEAR_DATE",
    FINANCIAL_STATEMENTS_2015 = "FINANCIAL_STATEMENTS_2015",
    FINANCIAL_STATEMENTS_2016 = "FINANCIAL_STATEMENTS_2016",
    FINANCIAL_STATEMENTS_2017 = "FINANCIAL_STATEMENTS_2017",
    FINANCIAL_STATEMENTS_2018 = "FINANCIAL_STATEMENTS_2018",
    FINANCIAL_STATEMENTS_2019 = "FINANCIAL_STATEMENTS_2019",
    FINANCIAL_STATEMENTS_2020 = "FINANCIAL_STATEMENTS_2020",
    FINANCIAL_STATEMENTS_2021 = "FINANCIAL_STATEMENTS_2021",
    FINANCIAL_STATEMENTS_2022 = "FINANCIAL_STATEMENTS_2022",
    FINANCIAL_STATEMENTS_2023 = "FINANCIAL_STATEMENTS_2023",
    FINANCIAL_STATEMENTS_2024 = "FINANCIAL_STATEMENTS_2024",
    FINANCIAL_STATEMENTS_2025 = "FINANCIAL_STATEMENTS_2025",
    FINANCIAL_STATEMENTS_2026 = "FINANCIAL_STATEMENTS_2026",
    FINANCIAL_STATEMENTS_2027 = "FINANCIAL_STATEMENTS_2027",
    FINANCIAL_STATEMENTS_2028 = "FINANCIAL_STATEMENTS_2028",
    FINANCIAL_STATEMENTS_2029 = "FINANCIAL_STATEMENTS_2029",
    FINANCIAL_STATEMENTS_2030 = "FINANCIAL_STATEMENTS_2030",
    FINANCIAL_STATEMENT_COMPS_YEAR_DATE = "FINANCIAL_STATEMENT_COMPS_YEAR_DATE",
    FINANCIAL_STATEMENT_COMPS_2015 = "FINANCIAL_STATEMENT_COMPS_2015",
    FINANCIAL_STATEMENT_COMPS_2016 = "FINANCIAL_STATEMENT_COMPS_2016",
    FINANCIAL_STATEMENT_COMPS_2017 = "FINANCIAL_STATEMENT_COMPS_2017",
    FINANCIAL_STATEMENT_COMPS_2018 = "FINANCIAL_STATEMENT_COMPS_2018",
    FINANCIAL_STATEMENT_COMPS_2019 = "FINANCIAL_STATEMENT_COMPS_2019",
    FINANCIAL_STATEMENT_COMPS_2020 = "FINANCIAL_STATEMENT_COMPS_2020",
    FINANCIAL_STATEMENT_COMPS_2021 = "FINANCIAL_STATEMENT_COMPS_2021",
    FINANCIAL_STATEMENT_COMPS_2022 = "FINANCIAL_STATEMENT_COMPS_2022",
    FINANCIAL_STATEMENT_COMPS_2023 = "FINANCIAL_STATEMENT_COMPS_2023",
    FINANCIAL_STATEMENT_COMPS_2024 = "FINANCIAL_STATEMENT_COMPS_2024",
    FINANCIAL_STATEMENT_COMPS_2025 = "FINANCIAL_STATEMENT_COMPS_2025",
    FINANCIAL_STATEMENT_COMPS_2026 = "FINANCIAL_STATEMENT_COMPS_2026",
    FINANCIAL_STATEMENT_COMPS_2027 = "FINANCIAL_STATEMENT_COMPS_2027",
    FINANCIAL_STATEMENT_COMPS_2028 = "FINANCIAL_STATEMENT_COMPS_2028",
    FINANCIAL_STATEMENT_COMPS_2029 = "FINANCIAL_STATEMENT_COMPS_2029",
    FINANCIAL_STATEMENT_COMPS_2030 = "FINANCIAL_STATEMENT_COMPS_2030",
}

export enum LinkActionType {
    INVITE_PERSON_TO_LOAN = "INVITE_PERSON_TO_LOAN",
    TEST_ACTION_CONTROLLER = "TEST_ACTION_CONTROLLER",
    CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
    VIEW = "VIEW",
    RESEND_LOAN_INVITE = "RESEND_LOAN_INVITE",
    RESEND_CONFIRMATION_CODE = "RESEND_CONFIRMATION_CODE",
    RESET_PASSWORD = "RESET_PASSWORD",
    DEEP_LINK = "DEEP_LINK",
}

export enum LoanEntityLabel {
    MEMBER = "MEMBER",
    APPLICANT = "APPLICANT",
    PRIMARY_OPERATION_COMPANY = "PRIMARY_OPERATION_COMPANY",
    OPERATING_COMPANY = "OPERATING_COMPANY",
    EPC = "EPC",
    NOT_A_GUARANTOR = "NOT_A_GUARANTOR",
    UNSECURED_FULL = "UNSECURED_FULL",
    SECURED_FULL = "SECURED_FULL",
    SECURED_LIMITED = "SECURED_LIMITED",
    UNSECURED_LIMITED = "UNSECURED_LIMITED",
    GUARANTOR = "GUARANTOR",
    SPOUSE = "SPOUSE",
    NEW = "NEW",
    FRANCHISEE = "FRANCHISEE",
    AFFILIATE = "AFFILIATE",
    RELATED_NON_AFFILIATE = "RELATED_NON_AFFILIATE",
    NON_AFFILIATE = "NON_AFFILIATE",
    PRIMARY_COLLATERAL = "PRIMARY_COLLATERAL",
}

export enum LoanPhaseCategoryType {
    LEAD = "LEAD",
    ORIGINATION = "ORIGINATION",
    ARCHIVE = "ARCHIVE",
    PORTFOLIO = "PORTFOLIO",
}

export enum LoanReviewStatus {
    LEAD = "LEAD",
    IN_PROGRESS = "IN_PROGRESS",
    READY_FOR_UNDERWRITING = "READY_FOR_UNDERWRITING",
    READY_FOR_REVIEW_PENDING = "READY_FOR_REVIEW_PENDING",
    READY_FOR_REVIEW_REVIEW = "READY_FOR_REVIEW_REVIEW",
    REVIEW_COMPLETE_ACCEPTED = "REVIEW_COMPLETE_ACCEPTED",
    REVIEW_COMPLETE_CONDITIONAL = "REVIEW_COMPLETE_CONDITIONAL",
    REVIEW_COMPLETE_REJECTED = "REVIEW_COMPLETE_REJECTED",
    CLOSING = "CLOSING",
    IN_SERVICE = "IN_SERVICE",
    ARCHIVE = "ARCHIVE",
    IN_DEFAULT = "IN_DEFAULT",
    HOLD = "HOLD",
    WITHDRAWN = "WITHDRAWN",
}

export enum LoanStatus {
    APPLYING = "APPLYING",
    ANALYZING = "ANALYZING",
    CONDITIONING = "CONDITIONING",
    CLOSING = "CLOSING",
    ARCHIVED = "ARCHIVED",
    SERVICING = "SERVICING",
}

export enum LoanViewType {
    NO_VIEW = "NO_VIEW",
    CONVENTIONAL = "CONVENTIONAL",
    SBA7A = "SBA7A",
    SBA504 = "SBA504",
    CONSOLIDATED_LENDER = "CONSOLIDATED_LENDER",
}

export enum LocationType {
    NO_LOCATION = "NO_LOCATION",
    ROOT = "ROOT",
    MISC = "MISC",
    IGNORE = "IGNORE",
    ASSET = "ASSET",
    COMPANY = "COMPANY",
    INDIVIDUAL = "INDIVIDUAL",
    TRUST = "TRUST",
    TRANSACTION = "TRANSACTION",
    UNDERWRITING = "UNDERWRITING",
    SERVICING = "SERVICING",
    SERVICING_LOANS = "SERVICING_LOANS",
    CONSTRUCTION_FOLDER = "CONSTRUCTION_FOLDER",
    SERVICING_INVESTORS = "SERVICING_INVESTORS",
    SERVICING_INSURANCE = "SERVICING_INSURANCE",
    SERVICING_PAYOFFS = "SERVICING_PAYOFFS",
    PROPERTY_PHOTOS = "PROPERTY_PHOTOS",
    SBA504_ROOT = "SBA504_ROOT",
    SBA504_TAB01 = "SBA504_TAB01",
    SBA504_TAB02 = "SBA504_TAB02",
    SBA504_TAB03 = "SBA504_TAB03",
    SBA504_TAB04 = "SBA504_TAB04",
    SBA504_TAB05 = "SBA504_TAB05",
    SBA504_TAB06 = "SBA504_TAB06",
    SBA504_TAB07 = "SBA504_TAB07",
    SBA504_TAB08 = "SBA504_TAB08",
    SBA504_TAB09 = "SBA504_TAB09",
    SBA504_TAB10 = "SBA504_TAB10",
    SBA504_TAB11 = "SBA504_TAB11",
    SBA504_TAB12 = "SBA504_TAB12",
    SBA504_TAB13 = "SBA504_TAB13",
    SBA504_TAB14 = "SBA504_TAB14",
    SBA504_TAB15 = "SBA504_TAB15",
    SBA504_TAB16 = "SBA504_TAB16",
    SBA504_TAB17 = "SBA504_TAB17",
    SBA504_TAB18 = "SBA504_TAB18",
    SBA504_TAB19 = "SBA504_TAB19",
    SBA504_TAB20 = "SBA504_TAB20",
    SBA504_TAB21 = "SBA504_TAB21",
    SBA504_TAB22 = "SBA504_TAB22",
    SBA504_TAB23 = "SBA504_TAB23",
    SBA504_TAB24 = "SBA504_TAB24",
    SBA504_TAB25 = "SBA504_TAB25",
    SBA504_TAB26 = "SBA504_TAB26",
    SBA504_TAB27 = "SBA504_TAB27",
    SBA504_TAB28 = "SBA504_TAB28",
    SBA504_CLOSING_DOCS = "SBA504_CLOSING_DOCS",
    SBA504_ENTITY_DOCS = "SBA504_ENTITY_DOCS",
    SBA504_MISC = "SBA504_MISC",
    SBA504_APPROVALS = "SBA504_APPROVALS",
    SBA504_RECEIPTS = "SBA504_RECEIPTS",
    SBA504_SUBMIT = "SBA504_SUBMIT",
    SBA504_SUBMIT_DRAFTS = "SBA504_SUBMIT_DRAFTS",
    SBA504_SIGGI_DOCS = "SBA504_SIGGI_DOCS",
    SBA504_CORRESPONDENCE = "SBA504_CORRESPONDENCE",
    SBA504_DISBURSEMENTS = "SBA504_DISBURSEMENTS",
    SBA504_SECONDARY_MARKET = "SBA504_SECONDARY_MARKET",
    SBA7A_ROOT = "SBA7A_ROOT",
    SBA7A_TAB01 = "SBA7A_TAB01",
    SBA7A_TAB02 = "SBA7A_TAB02",
    SBA7A_TAB03 = "SBA7A_TAB03",
    SBA7A_TAB04 = "SBA7A_TAB04",
    SBA7A_TAB05 = "SBA7A_TAB05",
    SBA7A_TAB06 = "SBA7A_TAB06",
    SBA7A_TAB07 = "SBA7A_TAB07",
    SBA7A_TAB08 = "SBA7A_TAB08",
    SBA7A_TAB09 = "SBA7A_TAB09",
    SBA7A_TAB10 = "SBA7A_TAB10",
    SBA7A_CLOSING_DOCS = "SBA7A_CLOSING_DOCS",
    SBA7A_ENTITY_DOCS = "SBA7A_ENTITY_DOCS",
    SBA7A_MISC = "SBA7A_MISC",
    SBA7A_APPROVALS = "SBA7A_APPROVALS",
    SBA7A_RECEIPTS = "SBA7A_RECEIPTS",
    SBA7A_SUBMIT = "SBA7A_SUBMIT",
    SBA7A_SUBMIT_DRAFTS = "SBA7A_SUBMIT_DRAFTS",
    SBA7A_SIGGI_DOCS = "SBA7A_SIGGI_DOCS",
    SBA7A_CORRESPONDENCE = "SBA7A_CORRESPONDENCE",
    SBA7A_DISBURSEMENTS = "SBA7A_DISBURSEMENTS",
    SBA7A_SECONDARY_MARKET = "SBA7A_SECONDARY_MARKET",
    GOVERNMENT_IDS = "GOVERNMENT_IDS",
    CREDIT_CONSENTS = "CREDIT_CONSENTS",
    CREDIT_REPORTS = "CREDIT_REPORTS",
    RISK_REPORTS = "RISK_REPORTS",
    ENTITY_DOCS = "ENTITY_DOCS",
    SBA504_CUSTOM_01 = "SBA504_CUSTOM_01",
    SBA504_CUSTOM_02 = "SBA504_CUSTOM_02",
    SBA504_CUSTOM_03 = "SBA504_CUSTOM_03",
    SBA504_CUSTOM_04 = "SBA504_CUSTOM_04",
    SBA504_CUSTOM_05 = "SBA504_CUSTOM_05",
    SBA504_CUSTOM_06 = "SBA504_CUSTOM_06",
    SBA504_CUSTOM_07 = "SBA504_CUSTOM_07",
    SBA504_CUSTOM_08 = "SBA504_CUSTOM_08",
    SBA504_CUSTOM_09 = "SBA504_CUSTOM_09",
    SBA504_CUSTOM_10 = "SBA504_CUSTOM_10",
    SBA504_CUSTOM_11 = "SBA504_CUSTOM_11",
    SBA504_CUSTOM_12 = "SBA504_CUSTOM_12",
    SBA504_CUSTOM_13 = "SBA504_CUSTOM_13",
    SBA504_CUSTOM_14 = "SBA504_CUSTOM_14",
    SBA504_CUSTOM_15 = "SBA504_CUSTOM_15",
    SBA504_CUSTOM_16 = "SBA504_CUSTOM_16",
    SBA504_CUSTOM_17 = "SBA504_CUSTOM_17",
    SBA504_CUSTOM_18 = "SBA504_CUSTOM_18",
    SBA504_CUSTOM_19 = "SBA504_CUSTOM_19",
    SBA504_CUSTOM_20 = "SBA504_CUSTOM_20",
    SBA504_CUSTOM_21 = "SBA504_CUSTOM_21",
    SBA504_CUSTOM_22 = "SBA504_CUSTOM_22",
    SBA504_CUSTOM_23 = "SBA504_CUSTOM_23",
    SBA504_CUSTOM_24 = "SBA504_CUSTOM_24",
    SBA504_CUSTOM_25 = "SBA504_CUSTOM_25",
    SBA504_CUSTOM_26 = "SBA504_CUSTOM_26",
    SBA504_CUSTOM_27 = "SBA504_CUSTOM_27",
    SBA504_CUSTOM_28 = "SBA504_CUSTOM_28",
    SBA504_CUSTOM_29 = "SBA504_CUSTOM_29",
    SBA504_CUSTOM_30 = "SBA504_CUSTOM_30",
    SBA504_CUSTOM_31 = "SBA504_CUSTOM_31",
    SBA504_CUSTOM_32 = "SBA504_CUSTOM_32",
    SBA504_CUSTOM_33 = "SBA504_CUSTOM_33",
    SBA504_CUSTOM_34 = "SBA504_CUSTOM_34",
    SBA504_CUSTOM_35 = "SBA504_CUSTOM_35",
    SBA504_CUSTOM_36 = "SBA504_CUSTOM_36",
    SBA504_CUSTOM_37 = "SBA504_CUSTOM_37",
    SBA504_CUSTOM_38 = "SBA504_CUSTOM_38",
    SBA504_CUSTOM_39 = "SBA504_CUSTOM_39",
    SBA504_CUSTOM_40 = "SBA504_CUSTOM_40",
    SBA504_CUSTOM_41 = "SBA504_CUSTOM_41",
    SBA504_CUSTOM_42 = "SBA504_CUSTOM_42",
    SBA504_CUSTOM_43 = "SBA504_CUSTOM_43",
    SBA504_CUSTOM_44 = "SBA504_CUSTOM_44",
    SBA504_CUSTOM_45 = "SBA504_CUSTOM_45",
    SBA504_CUSTOM_46 = "SBA504_CUSTOM_46",
    SBA504_CUSTOM_47 = "SBA504_CUSTOM_47",
    SBA504_CUSTOM_48 = "SBA504_CUSTOM_48",
    SBA504_CUSTOM_49 = "SBA504_CUSTOM_49",
    SBA504_CUSTOM_50 = "SBA504_CUSTOM_50",
    SBA504_CUSTOM_51 = "SBA504_CUSTOM_51",
    SBA504_CUSTOM_52 = "SBA504_CUSTOM_52",
    SBA504_CUSTOM_53 = "SBA504_CUSTOM_53",
    SBA504_CUSTOM_54 = "SBA504_CUSTOM_54",
    SBA504_CUSTOM_55 = "SBA504_CUSTOM_55",
    SBA504_CUSTOM_56 = "SBA504_CUSTOM_56",
    SBA504_CUSTOM_57 = "SBA504_CUSTOM_57",
    SBA504_CUSTOM_58 = "SBA504_CUSTOM_58",
    SBA504_CUSTOM_59 = "SBA504_CUSTOM_59",
    SBA504_CUSTOM_60 = "SBA504_CUSTOM_60",
    SBA504_CUSTOM_61 = "SBA504_CUSTOM_61",
    SBA504_CUSTOM_62 = "SBA504_CUSTOM_62",
    SBA504_CUSTOM_63 = "SBA504_CUSTOM_63",
    SBA504_CUSTOM_64 = "SBA504_CUSTOM_64",
    SBA504_CUSTOM_65 = "SBA504_CUSTOM_65",
    SBA504_CUSTOM_66 = "SBA504_CUSTOM_66",
    SBA504_CUSTOM_67 = "SBA504_CUSTOM_67",
    SBA504_CUSTOM_68 = "SBA504_CUSTOM_68",
    SBA504_CUSTOM_69 = "SBA504_CUSTOM_69",
    SBA504_CUSTOM_70 = "SBA504_CUSTOM_70",
    SBA504_CUSTOM_71 = "SBA504_CUSTOM_71",
    SBA504_CUSTOM_72 = "SBA504_CUSTOM_72",
    SBA504_CUSTOM_73 = "SBA504_CUSTOM_73",
    SBA504_CUSTOM_74 = "SBA504_CUSTOM_74",
    SBA504_CUSTOM_75 = "SBA504_CUSTOM_75",
    SBA504_CUSTOM_76 = "SBA504_CUSTOM_76",
    SBA504_CUSTOM_77 = "SBA504_CUSTOM_77",
    SBA504_CUSTOM_78 = "SBA504_CUSTOM_78",
    SBA504_CUSTOM_79 = "SBA504_CUSTOM_79",
    SBA504_CUSTOM_80 = "SBA504_CUSTOM_80",
    SBA504_CUSTOM_81 = "SBA504_CUSTOM_81",
    SBA504_CUSTOM_82 = "SBA504_CUSTOM_82",
    SBA504_CUSTOM_83 = "SBA504_CUSTOM_83",
    SBA504_CUSTOM_84 = "SBA504_CUSTOM_84",
    SBA504_CUSTOM_85 = "SBA504_CUSTOM_85",
    SBA504_CUSTOM_86 = "SBA504_CUSTOM_86",
    SBA504_CUSTOM_87 = "SBA504_CUSTOM_87",
    SBA504_CUSTOM_88 = "SBA504_CUSTOM_88",
    SBA504_CUSTOM_89 = "SBA504_CUSTOM_89",
    SBA504_CUSTOM_90 = "SBA504_CUSTOM_90",
    SBA504_CUSTOM_91 = "SBA504_CUSTOM_91",
    SBA504_CUSTOM_92 = "SBA504_CUSTOM_92",
    SBA504_CUSTOM_93 = "SBA504_CUSTOM_93",
    SBA504_CUSTOM_94 = "SBA504_CUSTOM_94",
    SBA504_CUSTOM_95 = "SBA504_CUSTOM_95",
    SBA504_CUSTOM_96 = "SBA504_CUSTOM_96",
    SBA504_CUSTOM_97 = "SBA504_CUSTOM_97",
    SBA504_CUSTOM_98 = "SBA504_CUSTOM_98",
    SBA504_CUSTOM_99 = "SBA504_CUSTOM_99",
    SBA7A_CUSTOM_01 = "SBA7A_CUSTOM_01",
    SBA7A_CUSTOM_02 = "SBA7A_CUSTOM_02",
    SBA7A_CUSTOM_03 = "SBA7A_CUSTOM_03",
    SBA7A_CUSTOM_04 = "SBA7A_CUSTOM_04",
    SBA7A_CUSTOM_05 = "SBA7A_CUSTOM_05",
    SBA7A_CUSTOM_06 = "SBA7A_CUSTOM_06",
    SBA7A_CUSTOM_07 = "SBA7A_CUSTOM_07",
    SBA7A_CUSTOM_08 = "SBA7A_CUSTOM_08",
    SBA7A_CUSTOM_09 = "SBA7A_CUSTOM_09",
    SBA7A_CUSTOM_10 = "SBA7A_CUSTOM_10",
    SBA7A_CUSTOM_11 = "SBA7A_CUSTOM_11",
    SBA7A_CUSTOM_12 = "SBA7A_CUSTOM_12",
    SBA7A_CUSTOM_13 = "SBA7A_CUSTOM_13",
    SBA7A_CUSTOM_14 = "SBA7A_CUSTOM_14",
    SBA7A_CUSTOM_15 = "SBA7A_CUSTOM_15",
    SBA7A_CUSTOM_16 = "SBA7A_CUSTOM_16",
    SBA7A_CUSTOM_17 = "SBA7A_CUSTOM_17",
    SBA7A_CUSTOM_18 = "SBA7A_CUSTOM_18",
    SBA7A_CUSTOM_19 = "SBA7A_CUSTOM_19",
    SBA7A_CUSTOM_20 = "SBA7A_CUSTOM_20",
    SBA7A_CUSTOM_21 = "SBA7A_CUSTOM_21",
    SBA7A_CUSTOM_22 = "SBA7A_CUSTOM_22",
    SBA7A_CUSTOM_23 = "SBA7A_CUSTOM_23",
    SBA7A_CUSTOM_24 = "SBA7A_CUSTOM_24",
    SBA7A_CUSTOM_25 = "SBA7A_CUSTOM_25",
    SBA7A_CUSTOM_26 = "SBA7A_CUSTOM_26",
    SBA7A_CUSTOM_27 = "SBA7A_CUSTOM_27",
    SBA7A_CUSTOM_28 = "SBA7A_CUSTOM_28",
    SBA7A_CUSTOM_29 = "SBA7A_CUSTOM_29",
    SBA7A_CUSTOM_30 = "SBA7A_CUSTOM_30",
    SBA7A_CUSTOM_31 = "SBA7A_CUSTOM_31",
    SBA7A_CUSTOM_32 = "SBA7A_CUSTOM_32",
    SBA7A_CUSTOM_33 = "SBA7A_CUSTOM_33",
    SBA7A_CUSTOM_34 = "SBA7A_CUSTOM_34",
    SBA7A_CUSTOM_35 = "SBA7A_CUSTOM_35",
    SBA7A_CUSTOM_36 = "SBA7A_CUSTOM_36",
    SBA7A_CUSTOM_37 = "SBA7A_CUSTOM_37",
    SBA7A_CUSTOM_38 = "SBA7A_CUSTOM_38",
    SBA7A_CUSTOM_39 = "SBA7A_CUSTOM_39",
    SBA7A_CUSTOM_40 = "SBA7A_CUSTOM_40",
    SBA7A_CUSTOM_41 = "SBA7A_CUSTOM_41",
    SBA7A_CUSTOM_42 = "SBA7A_CUSTOM_42",
    SBA7A_CUSTOM_43 = "SBA7A_CUSTOM_43",
    SBA7A_CUSTOM_44 = "SBA7A_CUSTOM_44",
    SBA7A_CUSTOM_45 = "SBA7A_CUSTOM_45",
    SBA7A_CUSTOM_46 = "SBA7A_CUSTOM_46",
    SBA7A_CUSTOM_47 = "SBA7A_CUSTOM_47",
    SBA7A_CUSTOM_48 = "SBA7A_CUSTOM_48",
    SBA7A_CUSTOM_49 = "SBA7A_CUSTOM_49",
    SBA7A_CUSTOM_50 = "SBA7A_CUSTOM_50",
    SBA7A_CUSTOM_51 = "SBA7A_CUSTOM_51",
    SBA7A_CUSTOM_52 = "SBA7A_CUSTOM_52",
    SBA7A_CUSTOM_53 = "SBA7A_CUSTOM_53",
    SBA7A_CUSTOM_54 = "SBA7A_CUSTOM_54",
    SBA7A_CUSTOM_55 = "SBA7A_CUSTOM_55",
    SBA7A_CUSTOM_56 = "SBA7A_CUSTOM_56",
    SBA7A_CUSTOM_57 = "SBA7A_CUSTOM_57",
    SBA7A_CUSTOM_58 = "SBA7A_CUSTOM_58",
    SBA7A_CUSTOM_59 = "SBA7A_CUSTOM_59",
    SBA7A_CUSTOM_60 = "SBA7A_CUSTOM_60",
    SBA7A_CUSTOM_61 = "SBA7A_CUSTOM_61",
    SBA7A_CUSTOM_62 = "SBA7A_CUSTOM_62",
    SBA7A_CUSTOM_63 = "SBA7A_CUSTOM_63",
    SBA7A_CUSTOM_64 = "SBA7A_CUSTOM_64",
    SBA7A_CUSTOM_65 = "SBA7A_CUSTOM_65",
    SBA7A_CUSTOM_66 = "SBA7A_CUSTOM_66",
    SBA7A_CUSTOM_67 = "SBA7A_CUSTOM_67",
    SBA7A_CUSTOM_68 = "SBA7A_CUSTOM_68",
    SBA7A_CUSTOM_69 = "SBA7A_CUSTOM_69",
    SBA7A_CUSTOM_70 = "SBA7A_CUSTOM_70",
    SBA7A_CUSTOM_71 = "SBA7A_CUSTOM_71",
    SBA7A_CUSTOM_72 = "SBA7A_CUSTOM_72",
    SBA7A_CUSTOM_73 = "SBA7A_CUSTOM_73",
    SBA7A_CUSTOM_74 = "SBA7A_CUSTOM_74",
    SBA7A_CUSTOM_75 = "SBA7A_CUSTOM_75",
    SBA7A_CUSTOM_76 = "SBA7A_CUSTOM_76",
    SBA7A_CUSTOM_77 = "SBA7A_CUSTOM_77",
    SBA7A_CUSTOM_78 = "SBA7A_CUSTOM_78",
    SBA7A_CUSTOM_79 = "SBA7A_CUSTOM_79",
    SBA7A_CUSTOM_80 = "SBA7A_CUSTOM_80",
    SBA7A_CUSTOM_81 = "SBA7A_CUSTOM_81",
    SBA7A_CUSTOM_82 = "SBA7A_CUSTOM_82",
    SBA7A_CUSTOM_83 = "SBA7A_CUSTOM_83",
    SBA7A_CUSTOM_84 = "SBA7A_CUSTOM_84",
    SBA7A_CUSTOM_85 = "SBA7A_CUSTOM_85",
    SBA7A_CUSTOM_86 = "SBA7A_CUSTOM_86",
    SBA7A_CUSTOM_87 = "SBA7A_CUSTOM_87",
    SBA7A_CUSTOM_88 = "SBA7A_CUSTOM_88",
    SBA7A_CUSTOM_89 = "SBA7A_CUSTOM_89",
    SBA7A_CUSTOM_90 = "SBA7A_CUSTOM_90",
    SBA7A_CUSTOM_91 = "SBA7A_CUSTOM_91",
    SBA7A_CUSTOM_92 = "SBA7A_CUSTOM_92",
    SBA7A_CUSTOM_93 = "SBA7A_CUSTOM_93",
    SBA7A_CUSTOM_94 = "SBA7A_CUSTOM_94",
    SBA7A_CUSTOM_95 = "SBA7A_CUSTOM_95",
    SBA7A_CUSTOM_96 = "SBA7A_CUSTOM_96",
    SBA7A_CUSTOM_97 = "SBA7A_CUSTOM_97",
    SBA7A_CUSTOM_98 = "SBA7A_CUSTOM_98",
    SBA7A_CUSTOM_99 = "SBA7A_CUSTOM_99",
    SOURCES_USES = "SOURCES_USES",
}

export enum LoginLevelType {
    NO_AUTH = "NO_AUTH",
    CONFIRMATION_CODE = "CONFIRMATION_CODE",
    PASSWORD = "PASSWORD",
    THIRD_PARTY_SSO = "THIRD_PARTY_SSO",
    TWO_FACTOR = "TWO_FACTOR",
}

export enum MessageGenerationType {
    IN_APP = "IN_APP",
    EMAIL = "EMAIL",
}

export enum MessageLabel {
    IN_APP = "IN_APP",
    EMAIL = "EMAIL",
    USER = "USER",
    SYSTEM = "SYSTEM",
    IMPORTANT = "IMPORTANT",
    LOCKED = "LOCKED",
}

export enum MessageOriginType {
    USER = "USER",
    SYSTEM = "SYSTEM",
}

export enum MessageThreadCategory {
    GENERAL = "GENERAL",
    LENDING_TEAM = "LENDING_TEAM",
    LENDING_ON_LOAN = "LENDING_ON_LOAN",
    DIRECT_SUBJECT = "DIRECT_SUBJECT",
    DIRECT_NO_SUBJECT = "DIRECT_NO_SUBJECT",
}

export enum MessageThreadMemberType {
    ONLY_LENDER = "ONLY_LENDER",
    ONLY_BORROWER = "ONLY_BORROWER",
    MIXED = "MIXED",
}

export enum MessageType {
    DIGEST = "DIGEST",
}

export enum NamingStrategyType {
    NO_STRATEGY = "NO_STRATEGY",
    ENTITY_ADDRESS = "ENTITY_ADDRESS",
    ENTITY_NAME = "ENTITY_NAME",
    PREFIX_ENTITY_NAME = "PREFIX_ENTITY_NAME",
    PREFIX_ENTITY_ADDRESS = "PREFIX_ENTITY_ADDRESS",
    SUFFIX_ENTITY_NAME = "SUFFIX_ENTITY_NAME",
    SUFFIX_ENTITY_NAME_AND_DATE = "SUFFIX_ENTITY_NAME_AND_DATE",
}

export enum NoteObjectType {
    LOAN = "LOAN",
    PACKAGE_INFO = "PACKAGE_INFO",
    SHERPA_ENTITY = "SHERPA_ENTITY",
}

export enum NoteType {
    STANDARD = "STANDARD",
    REJECTION = "REJECTION",
    RESOLVED_REJECTION = "RESOLVED_REJECTION",
}

export enum NoteVisibilityType {
    PRIVATE = "PRIVATE",
    LENDER_TEAM = "LENDER_TEAM",
    ASSIGNED_USER = "ASSIGNED_USER",
    ALL = "ALL",
}

export enum NullHandling {
    NATIVE = "NATIVE",
    NULLS_FIRST = "NULLS_FIRST",
    NULLS_LAST = "NULLS_LAST",
}

/**
 * Values:
 * - `Unset`
 * - `UNSET`
 * - `HIDDEN` - @deprecated
 * - `PRIVATE` - @deprecated
 * - `IGNORE`
 * - `BLOCKER`
 * - `LOW`
 * - `MEDIUM`
 * - `HIGH`
 * - `CRITICAL`
 * - `URGENT` - @deprecated
 */
export enum PriorityType {
    Unset = "Unset",
    UNSET = "UNSET",
    /**
     * @deprecated
     */
    HIDDEN = "HIDDEN",
    /**
     * @deprecated
     */
    PRIVATE = "PRIVATE",
    IGNORE = "IGNORE",
    BLOCKER = "BLOCKER",
    LOW = "LOW",
    MEDIUM = "MEDIUM",
    HIGH = "HIGH",
    CRITICAL = "CRITICAL",
    /**
     * @deprecated
     */
    URGENT = "URGENT",
}

export enum ProviderType {
    S3 = "S3",
    ONE_DRIVE = "ONE_DRIVE",
}

export enum Role {
    BORROWER = "BORROWER",
    LEAD_BORROWER = "LEAD_BORROWER",
    LENDER = "LENDER",
    LEAD_LENDER = "LEAD_LENDER",
    MANAGER_LENDER = "MANAGER_LENDER",
    UNDERWRITER_LENDER = "UNDERWRITER_LENDER",
    ASSOCIATE_LENDER = "ASSOCIATE_LENDER",
    CONTACT_BORROWER = "CONTACT_BORROWER",
    CONTACT_LENDER = "CONTACT_LENDER",
    KEY_CONTACT = "KEY_CONTACT",
}

export enum SearchTypeEnum {
    FORM_ELEMENT = "FORM_ELEMENT",
    LOAN = "LOAN",
    US_DRIVERS_LICENSE = "US_DRIVERS_LICENSE",
    PERSON = "PERSON",
}

export enum SharePermissionType {
    NONE = "NONE",
    VIEW = "VIEW",
    ANSWER = "ANSWER",
    ACCEPT = "ACCEPT",
    EDIT = "EDIT",
    LINK = "LINK",
}

/**
 * Values:
 * - `ASSET`
 * - `RE_ASSET` - @deprecated
 * - `GENERIC_RE_ASSET`
 * - `COMPANY`
 * - `TRUST`
 * - `INDIVIDUAL`
 * - `NEW_TITLED_EQUIPMENT_ASSET`
 * - `NEW_UNTITLED_EQUIPMENT_ASSET`
 * - `USED_TITLED_EQUIPMENT_ASSET`
 * - `USED_UNTITLED_EQUIPMENT_ASSET`
 * - `PURCHASE_COMMERCIAL_RE_ASSET`
 * - `REHAB_COMMERCIAL_RE_ASSET`
 * - `CONSTRUCTION_COMMERCIAL_RE_ASSET`
 * - `PURCHASE_RESIDENTIAL_RE_ASSET`
 * - `REHAB_RESIDENTIAL_RE_ASSET`
 * - `CONSTRUCTION_RESIDENTIAL_RE_ASSET`
 * - `STOCK_ACCOUNT_ASSET`
 * - `BOND_ACCOUNT_ASSET`
 * - `SAVINGS_ACCOUNT_ASSET`
 * - `INVENTORY_OTHER_ASSET`
 * - `AG_OTHER_ASSET`
 * - `ACCOUNT_RECEIVABLE_OTHER_ASSET`
 */
export enum SherpaEntityType {
    ASSET = "ASSET",
    /**
     * @deprecated
     */
    RE_ASSET = "RE_ASSET",
    GENERIC_RE_ASSET = "GENERIC_RE_ASSET",
    COMPANY = "COMPANY",
    TRUST = "TRUST",
    INDIVIDUAL = "INDIVIDUAL",
    NEW_TITLED_EQUIPMENT_ASSET = "NEW_TITLED_EQUIPMENT_ASSET",
    NEW_UNTITLED_EQUIPMENT_ASSET = "NEW_UNTITLED_EQUIPMENT_ASSET",
    USED_TITLED_EQUIPMENT_ASSET = "USED_TITLED_EQUIPMENT_ASSET",
    USED_UNTITLED_EQUIPMENT_ASSET = "USED_UNTITLED_EQUIPMENT_ASSET",
    PURCHASE_COMMERCIAL_RE_ASSET = "PURCHASE_COMMERCIAL_RE_ASSET",
    REHAB_COMMERCIAL_RE_ASSET = "REHAB_COMMERCIAL_RE_ASSET",
    CONSTRUCTION_COMMERCIAL_RE_ASSET = "CONSTRUCTION_COMMERCIAL_RE_ASSET",
    PURCHASE_RESIDENTIAL_RE_ASSET = "PURCHASE_RESIDENTIAL_RE_ASSET",
    REHAB_RESIDENTIAL_RE_ASSET = "REHAB_RESIDENTIAL_RE_ASSET",
    CONSTRUCTION_RESIDENTIAL_RE_ASSET = "CONSTRUCTION_RESIDENTIAL_RE_ASSET",
    STOCK_ACCOUNT_ASSET = "STOCK_ACCOUNT_ASSET",
    BOND_ACCOUNT_ASSET = "BOND_ACCOUNT_ASSET",
    SAVINGS_ACCOUNT_ASSET = "SAVINGS_ACCOUNT_ASSET",
    INVENTORY_OTHER_ASSET = "INVENTORY_OTHER_ASSET",
    AG_OTHER_ASSET = "AG_OTHER_ASSET",
    ACCOUNT_RECEIVABLE_OTHER_ASSET = "ACCOUNT_RECEIVABLE_OTHER_ASSET",
}

export enum ShoeboxType {
    LOAN = "LOAN",
    PERSONAL = "PERSONAL",
    COMPANY = "COMPANY",
}

export enum Source {
    USER = "USER",
    OTHER_USER = "OTHER_USER",
    TRANSUNION = "TRANSUNION",
    USER_SUBMITTED_DRIVERS_LICENSE = "USER_SUBMITTED_DRIVERS_LICENSE",
    CLEAR = "CLEAR",
}

export enum StorageType {
    FILE = "FILE",
    FOLDER = "FOLDER",
}

export enum SubRole {
    DEFAULT = "DEFAULT",
    VENDOR = "VENDOR",
    FRANCHISOR = "FRANCHISOR",
    LANDLORD = "LANDLORD",
    REAL_ESTATE_AGENT = "REAL_ESTATE_AGENT",
    TITLE_AGENT = "TITLE_AGENT",
    INSURANCE_AGENT = "INSURANCE_AGENT",
    ENVIRONMENTAL_AGENT = "ENVIRONMENTAL_AGENT",
    APPRAISER = "APPRAISER",
    LAWYER = "LAWYER",
    PROPERTY_INSPECTOR = "PROPERTY_INSPECTOR",
    PROPERTY_MANAGER = "PROPERTY_MANAGER",
    CONTRACTOR = "CONTRACTOR",
    TENANT = "TENANT",
    SPECIAL_USER = "SPECIAL_USER",
}

export enum TaskType {
    assign_signee = "assign_signee",
    fill = "fill",
    sign = "sign",
}

export enum TaskViewType {
    MESSAGE = "MESSAGE",
    UPLOAD = "UPLOAD",
    FILL_SIGN = "FILL_SIGN",
    ONBOARDING = "ONBOARDING",
}

export enum UnitedStates {
    AL = "AL",
    AK = "AK",
    AZ = "AZ",
    AR = "AR",
    CA = "CA",
    CO = "CO",
    CT = "CT",
    DE = "DE",
    DC = "DC",
    FL = "FL",
    GA = "GA",
    HI = "HI",
    ID = "ID",
    IL = "IL",
    IN = "IN",
    IA = "IA",
    KS = "KS",
    KY = "KY",
    LA = "LA",
    ME = "ME",
    MD = "MD",
    MA = "MA",
    MI = "MI",
    MN = "MN",
    MS = "MS",
    MO = "MO",
    MT = "MT",
    NE = "NE",
    NV = "NV",
    NH = "NH",
    NJ = "NJ",
    NM = "NM",
    NY = "NY",
    NC = "NC",
    ND = "ND",
    OH = "OH",
    OK = "OK",
    OR = "OR",
    PA = "PA",
    RI = "RI",
    SC = "SC",
    SD = "SD",
    TN = "TN",
    TX = "TX",
    UT = "UT",
    VT = "VT",
    VA = "VA",
    WA = "WA",
    WV = "WV",
    WI = "WI",
    WY = "WY",
    PR = "PR",
}

export enum VentureChangeLevel {
    GLOBAL = "GLOBAL",
    LOAN = "LOAN",
}

export enum VentureEnvironment {
    SANDBOX = "SANDBOX",
    PRODUCTION = "PRODUCTION",
}

export enum VentureExportDataType {
    LOAN = "LOAN",
    CONTACT = "CONTACT",
    COMPANY = "COMPANY",
    LOAN_ENTITY = "LOAN_ENTITY",
    LOAN_NOTE = "LOAN_NOTE",
    LOAN_POC = "LOAN_POC",
}

export enum VentureExportStatus {
    NOT_EXPORTED = "NOT_EXPORTED",
    DATA_CHANGE_CHECKING = "DATA_CHANGE_CHECKING",
    DATA_CHANGE_CHECKED = "DATA_CHANGE_CHECKED",
    EXPORTING = "EXPORTING",
    EXPORT_INCOMPLETE = "EXPORT_INCOMPLETE",
    EXPORT_COMPLETE = "EXPORT_COMPLETE",
    EXPORT_ERROR = "EXPORT_ERROR",
}

export enum VenturesDocType {
    A1098_STATEMENTS = "A1098_STATEMENTS",
    A12_MONTH_PROJECTION_OF_INCOME_AND_EXPENSES = "A12_MONTH_PROJECTION_OF_INCOME_AND_EXPENSES",
    A327_PLUS_BACKUP_DOCUMENTATION = "A327_PLUS_BACKUP_DOCUMENTATION",
    A504_DEBENTURE_CLOSING_CHECKLIST = "A504_DEBENTURE_CLOSING_CHECKLIST",
    A504_LOAN_CLOSING_ACKNOWLEDGEMENT_AND_CONSENT = "A504_LOAN_CLOSING_ACKNOWLEDGEMENT_AND_CONSENT",
    ACH_AUTH_AND_VOIDED_CHECK = "ACH_AUTH_AND_VOIDED_CHECK",
    AGREEMENT_REGARDING_ATTORNEY_FEES = "AGREEMENT_REGARDING_ATTORNEY_FEES",
    ALL_CREDIT_REPORTS_EXPLANATIONS = "ALL_CREDIT_REPORTS_EXPLANATIONS",
    AMORTIZATION_AND_PREPAY_SCHEDULES = "AMORTIZATION_AND_PREPAY_SCHEDULES",
    ANNUAL_FS_REVIEW = "ANNUAL_FS_REVIEW",
    AP_AR_AGINGS = "AP_AR_AGINGS",
    APPRAISAL_FOR_PROPERTY_CHECKLIST_SBA_APP = "APPRAISAL_FOR_PROPERTY_CHECKLIST_SBA_APP",
    ASSIGNMENT_OF_DEED_OF_TRUST = "ASSIGNMENT_OF_DEED_OF_TRUST",
    ATTORNEYS_REVIEW_CHECKLIST = "ATTORNEYS_REVIEW_CHECKLIST",
    BANKRUPTCY = "BANKRUPTCY",
    BORROWER_AND_OP_CO_CERT = "BORROWER_AND_OP_CO_CERT",
    BORROWERS_TENANT_CHECKS = "BORROWERS_TENANT_CHECKS",
    BUSINESS_DEBT_SCHEDULE = "BUSINESS_DEBT_SCHEDULE",
    BUSINESS_FEDERAL_TAX_RETURN_2012_2011 = "BUSINESS_FEDERAL_TAX_RETURN_2012_2011",
    BUSINESS_FEDERAL_TAX_RETURN_2013 = "BUSINESS_FEDERAL_TAX_RETURN_2013",
    BUSINESS_FEDERAL_TAX_RETURN_2014 = "BUSINESS_FEDERAL_TAX_RETURN_2014",
    BUSINESS_FEDERAL_TAX_RETURN_ARCHIVE = "BUSINESS_FEDERAL_TAX_RETURN_ARCHIVE",
    BUSINESS_INTERIM_FINANCIAL_STATEMENT = "BUSINESS_INTERIM_FINANCIAL_STATEMENT",
    BUSINESS_PROFESSIONAL_LICENSES = "BUSINESS_PROFESSIONAL_LICENSES",
    CDC_APPLICATION = "CDC_APPLICATION",
    CDC_BOARD_RESOLUTION_SBA_FORM_1528 = "CDC_BOARD_RESOLUTION_SBA_FORM_1528",
    CDC_CERTIFICATION_SBA_FORM_2101 = "CDC_CERTIFICATION_SBA_FORM_2101",
    CDC_DEED_OF_TRUST = "CDC_DEED_OF_TRUST",
    CERTIFICATE_OF_FUTURE_OCCUPANCY = "CERTIFICATE_OF_FUTURE_OCCUPANCY",
    COLPURS_REO = "COLPURS_REO",
    COMPENSATION_AGREEMENT_SBA_FORM_159 = "COMPENSATION_AGREEMENT_SBA_FORM_159",
    CONSTRUCTION_BIDS = "CONSTRUCTION_BIDS",
    CONSTRUCTION_DISBURS_ITEMIZATION_NOC_COFO = "CONSTRUCTION_DISBURS_ITEMIZATION_NOC_COFO",
    CORPS_ARTCLS_BYLAWS_STOCKS_CERTOFSECY_RES = "CORPS_ARTCLS_BYLAWS_STOCKS_CERTOFSECY_RES",
    CORRESPONDENCE = "CORRESPONDENCE",
    CORRESPONDENCE_EMAILS = "CORRESPONDENCE_EMAILS",
    CURRENT_PROPOSED_LEASE = "CURRENT_PROPOSED_LEASE",
    DEVELOPMENT_COMPANY_DEB_SBA_FORM_1504 = "DEVELOPMENT_COMPANY_DEB_SBA_FORM_1504",
    ELIGIBILITY_QUESTIONNAIRE = "ELIGIBILITY_QUESTIONNAIRE",
    ENVIRO_TSA_PH1_QSTNRE_CHCKLST_SBA_APP = "ENVIRO_TSA_PH1_QSTNRE_CHCKLST_SBA_APP",
    FEE_DISCLOSURE_STMT_BORROWERS_SGNTR_CERT = "FEE_DISCLOSURE_STMT_BORROWERS_SGNTR_CERT",
    FLOOD_INSURANCE_FEMA_FORM = "FLOOD_INSURANCE_FEMA_FORM",
    FRANCHISE_AGRMNT_FTC_DISCLOSURE = "FRANCHISE_AGRMNT_FTC_DISCLOSURE",
    HAZARD_INSURANCE = "HAZARD_INSURANCE",
    HISTORY_OF_BUSINESS = "HISTORY_OF_BUSINESS",
    INJECTION_CERT_FINAL_CLSNG_STMNT_AND_CERT = "INJECTION_CERT_FINAL_CLSNG_STMNT_AND_CERT",
    INTERIM_LENDER_CERTIFICATION_SBA_FORM_2288 = "INTERIM_LENDER_CERTIFICATION_SBA_FORM_2288",
    INTERIM_LENDER_NOTE_AND_DEED_OF_TRUST = "INTERIM_LENDER_NOTE_AND_DEED_OF_TRUST",
    IRS_W_9_FORM = "IRS_W_9_FORM",
    LEASES_EPC_OC_TENANT_CERTS_ASSIGNMENTS = "LEASES_EPC_OC_TENANT_CERTS_ASSIGNMENTS",
    LEGAL_MISC = "LEGAL_MISC",
    LEGAL_MISC_FILE_CHECKLIST = "LEGAL_MISC_FILE_CHECKLIST",
    LENDER_COMMITMENT_LETTER_LENDER_CREDIT_MEMO = "LENDER_COMMITMENT_LETTER_LENDER_CREDIT_MEMO",
    LIQUIDATION = "LIQUIDATION",
    LIQUIDATIONS = "LIQUIDATIONS",
    LIST_OF_EQUIP_TO_BE_ACQUIRED_WITH_PROCEEDS = "LIST_OF_EQUIP_TO_BE_ACQUIRED_WITH_PROCEEDS",
    LLC__BRRWNG_GTY_RES_ARTCLS_OF_ORG_OP_AGRMENT = "LLC__BRRWNG_GTY_RES_ARTCLS_OF_ORG_OP_AGRMENT",
    LLP_ARTICLES_AGREEMENT_AND_RESOLUTION = "LLP_ARTICLES_AGREEMENT_AND_RESOLUTION",
    LOAN_AUTHORIZATION_327_ACTION_LETTERS = "LOAN_AUTHORIZATION_327_ACTION_LETTERS",
    LOAN_MODIFICATION = "LOAN_MODIFICATION",
    NAC_FOR_FUNDING_INCL_SPREADS = "NAC_FOR_FUNDING_INCL_SPREADS",
    OPINION_OF_CDC_COUNSEL = "OPINION_OF_CDC_COUNSEL",
    OTHER_MISC_CLOSING = "OTHER_MISC_CLOSING",
    PARTICIPATION_FEE = "PARTICIPATION_FEE",
    PARTNERSHIPS_AGREEMENT_AND_RESOLUTIONS = "PARTNERSHIPS_AGREEMENT_AND_RESOLUTIONS",
    PERSONAL_FEDERAL_INCOME_TAX_RETURN = "PERSONAL_FEDERAL_INCOME_TAX_RETURN",
    PERSONAL_FINANCIAL_STATEMENT = "PERSONAL_FINANCIAL_STATEMENT",
    PERSONAL_FINANCIAL_STATEMENT_SBA_FORM_413 = "PERSONAL_FINANCIAL_STATEMENT_SBA_FORM_413",
    POST_FUNDING_INVOICES = "POST_FUNDING_INVOICES",
    PRE_CLOSING_REVIEW_AUDIT = "PRE_CLOSING_REVIEW_AUDIT",
    PREPAYS_AND_PAYOFFS = "PREPAYS_AND_PAYOFFS",
    PREVIOUS_GOVT_FINANCING_DEBARMENT = "PREVIOUS_GOVT_FINANCING_DEBARMENT",
    PROMISSORY_NOTE = "PROMISSORY_NOTE",
    PROPERTY_TAXES = "PROPERTY_TAXES",
    PURCHASE_AGRMNT_ESCROW_INST_LTR_TO_ESCROW = "PURCHASE_AGRMNT_ESCROW_INST_LTR_TO_ESCROW",
    RECONVEYANCES = "RECONVEYANCES",
    REFI = "REFI",
    REQUEST_FOR_NOTICE = "REQUEST_FOR_NOTICE",
    SBA_912_NAME_AFFDVT_RESUME_CIP = "SBA_912_NAME_AFFDVT_RESUME_CIP",
    SBA_ACTIONS = "SBA_ACTIONS",
    SBA_FORM_912 = "SBA_FORM_912",
    SCREENOUTS_LOAN_NUMBER = "SCREENOUTS_LOAN_NUMBER",
    SECONDARY_COLLATERAL_SEE_SBA_CHKLST = "SECONDARY_COLLATERAL_SEE_SBA_CHKLST",
    SERVICING_AGENT_AGREEMENT_SBA_FORM_1506 = "SERVICING_AGENT_AGREEMENT_SBA_FORM_1506",
    SITE_INSPECTION = "SITE_INSPECTION",
    SLPC_CREDIT_MEMO = "SLPC_CREDIT_MEMO",
    SOLEPROP_FBNS = "SOLEPROP_FBNS",
    STANDBY_AGREEMENTS_SBA_FORM_155 = "STANDBY_AGREEMENTS_SBA_FORM_155",
    SUPPLEMENTAL = "SUPPLEMENTAL",
    TAX_TRANSCRIPTS_BUSINESS = "TAX_TRANSCRIPTS_BUSINESS",
    TAX_TRANSCRIPTS_PERSONAL = "TAX_TRANSCRIPTS_PERSONAL",
    THIRD_PARTY_LENDER_AGREEMENT_SBA_FORM_2287 = "THIRD_PARTY_LENDER_AGREEMENT_SBA_FORM_2287",
    THIRD_PARTY_LENDER_NOTE_AND_DEED_OF_TRUST = "THIRD_PARTY_LENDER_NOTE_AND_DEED_OF_TRUST",
    TITLE_AND_ALTA_AND_PRELIMS = "TITLE_AND_ALTA_AND_PRELIMS",
    TRUST_AGREEMENT_AND_CERT = "TRUST_AGREEMENT_AND_CERT",
    UCC = "UCC",
    UNCONDITIONAL_GUARANTEE_SBA_FORM_148_148L = "UNCONDITIONAL_GUARANTEE_SBA_FORM_148_148L",
    USCIS_FORMS_G_845_ALIEN_REG_CARD = "USCIS_FORMS_G_845_ALIEN_REG_CARD",
    WIRING_INSTRUCTIONS = "WIRING_INSTRUCTIONS",
    YEAR_END_FINANCIAL_STATEMENT = "YEAR_END_FINANCIAL_STATEMENT",
}

export enum ViewType {
    PRINCIPAL = "PRINCIPAL",
    LENDER = "LENDER",
    ANONYMOUS = "ANONYMOUS",
    CONTACT = "CONTACT",
}

export enum VirusScanState {
    NOT_SCANNED = "NOT_SCANNED",
    NOT_STAGED = "NOT_STAGED",
    CLEAN = "CLEAN",
    INFECTED = "INFECTED",
    NOT_CHECKED = "NOT_CHECKED",
}
